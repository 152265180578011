import { Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import ErrorMessage from '@/components/ErrorMessage'
import Loading from '@/components/Loading'

import success_blue from '../../../assets/icons/success_blue.svg'
// svgs
import success_green from '../../../assets/icons/success_green.svg'
import RootButton from '../../Buttons/RootButton'
import IconWrapper from '../../MediaWrappers/IconWrapper'
import classes from './ConfirmModal.module.css'

export const ConfirmationError: React.FC<{
  title: string | undefined
  retry: () => void
  noTitle?: boolean
}> = ({ title, retry, noTitle = false }) => {
  const { t } = useTranslation()

  // const theme = useMantineTheme()

  const displayTitle = title ?? t('components.receipt.error', 'Transaction Error')
  return (
    <Stack className={classes.identityLoadingComponent}>
      <Loading size="184px" error />
      {!noTitle && (
        <ErrorMessage
          message={t('components.receipt.error', 'Transaction Error')}
          details={displayTitle}
        />
      )}
      <Stack style={{ flexDirection: 'row', width: '100%' }}>
        <RootButton expand style1={false} onClick={retry}>
          {t('buttons.retry', 'Retry')}
        </RootButton>
      </Stack>
    </Stack>
  )
}

export const ConfirmationLoading: React.FC<{
  title: string | undefined
  noTitle?: boolean
}> = ({ title, noTitle = false }) => {
  const { t } = useTranslation()
  const displayTitle = title ?? t('components.receipt.pending', 'Transaction Pending...')
  return (
    <Stack className={classes.identityLoadingComponent}>
      {!noTitle && (
        <Title order={4} style={{ marginRight: '1px' }}>
          {displayTitle}
        </Title>
      )}
      <Loading size="184px" />
    </Stack>
  )
}

export const ConfirmationReady: React.FC<{
  message?: string
  tip?: string
  successMessage?: string
  onClose?: () => void
  success: () => void
}> = ({ message, success, tip = '', onClose, successMessage = 'Continue' }) => {
  const { t } = useTranslation()
  message = message ?? t('components.receipt.complete', 'Transaction Complete')
  const theme = useMantineTheme()
  return (
    <Stack className={classes.identityLoadingComponent}>
      <Stack style={{ display: 'flex', alignItems: 'center' }}>
        <IconWrapper
          style={{ width: '184px', height: '184px' }}
          iconDark={success_green}
          iconLight={success_blue}
        />
      </Stack>
      <Stack style={{ alignItems: 'center', gap: 10, marginBottom: '20px' }}>
        <Title order={4}>{message}</Title>
        {tip && <Text style={{ color: theme.colors.paragraph[0] }}>{tip}</Text>}
      </Stack>
      <Stack style={{ flexDirection: 'row', width: '100%' }}>
        {onClose && (
          <RootButton expand secondary style1={false} onClick={onClose}>
            {t('buttons.close', 'Close')}
          </RootButton>
        )}
        <RootButton expand style1={false} onClick={success}>
          {successMessage}
        </RootButton>
      </Stack>
    </Stack>
  )
}

import React, { useMemo, useState } from 'react'

import useLocalStorage from '@/hooks/useLocalStorage'
import { StoredTheme } from '@/utils/utils'

import Loading from '../Loading'

interface HeaderIconWrapperProps {
  selected?: boolean
  hovered: boolean
  hoveredIconDark?: string
  hoveredIconLight?: string
  iconDark: string
  iconLight: string
  loader?: boolean
  imageLoaderHeight?: string
}
const HeaderIconWrapper: React.FC<HeaderIconWrapperProps> = ({
  selected = false,
  hovered,
  hoveredIconDark = '',
  hoveredIconLight = '',
  iconDark,
  iconLight,
  loader = false,
  imageLoaderHeight = '100%',
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [userTheme] = useLocalStorage<StoredTheme>(`userTheme`)
  const darkmode = !userTheme?.isLightMode

  const icon = useMemo(() => {
    if (hovered) {
      return darkmode ? hoveredIconDark || iconDark : hoveredIconLight || iconLight
    }
    if (selected) {
      return darkmode ? hoveredIconDark || iconDark : hoveredIconLight || iconLight
    }
    return darkmode ? iconLight : iconDark
  }, [selected, hovered, darkmode])

  const handleLoading = () => {
    if (loader) {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading && loader && <Loading imageLoaderHeight={imageLoaderHeight} />}
      <img
        style={{
          display: isLoading && loader ? 'none' : 'block',
          transform: hovered ? 'translateY(-5px)' : 'scale(1)',
          transition: 'transform 0.3s ease-in-out',
        }}
        src={icon}
        alt={icon}
        onLoad={handleLoading}
      />
    </>
  )
}

export default HeaderIconWrapper

import { useMemo } from 'react'

import useL1nsName from './useL1nsName'

const useProfilePath = (address: `0x${string}` | string | undefined) => {
  const {
    l1nsName: { data },
  } = useL1nsName(address)
  // check if address has username
  const identity: string | undefined = useMemo(() => data || address, [data])

  return `/profile/${identity}`
}

export default useProfilePath

import { Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'

import RootButton from '@/components/Buttons/RootButton'
import AddressDisplay from '@/components/User/AddressDisplay'
import useLocalStorage from '@/hooks/useLocalStorage'
import { useAuth } from '@/plugins/auth'
import { UserIdentity } from '@/utils/utils'

interface ReceiveProps {
  close: () => void
}

const Receive: React.FC<ReceiveProps> = ({ close }) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const { address } = useAuth()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const [userIdentity] = useLocalStorage<UserIdentity>(`userIdentity-${address}`)
  return (
    <Stack style={{ gap: '20px' }}>
      <Text style={{ color: theme.colors.title[0] }}>
        {t(
          'components.receive.modal.description',
          'Copy your addresses below to receive assets from others across LAMINA1 and beyond'
        )}
      </Text>
      {userIdentity?.username && (
        <>
          <Title order={4}>{t('components.receive.modal.usernameTitle', 'Username Address')}</Title>
          {address && (
            <AddressDisplay
              address={address}
              truncated={isMobile}
              tip={t(
                'components.receive.modal.addressDisplayTip',
                'Use this to receive assets from other LAMINA1 Hub users'
              )}
            />
          )}
        </>
      )}

      <Title order={4}>{t('components.receive.modal.addressTitle', 'Alphanumeric Address')}</Title>
      {address && (
        <AddressDisplay
          address={address}
          truncated={isMobile}
          showIdentity={false}
          tip={t(
            'components.receive.modal.addressDisplayTip2',
            'Use this to receive assets from other LAMINA1 Hub or Avalanche users'
          )}
        />
      )}
      <Stack style={{ flexDirection: 'row' }}>
        <RootButton onClick={close} expand style1={false}>
          {t('buttons.close', 'Close')}
        </RootButton>
      </Stack>
    </Stack>
  )
}
export default Receive

type AuthedRedirectPathOptions = {
  forceStepper?: boolean
  restoreOldRoute?: boolean
  setUsername?: boolean
  profileAddr?: string
}

export function getAuthedRedirectPath(options?: AuthedRedirectPathOptions): string {
  const newRoute = (() => {
    const {
      forceStepper,
      restoreOldRoute = true,
      setUsername = false,
      profileAddr = '',
    } = options ?? {}

    if (restoreOldRoute) {
      const oldRoute =
        typeof window !== 'undefined' ? window.sessionStorage.getItem('oldRoute') : null

      if (oldRoute) {
        if (oldRoute !== '/connect') {
          return oldRoute
        }
      }
    }

    if (setUsername) {
      return '/freeusername'
    }

    if (profileAddr) {
      return `/profile/${profileAddr}`
    }

    const stepper =
      forceStepper ?? window.localStorage.getItem(`firstLogin-${profileAddr}`) !== 'false'

    return stepper ? '/freeusername' : '/home'
  })()

  // Avoids getting stuck in the same page
  return newRoute === window.location.pathname ? '/home' : newRoute
}

export function saveLocation(pathname?: string): void {
  pathname = pathname ?? window.location.pathname

  if (!['/', '/connect'].includes(pathname)) {
    window.sessionStorage.setItem('oldRoute', pathname)
  }
}

type UnauthedRedirectPathOptions = {
  saveOldRoute?: boolean | string
}

export function getUnauthedRedirectPath(options?: UnauthedRedirectPathOptions): string {
  const { saveOldRoute = true } = options ?? {}

  if (saveOldRoute) {
    saveLocation(typeof saveOldRoute === 'string' ? saveOldRoute : undefined)
  }

  return '/home'
}

import { TFunction } from 'i18next'
import { Trans, useTranslation } from 'react-i18next'

import { AVAX_CHAIN_NAME, IDENTITY_SUBNET_NAME, NATIVE_CHAIN_NAME } from './blockchain'

const reportMessage = () => (
  <Trans i18nKey="errors.reportMessage">
    Please clear your cache and temporarily disable wallet extensions (Rabby, Backpack, etc) that
    you are not using to connect to the Hub, reload, and try again. If that does not work, file an
    error report&nbsp;
    <a
      href="https://blocksurvey.io/lamina1-hub-feedback-D.KIfzAfR9.0KNKCBAMGGQ-o"
      target="_blank"
      rel="noreferrer"
    >
      here
    </a>
    .
  </Trans>
)

const bugFoundMessage = () => (
  <>
    <Trans i18nKey="errors.bugFoundMessage">
      Lucky you! Possible bug found. Please copy the details and file a bug report&nbsp;
      <a
        href="https://blocksurvey.io/lamina1-hub-feedback-D.KIfzAfR9.0KNKCBAMGGQ-o"
        target="_blank"
        rel="noreferrer"
      >
        here
      </a>
      .
    </Trans>
    {reportMessage()}
  </>
)

const defaultErrorMessage = (t: TFunction, server: string) => (
  <Trans i18nKey="errors.defaultErrorMessage">Error connecting to the {{ server }}.</Trans>
)

const idBotRateLimit = (t: TFunction, time: string) => (
  <>
    {t(
      'errors.idBotRateLimit1',
      'You can only modify your username and profile a limited number of times per day from this IP address or wallet. Please return to the Hub in '
    )}
    <p style={{ color: 'green', fontWeight: 'bold' }}>
      <Trans i18nKey="errors.idBotRateLimit2">{{ time }}</Trans>
    </p>
    {t(
      'errors.idBotRateLimit3',
      ' to complete your username reservation or profile change request.'
    )}
  </>
)

const getErrorMessagesNonMemoized = (t: TFunction) =>
  ({
    // General error messages
    reportMessage: reportMessage(),
    bugFoundMessage: bugFoundMessage(),

    // Connection errors
    hubConnection: t(
      'common.servers.l1Hub',
      'Please connect to the LAMINA1 Hub to continue.'
    ) as string,

    // RPC connections
    rpcConnection: defaultErrorMessage(t, NATIVE_CHAIN_NAME),
    rpcIdentityConnection: defaultErrorMessage(t, IDENTITY_SUBNET_NAME),
    rpcAvaxConnection: defaultErrorMessage(t, AVAX_CHAIN_NAME),

    // Backend servers - Failed to fetch
    idBotConnection: defaultErrorMessage(t, t('common.servers.idBot', 'LAMINA1 ID Bot')),
    studioBotConnection: defaultErrorMessage(
      t,
      t('common.servers.studioBot', 'LAMINA1 Studio Bot')
    ),
    indexerConnection: defaultErrorMessage(t, t('common.servers.l1Indexer', 'LAMINA1 Indexer')),
    spaceConnection: defaultErrorMessage(t, t('common.servers.l1Space', 'LAMINA1 Space')),
    gameBotConnection: defaultErrorMessage(
      t,
      t('common.servers.l1MvAssetDispenser', 'LAMINA1 Metaverse Asset Dispenser')
    ),

    // Specific error messages
    idBotRateLimit: (time: string) => idBotRateLimit(t, time),

    // Metamask errors
    metamaskConnection: t(
      'errors.metamaskConnection',
      'You are not connected to Metamask. Please connect to Metamask to continue.'
    ),
    metamaskCancelEvent: t(
      'errors.metamaskCancelEvent',
      'You rejected the Metamask event. Please try again.'
    ),

    // Webhooks errors
    cmsConnection: defaultErrorMessage(t, t('common.servers.l1CMS', 'LAMINA1 CMS')),
    nftDiscordSharing: defaultErrorMessage(
      t,
      t('common.servers.l1Discord', '#nfts channel on the LAMINA1 Discord')
    ),
    profileDiscordSharing: defaultErrorMessage(
      t,
      t('common.servers.profilel1Discord', '#profiles channel on the LAMINA1 Discord')
    ),
  }) as const

export type ErrorMessages = ReturnType<typeof getErrorMessagesNonMemoized>

export const getErrorMessages = (() => {
  const errorMessagesCache = new WeakMap<TFunction, ErrorMessages>()

  return (t: TFunction): ErrorMessages => {
    const memoizedErrorMessages = errorMessagesCache.get(t)
    if (memoizedErrorMessages) {
      return memoizedErrorMessages
    }

    const newErrorMessages = getErrorMessagesNonMemoized(t)
    errorMessagesCache.set(t, newErrorMessages)

    return newErrorMessages
  }
})()

export const useErrorMessages = () => {
  const { t } = useTranslation()
  return getErrorMessages(t)
}

export const globalConstants = {
  CROP_IMAGE_SIZE: {
    width: 512,
    height: 512,
  },
}

const getConstantsNonMemoized = (t: TFunction) =>
  ({
    DISCOVER_HEADER: t('components.discover.header', 'Discover the Open Metaverse'),
  }) as const

export type Constants = ReturnType<typeof getConstantsNonMemoized>

export const getConstants = (() => {
  const constantsCache = new WeakMap<TFunction, Constants>()

  return (t: TFunction): Constants => {
    const memoizedConstants = constantsCache.get(t)
    if (memoizedConstants) {
      return memoizedConstants
    }

    const newConstants = getConstantsNonMemoized(t)
    constantsCache.set(t, newConstants)

    return newConstants
  }
})()

export const useConstants = () => {
  const { t } = useTranslation()
  return getConstants(t)
}

import { Center } from '@mantine/core'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Loading from '@/components/Loading'

const ExploreRedirector: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/explore/creators')
  }, [navigate])

  return (
    <Center style={{ height: '100vh', width: '100vw' }}>
      <Loading />
    </Center>
  )
}

export default ExploreRedirector

import { Box, Stack, Title, TitleOrder, Tooltip, useMantineTheme } from '@mantine/core'
import { IconInfoCircleFilled } from '@tabler/icons-react'
import React from 'react'

interface TooltipTitleProps {
  title: string | React.ReactNode
  tooltip?: string | React.ReactNode
  position?: 'top' | 'bottom' | 'left' | 'right'
  order?: TitleOrder
  titleStyle?: React.CSSProperties
  outside?: boolean
}

const TooltipTitle: React.FC<TooltipTitleProps> = ({
  title,
  tooltip,
  position = 'top',
  order = 4,
  titleStyle,
  outside = false,
}) => {
  const theme = useMantineTheme()
  return (
    <>
      {outside ? (
        <Stack style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
          <Box
            style={{
              display: 'flex',
              gap: '5px',
            }}
          >
            <Title order={order} style={{ width: 'fit-content', ...titleStyle }}>
              {title}
            </Title>

            <Stack style={{ paddingTop: '3px' }}>
              {tooltip && (
                <Tooltip multiline label={tooltip} withArrow position={position} offset={10}>
                  <IconInfoCircleFilled size={15} style={{ color: theme.colors.textAndIcon[0] }} />
                </Tooltip>
              )}
            </Stack>
          </Box>
        </Stack>
      ) : (
        <Stack style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
          <Title order={order} style={{ width: 'fit-content', ...titleStyle }}>
            {title}
          </Title>
          {tooltip && (
            <Tooltip multiline label={tooltip} withArrow position={position} offset={10}>
              <IconInfoCircleFilled size={15} style={{ color: theme.colors.textAndIcon[0] }} />
            </Tooltip>
          )}
        </Stack>
      )}
    </>
  )
}

export default TooltipTitle

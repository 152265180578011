import { ActionIcon, Stack, useMantineTheme } from '@mantine/core'
import { IconCheck, IconCopy, IconLink } from '@tabler/icons-react'
import { TFunction } from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import copiedClipboard_blue from '../../assets/icons/copiedClipboard_blue.svg'
import copiedClipboard_green from '../../assets/icons/copiedClipboard_green.svg'
import useCopyClipboard from '../../hooks/useCopyToClipboard'
import IconWrapper from '../MediaWrappers/IconWrapper'
import Snackbar from '../Snackbar'
import classes from './CopyButton.module.css'
import RootButton from './RootButton'

export const callbackCopyMessage =
  (t: TFunction) =>
  // eslint-disable-next-line react/display-name
  (value: string, { copyMessageIcon }: any) => (
    <Snackbar
      value={value}
      title={t('common.copyButtonTitle', 'Copied to clipboard')}
      icon={
        <IconWrapper
          classes={copyMessageIcon}
          iconDark={copiedClipboard_green}
          iconLight={copiedClipboard_blue}
        />
      }
    />
  )

interface CopyButtonProps {
  value: string
  text?: string
  transparent?: boolean
  share?: boolean
  setIsCopied?: (isCopied: boolean) => void
}

const CopyButton: React.FC<CopyButtonProps> = ({
  value,
  text = '',
  transparent = false,
  share = false,
  setIsCopied,
}) => {
  const { t } = useTranslation()
  const [isCopied, staticCopy] = useCopyClipboard()
  const theme = useMantineTheme()

  const handleStopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  useEffect(() => {
    if (!setIsCopied) return
    setIsCopied(isCopied)
  }, [isCopied])

  return !text ? (
    <Stack onClick={handleStopPropagation}>
      <ActionIcon
        className={share ? classes.shareActionIcon : classes.rightActionButton}
        variant="outline"
        style={{ backgroundColor: transparent ? 'transparent' : theme.colors.background3[0] }}
        onClick={() => {
          staticCopy(value, classes, theme, callbackCopyMessage(t))
        }}
      >
        {share ? (
          <IconLink size="32px" className={classes.linkIcon} />
        ) : isCopied ? (
          <IconCheck size="16px" />
        ) : (
          <IconCopy size="16px" />
        )}
      </ActionIcon>
    </Stack>
  ) : (
    <RootButton
      leftSection={isCopied ? <IconCheck size="16px" /> : <IconCopy size="16px" />}
      onClick={() => {
        staticCopy(value, classes, theme, callbackCopyMessage(t))
      }}
      secondary
      style1={false}
      expand
    >
      {text}
    </RootButton>
  )
}

export default CopyButton

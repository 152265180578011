import { useRef } from 'react'

import { deepFreeze } from '@/utils/helpers'

const useConstFn = <T>(fn: () => T): T => {
  const ref = useRef<{ ret: T }>()
  if (!ref.current) {
    ref.current = { ret: fn() }
  }
  return ref.current.ret
}

const useConstReadonly = <T>(c: T): DeepReadonly<T> => {
  const ref = useRef<{ ret: DeepReadonly<T> }>()
  if (!ref.current) {
    ref.current = { ret: deepFreeze(c) }
  }
  return ref.current.ret
}

const useConstReadonlyFn = <T>(fn: () => T): DeepReadonly<T> => {
  const ref = useRef<{ ret: DeepReadonly<T> }>()
  if (!ref.current) {
    ref.current = { ret: deepFreeze(fn()) }
  }
  return ref.current.ret
}

type UseConst = {
  <T>(c: T): T
  fn: typeof useConstFn
  ro: typeof useConstReadonly
  roFn: typeof useConstReadonlyFn
}

const useConst: UseConst = (<T>(c: T) => {
  const ref = useRef<T>(c)
  return ref.current
}) as UseConst & Function as UseConst
useConst.fn = useConstFn
useConst.ro = useConstReadonly
useConst.roFn = useConstReadonlyFn

export default useConst

import { Modal, Stack, Title, useMantineTheme } from '@mantine/core'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { IconCameraFilled } from '@tabler/icons-react'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { namehash, zeroAddress } from 'viem'
import { normalize } from 'viem/ens'

import ipfsError from '@/assets/ipfsError.svg'
import {
  CONTRACT_RESOLVER_ADDRESS,
  CONTRACT_REVERSE_ADDRESS,
  FREE_MIN_BALANCE,
  ID_BOT,
  STUDIO_BOT,
} from '@/constants/blockchain'
import { useErrorMessages } from '@/constants/content'
import { useChainFunctions, useChainValues } from '@/hooks/useChain'
import useL1NS from '@/hooks/useL1NS'
import useLocalStorage from '@/hooks/useLocalStorage'
import { useAuth } from '@/plugins/auth'
import {
  getDefaultBannerImage,
  getDefaultProfileImage,
} from '@/plugins/auth/AuthProvider/effects/functions'
import { l1IdSubnetWagmiConfig } from '@/plugins/auth/config'
import { switchChain } from '@/plugins/auth/utils'
import {
  EditProfileDraft,
  ErrorMessageDisplay,
  post,
  ProfileUploadMode,
  StoredTheme,
  UserIdentity,
} from '@/utils/utils'

import L1Resolver from '../../../contracts/PublicResolver'
import L1Reverse from '../../../contracts/ReverseRegistrar'
import RootButton from '../../Buttons/RootButton'
import ErrorMessage from '../../ErrorMessage'
import { MAX_NICKNAME_LENGTH, MIN_NICKNAME_LENGTH, validateNickname } from '../../Identity/rules'
import Loading from '../../Loading'
import { ProfileMetadata } from '../../Profile/metadata'
import {
  BANNER_HEIGHT,
  BANNER_WIDTH,
  displayImage,
  PROFILE_HEIGHT,
  PROFILE_WIDTH,
} from '../../Profile/ProfileHeader'
import SpecialInputTile from '../../SpecialInputTile'
import { StringSizeRule } from '../../Studio/StepperElements/Info'
import UploadImage from '../../UploadImage'
import classes from './EditProfile.module.css'

const bioRules = {
  min: 0,
  max: 300,
}

const linkRules = {
  min: 0,
  max: 50,
}

const ErrorLoadingImage: React.FC = () => (
  <img
    src={ipfsError}
    style={{
      width: 'inherit',
      height: 'inherit',
      position: 'absolute',
      zIndex: '-1',
    }}
    alt="error"
  />
)

interface IEditProfileProps {
  profile: ProfileMetadata
  setProfile: (profile: ProfileMetadata) => void
  closeEdit: () => void
  uploadModeOn: ProfileUploadMode
  setUploadModeOn: (mode: ProfileUploadMode) => void
}

const EditProfile: React.FC<IEditProfileProps> = ({
  profile,
  setProfile,
  closeEdit,
  uploadModeOn,
  setUploadModeOn,
}) => {
  const theme = useMantineTheme()
  const { t } = useTranslation()
  const { address } = useAuth()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/

  const [userIdentity, setUserIdentity] = useLocalStorage<UserIdentity>(`userIdentity-${address}`)
  const errorMessages = useErrorMessages()
  const { getL1nsNodeOwner } = useL1NS()
  const { writeContract } = useChainFunctions(l1IdSubnetWagmiConfig)
  const { balance } = useChainValues(l1IdSubnetWagmiConfig.id)

  // loading states
  const [imageLoadedBanner, setImageLoadedBanner] = useState<boolean>(false)
  const [imageLoadedProfile, setImageLoadedProfile] = useState<boolean>(false)

  // error handling
  const [imageLoadedBannerError, setImageLoadedBannerError] = useState<boolean>(false)
  const [imageLoadedProfileError, setImageLoadedProfileError] = useState<boolean>(false)

  // profile variables
  const [bio, setBio] = useState<EditProfileDraft>({
    draft: profile?.description,
    touched: false,
  })
  const [link, setLink] = useState<EditProfileDraft>({
    draft: profile?.creator?.link,
    touched: false,
  })
  const [nickname, setNickname] = useState<EditProfileDraft>({
    draft: profile?.name,
    touched: false,
  })
  const [profileImage, setProfileImage] = useState<string>(profile?.image)
  const [bannerImage, setBannerImage] = useState<string>(profile?.bannerImage)

  // default profile images
  const [userTheme] = useLocalStorage<StoredTheme>('userTheme')
  const defaultPfp = useMemo(() => getDefaultProfileImage(userTheme?.isLightMode), [userTheme])
  const defaultBannerImg = useMemo(() => getDefaultBannerImage(userTheme?.isLightMode), [userTheme])

  // error and loading handling
  const [loading, setLoading] = useState<boolean>(false)
  const [openedErrorModal, { open: openErrorModal, close: closeErrorModal }] = useDisclosure(false)
  const [errorMessage, setErrorMessage] = useState<ErrorMessageDisplay>({
    message: '',
    details: '',
    report: true,
  })
  const nicknameErrorMessage = useMemo(
    () => validateNickname(t, profile?.name ?? '', nickname.draft),
    [t, nickname]
  )

  // resize original banner width and height to fit in modal but keep same aspect ratio
  const resizedImages = useMemo(() => {
    const fitBannerModalWidth = isMobile ? 310 : 570
    const resizeBannerRatio = Math.min(
      1,
      Math.min(fitBannerModalWidth / BANNER_WIDTH, fitBannerModalWidth / BANNER_HEIGHT)
    )

    const fitProfileModalWidth = isMobile ? 300 : 350
    const resizeProfileRatio = Math.min(
      1,
      Math.min(fitProfileModalWidth / PROFILE_WIDTH, fitProfileModalWidth / PROFILE_HEIGHT)
    )
    return {
      banner: {
        width: BANNER_WIDTH * resizeBannerRatio,
        height: BANNER_HEIGHT * resizeBannerRatio,
      },
      profile: {
        width: PROFILE_WIDTH * resizeProfileRatio,
        height: PROFILE_HEIGHT * resizeProfileRatio,
      },
    }
  }, [isMobile])

  const handleError = useCallback(
    (err: ErrorMessageDisplay) => {
      setLoading(false)
      setErrorMessage(err)
      openErrorModal()
    },
    [setLoading, setErrorMessage, openErrorModal]
  )

  const handleCloseError = useCallback(() => {
    closeErrorModal()
    setErrorMessage({ message: '', details: '', report: true })
    closeEdit()
  }, [closeErrorModal, setErrorMessage, closeEdit])

  const writeProfileSubnet = async (addr: string, cid: string): Promise<boolean> => {
    if (parseFloat(balance.fullValue) < FREE_MIN_BALANCE) {
      // Post Faucet to request L1ID tokens
      try {
        const retval = await post(`${ID_BOT}/faucet`, JSON.stringify({ address }))
        if (retval?.txHash === '') {
          // assume the time string is of the time "23h50m15s"
          const time = (retval.ttl as string)
            .replace('s', ' seconds')
            .replace('h', ' hours ')
            .replace('m', ' minutes ')

          handleError({
            message: errorMessages.idBotRateLimit(time),
            details: retval.error,
            report: false,
          })
          return false
        }
      } catch (err: any) {
        handleError({ message: errorMessages.idBotConnection, details: err.message })
        return false
      }
    }

    const node = namehash(normalize(`${addr.substring(2)}.addr.reverse`))
    const ownerAddr = await getL1nsNodeOwner(node)

    try {
      // Switch to L1 subnet
      await switchChain(true, false)

      if (ownerAddr !== addr) {
        // Claim reverse
        await writeContract({
          address: CONTRACT_REVERSE_ADDRESS,
          abi: L1Reverse,
          functionName: 'claim',
          args: [addr],
        })
      }

      // Set profile IPFS url on resolver
      await writeContract({
        address: CONTRACT_RESOLVER_ADDRESS,
        abi: L1Resolver,
        functionName: 'setText',
        args: [node, 'profile', `ipfs://${cid}`],
      })

      // Switch back to L1 Native
      await switchChain(false, false)

      return true
    } catch (err: any) {
      handleError({
        message: errorMessages.rpcIdentityConnection,
        details: err.message,
      })
      return false
    }
  }

  // Submit profile
  const onSave = useCallback(async () => {
    // Build profile metadata
    let profileMetadata: ProfileMetadata = {
      description: bio.draft,
      name: nickname.draft,
      creator: {
        name: address || zeroAddress,
        link: link.draft || profile?.creator?.link,
      },
      image: profileImage || profile?.image,
      bannerImage: bannerImage || profile?.bannerImage,
    }
    // Filter links. Only accept links with https or ipfs for creator link, banner and profile images
    profileMetadata = {
      ...profileMetadata,
      creator: {
        name: address || '',
        link: link.draft?.match(urlRegex) ? link.draft : '',
      },
      image: profileImage?.match(/^(https?:\/\/|ipfs:\/\/).*/) ? profileImage : '',
      bannerImage: bannerImage?.match(/^(https?:\/\/|ipfs:\/\/).*/) ? bannerImage : '',
    }

    // Submit metadata to IPFS
    let retval: { cid: string; error: string | JSX.Element } = {
      cid: '',
      error: '',
    }
    const jsonBody = JSON.stringify({
      metadata: JSON.stringify(profileMetadata),
    })
    try {
      setLoading(true)
      const serverRes = await post(`${STUDIO_BOT}/upload`, jsonBody)
      // Server Error
      if (!serverRes.valid) {
        handleError({
          message: errorMessages.studioBotConnection,
          details: serverRes.error as string,
        })
        retval = {
          cid: '',
          error: serverRes.error,
        }
        return retval
      }
      // Success
      retval = {
        cid: serverRes.cid,
        error: '',
      }
    } catch (err: any) {
      if (err.message.includes('Failed to fetch')) {
        handleError({
          message: errorMessages.studioBotConnection,
          details: err.message,
        })
        retval = {
          cid: '',
          error: errorMessages.studioBotConnection,
        }
      } else {
        handleError({
          message: 'Generic error',
          details: err.message,
        })
        retval = {
          cid: '',
          error: err.message,
        }
      }
    }
    // Set IPFS link on resolver
    if (retval.cid !== '' && address) {
      try {
        const res = await writeProfileSubnet(address, retval.cid)
        if (userIdentity && res) {
          setUserIdentity({
            ...userIdentity,
            nickname: nickname.draft,
            bio: bio.draft,
            link: link.draft,
            profileImage,
            bannerImage,
          })
          setProfile(profileMetadata)
        }
      } catch (err: any) {
        handleError({
          message: errorMessages.rpcIdentityConnection,
          details: err.message,
        })
      }
    }
    if (!errorMessage.message) closeEdit()
    setLoading(false)
    return retval
  }, [address, bio, link, userIdentity, nickname, profileImage, bannerImage])

  return (
    <>
      <Stack style={{ minHeight: '550px' }}>
        {userIdentity && !loading && !errorMessage.message && (
          <>
            <Stack style={{ position: 'relative', height: '150px' }}>
              <Stack
                className={classes.bgImageStack}
                style={{
                  cursor:
                    uploadModeOn.profile || (!uploadModeOn.banner && !uploadModeOn.profile)
                      ? 'pointer'
                      : 'default',
                  backgroundImage: imageLoadedBanner
                    ? `url(${displayImage(defaultBannerImg, bannerImage)})`
                    : '',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
                onClick={() => setUploadModeOn({ profile: false, banner: true })}
              >
                {imageLoadedBannerError && <ErrorLoadingImage />}
                {!imageLoadedBanner && (
                  <Loading
                    style={{
                      position: 'absolute',
                      height: 'inherit',
                    }}
                  />
                )}
                <img
                  src={displayImage(defaultBannerImg, bannerImage)}
                  style={{ display: 'none' }}
                  alt="banner"
                  onLoad={() => {
                    setImageLoadedBanner(true)
                  }}
                  onError={() => {
                    setImageLoadedBanner(true)
                    setImageLoadedBannerError(true)
                  }}
                />
                {!uploadModeOn.banner && imageLoadedBanner && (
                  <IconCameraFilled className={classes.cameraIcon} />
                )}
              </Stack>
              <Stack
                className={classes.userCardImage}
                style={{
                  cursor:
                    uploadModeOn.banner || (!uploadModeOn.banner && !uploadModeOn.profile)
                      ? 'pointer'
                      : 'default',
                }}
                onClick={() => setUploadModeOn({ banner: false, profile: true })}
              >
                {imageLoadedProfileError && <ErrorLoadingImage />}
                {!imageLoadedProfile && (
                  <Loading
                    style={{
                      position: 'absolute',
                      height: 'inherit',
                    }}
                  />
                )}
                <img
                  style={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '6px',
                    position: 'absolute',
                    top: 0,
                  }}
                  src={displayImage(defaultPfp, profileImage)}
                  alt="profile"
                  onLoad={() => setImageLoadedProfile(true)}
                  onError={() => {
                    setImageLoadedProfile(true)
                    setImageLoadedProfileError(true)
                  }}
                />
                {!uploadModeOn.profile && imageLoadedProfile && (
                  <IconCameraFilled className={classes.cameraIcon} />
                )}
              </Stack>
            </Stack>
            {!uploadModeOn.profile && !uploadModeOn.banner && (
              <Stack style={{ gap: '20px' }}>
                <Stack style={{ gap: '10px' }}>
                  <Title order={4}>
                    {t('components.profile.modal.displayName', 'Display Name')}
                  </Title>
                  <SpecialInputTile
                    placeholder="Input your display name here"
                    value={nickname.draft}
                    onChange={e => {
                      if (e.currentTarget.value === '') setNickname({ draft: '', touched: false })
                      else setNickname({ draft: e.currentTarget.value, touched: true })
                    }}
                    errorMessage={
                      nicknameErrorMessage && nickname.touched ? nicknameErrorMessage : ''
                    }
                    rule={
                      <StringSizeRule
                        countChars={nickname.draft.length}
                        minChars={nickname.touched ? MIN_NICKNAME_LENGTH : 0}
                        maxChars={MAX_NICKNAME_LENGTH}
                      />
                    }
                    identitySetup
                  />
                </Stack>
                <Stack style={{ gap: '10px' }}>
                  <Title order={4}>{t('components.profile.modal.Bio', 'Bio')}</Title>
                  <SpecialInputTile
                    value={bio.draft}
                    placeholder="Input your bio here"
                    rule={
                      <StringSizeRule
                        countChars={bio.draft.length}
                        minChars={bioRules.min}
                        maxChars={bioRules.max}
                      />
                    }
                    errorMessage={
                      (bio.draft.length > bioRules.max || bio.draft.length < bioRules.min) &&
                      bio.touched
                        ? `Please type in a bio that’s between ${bioRules.min} and ${bioRules.max} characters`
                        : ''
                    }
                    onChange={e => {
                      setBio({ draft: e.target.value, touched: true })
                    }}
                    longInput
                  />
                </Stack>
                <Stack style={{ gap: '10px' }}>
                  <Title order={4}>{t('components.profile.modal.website', 'Website')}</Title>
                  <SpecialInputTile
                    value={link.draft}
                    placeholder="Input your link here"
                    rule={
                      <StringSizeRule
                        countChars={link.draft.length}
                        minChars={linkRules.min}
                        maxChars={linkRules.max}
                      />
                    }
                    errorMessage={
                      (link.draft.length > linkRules.max || link.draft.length < linkRules.min) &&
                      link.touched &&
                      link.draft?.match(urlRegex)
                        ? `Please type in a link that’s between ${linkRules.min} and ${linkRules.max} characters`
                        : link.touched && !link.draft?.match(urlRegex) && link.draft !== ''
                          ? 'Please type in a valid URL'
                          : ''
                    }
                    onChange={e => {
                      setLink({ draft: e.target.value, touched: true })
                    }}
                  />
                </Stack>
                <Stack style={{ flexDirection: 'row', gap: 10 }}>
                  <RootButton style1={false} expand secondary onClick={closeEdit}>
                    {t('buttons.cancel', 'Cancel')}
                  </RootButton>
                  <RootButton
                    style1={false}
                    expand
                    onClick={onSave}
                    disabled={
                      bio.draft.length > bioRules.max ||
                      bio.draft.length < bioRules.min ||
                      link.draft.length > linkRules.max ||
                      link.draft.length < linkRules.min ||
                      (nickname.touched &&
                        (nickname.draft.length > MAX_NICKNAME_LENGTH ||
                          nickname.draft.length < MIN_NICKNAME_LENGTH ||
                          nicknameErrorMessage !== ''))
                    }
                  >
                    {t('buttons.save', 'Save')}
                  </RootButton>
                </Stack>
              </Stack>
            )}
            {(uploadModeOn.profile || uploadModeOn.banner) && (
              <UploadImage
                uploadClose={() => setUploadModeOn({ banner: false, profile: false })}
                title={
                  uploadModeOn.profile
                    ? t('components.profile.modal.avatarTitle', 'Select Your Profile Picture')
                    : t('components.profile.modal.bannerTitle', 'Select Your Banner Picture')
                }
                subtitle={
                  uploadModeOn.profile
                    ? t(
                        'components.profile.modal.avatarSubtitle',
                        'Upload an image to use as your profile picture and 2D avatar on LAMINA1.'
                      )
                    : t(
                        'components.profile.modal.bannerSubtitle',
                        'Upload an image to use as your banner picture.'
                      )
                }
                width={
                  uploadModeOn.profile ? resizedImages.profile.width : resizedImages.banner.width
                }
                height={
                  uploadModeOn.profile ? resizedImages.profile.height : resizedImages.banner.height
                }
                isProfileImage={uploadModeOn.profile}
                setImage={uploadModeOn.profile ? setProfileImage : setBannerImage}
              />
            )}
          </>
        )}
        {loading && <Loading />}
      </Stack>
      <Modal
        opened={openedErrorModal}
        onClose={handleCloseError}
        size="lg"
        lockScroll={false}
        style={{ header: { margin: '0 1.6px' } }}
      >
        <ErrorMessage
          message={errorMessage.message}
          details={errorMessage.details}
          report={errorMessage.report}
        />
      </Modal>
    </>
  )
}
export default EditProfile

import { Stack, Text } from '@mantine/core'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import RootButton from '../../Buttons/RootButton'

interface ConfirmTransactionProps {
  close: () => void
  confirm: () => void
}

const ConfirmTransaction: React.FC<ConfirmTransactionProps> = ({ close, confirm }) => {
  const { t } = useTranslation()
  const isConfirming = useRef<boolean>(false)

  const handleConfirm = () => {
    isConfirming.current = true
    confirm()
  }

  return (
    <Stack style={{ flexDirection: 'column', gap: '20px' }}>
      <Text style={{ fontSize: '18px', marginRight: 1 }}>
        Are you sure you want to send this transaction?
      </Text>
      <Stack style={{ flexDirection: 'row', gap: '8px' }}>
        <RootButton secondary onClick={close} style1={false} expand>
          {t('buttons.cancel', 'Cancel')}
        </RootButton>
        <RootButton style1={false} onClick={handleConfirm} disabled={isConfirming.current} expand>
          {isConfirming.current
            ? t('buttons.confirming', 'Confirming...')
            : t('buttons.confirm', 'Confirm')}
        </RootButton>
      </Stack>
    </Stack>
  )
}

export default ConfirmTransaction

import { Button, Center, Grid, Title } from '@mantine/core'
import React, { useCallback, useEffect, useMemo } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useNavigate } from 'react-router-dom'

import { noop } from '@/utils/helpers'

/**
 * Minimum with of the text in the error boundary
 * so that it can fit without overflowing
 */
const textMinWidth = 450
const textMinHeight = 90

type SkeletonProps = {
  setLocation: (href: string) => void
  text: Record<'error' | 'tryAgain' | 'restart', string>
}

const EnglishText: SkeletonProps['text'] = {
  error: 'Error',
  tryAgain: 'Try again',
  restart: 'Restart',
}

const ErrorBoundaryFallbackSkeleton: React.FC<FallbackProps & SkeletonProps> = ({
  resetErrorBoundary,
  setLocation,
  text,
}) => {
  const restartHandle = useCallback(() => {
    setLocation('/')
    resetErrorBoundary()
  }, [setLocation, resetErrorBoundary])

  return (
    <Center style={{ position: 'relative' }}>
      <Grid
        px="sm"
        style={{ width: '100%', height: '100%', maxWidth: textMinWidth, maxHeight: textMinHeight }}
      >
        <Grid.Col span={12}>
          <Title order={3} style={{ align: 'center', textAlign: 'center' }}>
            {text.error}
          </Title>
        </Grid.Col>
        <Grid.Col span={12} style={{ alignItems: '', gap: '20px' }}>
          <Button onClick={resetErrorBoundary} color="dark" ml="sm">
            {text.tryAgain}
          </Button>
          <Button onClick={restartHandle} color="dark" ml="sm">
            {text.restart}
          </Button>
        </Grid.Col>
      </Grid>
    </Center>
  )
}

const ErrorBoundaryWithContexts: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const navigate = useNavigate()

  return (
    <ErrorBoundaryFallbackSkeleton
      error={error}
      resetErrorBoundary={resetErrorBoundary}
      setLocation={navigate}
      text={EnglishText}
    />
  )
}

const ErrorBoundaryFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  const noContextSetLocation: SkeletonProps['setLocation'] = useCallback((href: string) => {
    if (typeof window === 'undefined') {
      console.error('ErrorBoundaryFallback: window is undefined; cannot navigate; running in SSR?')
      return
    }
    window.location.href = href
  }, [])

  // This is a fallback that's used when the full component with context consumers
  // fails to render due to the lack of context providers above it in the component
  // tree. When that happens, this fallback will be used, which only uses native APIs
  // with the downside of having no translations
  const noContextFallback = useMemo(
    () => (
      <ErrorBoundaryFallbackSkeleton
        error={error}
        resetErrorBoundary={resetErrorBoundary}
        setLocation={noContextSetLocation}
        text={EnglishText}
      />
    ),
    [error, resetErrorBoundary, noContextSetLocation]
  )

  return (
    <ErrorBoundary fallback={noContextFallback} onError={noop}>
      {/*
        Tries to use the fallback with context hooks. If it throws on render, it's because
        this boundary fallback was called outside the context of a router or translation provider,
        so instead we use a version of this fallback that doesn't use context hooks, so it will
        use window.location instead of navigate, and English text instead of translated text.
      */}
      <ErrorBoundaryWithContexts error={error} resetErrorBoundary={resetErrorBoundary} />
    </ErrorBoundary>
  )
}

export default ErrorBoundaryFallback

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWaitForTransactionReceipt } from 'wagmi'

import ErrorMessage from '../ErrorMessage'
import { ConfirmationLoading, ConfirmationReady } from '../Identity/ConfirmModals/utils'

interface OnTransferProps {
  hash: string
  chainId: number
  loadingMessage?: string
  confirmationMessage?: string
  onClose?: () => void
  successMessage?: string
  success: () => void
}

const OnTransfer: React.FC<OnTransferProps> = ({
  hash,
  chainId,
  loadingMessage,
  confirmationMessage,
  successMessage = 'Continue',
  success,
  onClose,
}) => {
  const { t } = useTranslation()
  const [error, setError] = useState<Error>()
  const {
    isSuccess,
    isLoading,
    error: errorReceipt,
  } = useWaitForTransactionReceipt({
    hash: hash as `0x${string}`,
    chainId,
  })
  useEffect(() => {
    if (errorReceipt) {
      setError(errorReceipt)
    } else {
      setError(undefined)
    }
  }, [errorReceipt])

  return (
    <>
      {error && !isLoading && (
        <ErrorMessage
          message={t('components.error.transaction', 'Error sending transaction')}
          details={error.message}
        />
      )}
      {isLoading && <ConfirmationLoading title={loadingMessage} />}
      {isSuccess && (
        <ConfirmationReady
          message={confirmationMessage}
          success={success}
          onClose={onClose}
          successMessage={successMessage}
        />
      )}
    </>
  )
}

export default OnTransfer

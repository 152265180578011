import { ActionIcon, Menu, Stack, Text, useMantineTheme } from '@mantine/core'
import * as Sentry from '@sentry/react'
import React, { useCallback, useEffect, useState } from 'react'

import useL1NS from '@/hooks/useL1NS'
import { useAuth } from '@/plugins/auth'
import { shortenHash, truncateIdentity } from '@/utils/utils'

import OpenMenuArrow from './Animations/OpenMenuArrow/OpenMenuArrow'
import CopyButton from './Buttons/CopyButton'
import classes from './DisplayUsername.module.css'

const MAX_CHARS = 15

interface DisplayUsernameProps {
  truncate?: boolean
  address: string
  username?: string
  colorless?: boolean
  noButton?: boolean
}
const DisplayUsername: React.FC<DisplayUsernameProps> = ({
  truncate = true,
  address,
  username,
  colorless = false,
  noButton = false,
}) => {
  const theme = useMantineTheme()
  const { userIdentity } = useAuth()
  const [menuOpened, setMenuOpened] = useState<boolean>(false)
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const { getL1nsName } = useL1NS()
  const [profileUsername, setProfileUsername] = useState<string | undefined>(undefined)

  useEffect(() => {
    const getUsername = async () => {
      if (username) {
        setProfileUsername(username)
      }
      // If the address is the same as the user's address, get the username
      if (address === userIdentity?.address) {
        if (userIdentity?.username) {
          setProfileUsername(userIdentity.username)
        } else {
          try {
            const pUsername = await getL1nsName(address)
            setProfileUsername(pUsername ?? undefined)
          } catch {
            Sentry.withScope(scope => {
              scope.setTag('ignore_error', 'true')
            })
          }
        }
      }
    }

    getUsername()
  }, [address, getL1nsName, username])

  useEffect(() => {
    if (isCopied) setMenuOpened(false)
  }, [isCopied])

  const handleToggleMenu = useCallback(
    (e: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      setMenuOpened(previous => !previous)
    },
    []
  )

  return (
    address && (
      <Menu
        trigger="click"
        openDelay={500}
        closeDelay={100000}
        opened={menuOpened}
        onClose={() => setMenuOpened(false)}
        closeOnClickOutside
        closeOnEscape
        transitionProps={{ transition: 'fade-up', duration: 200 }}
        position="bottom-end"
      >
        <Menu.Target>
          <Stack
            style={{
              flexDirection: 'row',
              gap: '5px',
              alignItems: 'center',
              cursor: 'pointer',
              width: 'fit-content',
            }}
            onClick={noButton ? undefined : handleToggleMenu}
          >
            <>
              <Text
                style={{
                  color: noButton
                    ? theme.colors.textAndIcon[0]
                    : colorless
                      ? theme.colors.title[0]
                      : theme.colors.l1Primary[0],
                  fontFamily: profileUsername ? 'monument-grotesk-bold' : 'monospace',
                  fontSize: '14px',
                  fontWeight: 700,
                  textAlign: 'left',
                }}
              >
                {profileUsername
                  ? truncate
                    ? `@${truncateIdentity(profileUsername, MAX_CHARS)}`
                    : `@${profileUsername}`
                  : truncate
                    ? shortenHash(address)
                    : address}
              </Text>
              {!noButton && (
                <ActionIcon onClick={handleToggleMenu}>
                  <OpenMenuArrow menuOpened={menuOpened} />
                </ActionIcon>
              )}
            </>
          </Stack>
        </Menu.Target>
        <Menu.Dropdown className={classes.dropdown}>
          <Stack className={classes.selectionButton}>
            <Text style={{ fontFamily: 'monospace' }}>{shortenHash(address)}</Text>
            <CopyButton value={address} transparent setIsCopied={setIsCopied} />
          </Stack>
          {profileUsername && (
            <Stack className={classes.selectionButton}>
              <Text>{truncateIdentity(profileUsername, MAX_CHARS)}</Text>
              <CopyButton value={profileUsername} transparent setIsCopied={setIsCopied} />
            </Stack>
          )}
        </Menu.Dropdown>
      </Menu>
    )
  )
}

export default React.memo(DisplayUsername)

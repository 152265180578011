import { Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import homeBlackIcon from '@/assets/icons/home_black.svg'
import homeWhiteIcon from '@/assets/icons/home_white.svg'
import { BodyStack } from '@/styled/components'

import RootButton from './Buttons/RootButton'
import HeaderIconWrapper from './Header/HeaderIconWrapper'

const NotFound: React.FC = () => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const navigate = useNavigate()

  const ref = useRef(null)
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const handleHoverEnter = useCallback(() => {
    setIsHovered(true)
  }, [])

  const handleHoverLeave = useCallback(() => {
    setIsHovered(false)
  }, [])

  const goHome = () => {
    navigate('/home')
  }

  useEffect(() => {
    window.sessionStorage.removeItem('oldRoute')
  }, [])

  return (
    <BodyStack>
      <Title order={isMobile ? 3 : 2}>{t('common.notFoundTitle', 'Page Not Found')}</Title>
      <Stack style={{ gap: '30px' }}>
        <Text
          style={{
            fontSize: isMobile ? '27px' : '34px',
          }}
        >
          {t(
            'common.notFoundMessage',
            `We're sorry, but the page you're trying to reach does not exist or is currently under construction.`
          )}
        </Text>
        <div
          ref={ref}
          onMouseEnter={handleHoverEnter}
          onMouseLeave={handleHoverLeave}
          style={{ width: 'fit-content', height: 'fit-content' }}
        >
          <RootButton
            onClick={goHome}
            leftSection={
              <HeaderIconWrapper
                hovered={isHovered}
                iconDark={homeBlackIcon}
                iconLight={homeWhiteIcon}
                hoveredIconDark={homeBlackIcon}
                hoveredIconLight={homeWhiteIcon}
              />
            }
            secondary
          >
            {t('common.returnHome', 'Return Home')}
          </RootButton>
        </div>
      </Stack>
    </BodyStack>
  )
}

export default NotFound

import { Stack, Text, Title } from '@mantine/core'
import React from 'react'

import classes from './Snackbar.module.css'

interface SnackbarProps {
  value: string | JSX.Element
  title: string
  icon: JSX.Element
  rightElement?: JSX.Element
}

const Snackbar: React.FC<SnackbarProps> = ({ value, title, icon, rightElement = null }) => (
  <Stack className={classes.copyMessageModal}>
    {icon}
    <Stack className={classes.copyMessageHeader}>
      <Title className={classes.copyMessageTitle}>{title}</Title>
      <Text className={classes.copyMessageValue}>{value}</Text>
    </Stack>
    <Stack className={classes.rightElement}>{rightElement}</Stack>
  </Stack>
)

export default Snackbar

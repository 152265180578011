import {
  NumberInput,
  NumberInputProps,
  Stack,
  Text,
  Textarea,
  TextareaProps,
  TextInput,
  TextInputProps,
  useMantineTheme,
} from '@mantine/core'
import React, { useMemo } from 'react'

import classes from './SpecialInputText.module.css'

export const AtIcon: React.FC = () => (
  <Text size="xl" className={classes.atIcon}>
    @
  </Text>
)

export const CheckedIcon: React.FC = () => (
  <Text size="xl" className={classes.checkedIcon}>
    ✓
  </Text>
)

export const InvalidIcon: React.FC = () => (
  <Text size="xl" className={classes.invalidIcon}>
    ✕
  </Text>
)

export type SpecialInputConditionalProps =
  | ({ longInput: true } & TextareaProps)
  | ({ longInput?: false } & TextInputProps)
  | ({ isAmount: true; longInput?: false } & NumberInputProps)

export type SpecialInputTextProps = {
  iconLeft?: JSX.Element | null
  leftSectionWidth?: string
  value: string | number
  borderStatus?: 'default' | 'success' | 'error'
  placeholder?: string
  iconRight?: JSX.Element | null
  rightSectionWidth?: string
  isAmount?: boolean
  identitySetup?: boolean
  tipLeft?: string
  tipRight?: string
  width?: string
  paddingLeft?: string
} & SpecialInputConditionalProps

export const SpecialInputText: React.FC<SpecialInputTextProps> = ({
  iconLeft = null,
  placeholder = 'Insert Text',
  leftSectionWidth = '',
  rightSectionWidth = '50px',
  value,
  borderStatus = 'default',
  iconRight,
  longInput = false,
  identitySetup = false,
  isAmount = false,
  tipLeft = '',
  tipRight = '',
  ...props
}) => {
  const theme = useMantineTheme()
  const { error: errorMessage, ...newProps } = props

  const input = useMemo(() => {
    if (longInput) {
      return (
        <Textarea
          leftSectionWidth={leftSectionWidth}
          rightSectionWidth={rightSectionWidth}
          leftSection={iconLeft}
          style={{
            input: {
              fontSize: !isAmount ? '16px' : '18px',
            },
          }}
          placeholder={placeholder}
          value={value}
          autosize
          minRows={3}
          maxRows={6}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault()
            }
          }}
          {...(newProps as TextareaProps)}
        />
      )
    }
    if (isAmount) {
      return (
        <NumberInput
          className={
            borderStatus === 'error'
              ? classes.textFieldError
              : borderStatus === 'success'
                ? classes.textFieldSuccess
                : classes.textField
          }
          leftSectionWidth={leftSectionWidth}
          rightSectionWidth={rightSectionWidth}
          style={{
            input: {
              fontSize: '18px',
            },
          }}
          placeholder={placeholder}
          leftSection={iconLeft}
          rightSection={iconRight}
          value={value}
          {...(newProps as NumberInputProps)}
        />
      )
    }
    return (
      <TextInput
        className={
          borderStatus === 'error'
            ? classes.textFieldError
            : borderStatus === 'success'
              ? classes.textFieldSuccess
              : classes.textField
        }
        leftSectionWidth={leftSectionWidth}
        rightSectionWidth={rightSectionWidth}
        style={{
          input: {
            fontSize: '18px',
          },
        }}
        type="text"
        placeholder={placeholder}
        leftSection={iconLeft}
        rightSection={iconRight}
        value={value}
        {...(newProps as TextInputProps)}
      />
    )
  }, [
    longInput,
    leftSectionWidth,
    rightSectionWidth,
    iconLeft,
    placeholder,
    value,
    borderStatus,
    iconRight,
    isAmount,
    tipLeft,
    tipRight,
    newProps,
  ])

  return (
    <Stack style={{ gap: '0px' }}>
      {input}
      {!identitySetup && (
        <Stack style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          {errorMessage ? (
            <Text size="sm" className={classes.errorMessage}>
              {errorMessage}
            </Text>
          ) : (
            <Text size="sm" style={{ color: theme.colors.paragraph[0], textAlign: 'left' }}>
              {tipLeft}
            </Text>
          )}
          {tipRight !== '' && (
            <Text size="sm" style={{ color: theme.colors.paragraph[0], textAlign: 'right' }}>
              {tipRight}
            </Text>
          )}
        </Stack>
      )}
    </Stack>
  )
}

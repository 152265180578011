import { useEffect } from 'react'

import useLocalStorage from './useLocalStorage'

export type NetworkType = 'betanet' | 'mainnet' | 'testnet'

const useNetworkType = (): {
  simulatedNetworkType: NetworkType | null
  isBetanet: boolean
  isMainnet: boolean
  isTestnet: boolean
  simulateNetwork: (networkType: NetworkType) => void
} => {
  const [simulatedNetwork, setSimulatedNetwork] = useLocalStorage<NetworkType | null>(
    'simulatedNetwork'
  )

  const simulatedNetworkType = simulatedNetwork || null

  // useEffect to delete local simulatedNetwork if networkType is different from testnet
  useEffect(() => {
    if (process.env.NETWORK_TYPE !== 'testnet') {
      localStorage.removeItem('simulatedNetwork')
    }
  }, [process.env.NETWORK_TYPE])

  const isTestnet = process.env.NETWORK_TYPE === 'testnet' || false
  const isBetanet =
    simulatedNetwork === 'betanet' || process.env.NETWORK_TYPE === 'betanet' || false
  const isMainnet = simulatedNetwork === 'mainnet' || process.env.NETWORK_TYPE === 'mainnet' || true

  const simulateNetwork = (type: NetworkType) => {
    setSimulatedNetwork(type)
  }

  return { simulatedNetworkType, isBetanet, isMainnet, isTestnet, simulateNetwork }
}

export default useNetworkType

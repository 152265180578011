import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react'

interface ModalContextType {
  openModal: (modalType: string, modalProps?: any) => void
  closeModal: () => void
  modalType: string | null
  modalProps: any
}

const ModalContext = createContext<ModalContextType | undefined>(undefined)

export const useModalContext = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModalContext must be used within a ModalProvider')
  }
  return context
}

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [modalType, setModalType] = useState<string | null>(null)
  const [modalProps, setModalProps] = useState<any>(null)

  const openModal = (type: string, props = {}) => {
    setModalType(type)
    setModalProps(props)
  }

  const closeModal = () => {
    setModalType(null)
    setModalProps(null)
  }

  const contextValue = useMemo(
    () => ({ openModal, closeModal, modalType, modalProps }),
    [openModal, closeModal, modalType, modalProps]
  )

  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>
}

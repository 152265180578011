import { Modal, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import React from 'react'

interface ModalSkeletonProps {
  title?: string | JSX.Element
  size?: string
  fullScreen?: boolean
  opened: boolean
  onClose?: () => void
  children: React.ReactNode
  allowModalCloseOutside?: boolean
  backgroundColor?: string
}

const ModalSkeleton: React.FC<ModalSkeletonProps> = ({
  title,
  opened,
  onClose,
  children,
  size = 'md',
  fullScreen = false,
  allowModalCloseOutside = true,
  backgroundColor = '',
}) => {
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  return (
    <Modal.Root
      opened={opened}
      onClose={allowModalCloseOutside && onClose ? onClose : () => {}}
      lockScroll={false}
      fullScreen={fullScreen}
      style={{
        section: {
          backgroundColor,
          maxWidth: fullScreen ? '' : !isMobile ? '610px' : '350px !important',
          zIndex: 300,
        },
      }}
      size={size}
    >
      <Modal.Overlay style={{ zIndex: 200 }} />
      <Modal.Content
        style={{
          border: !title ? 'none' : '',
        }}
      >
        {title && (
          <Modal.Header>
            <Modal.Title>{typeof title === 'string' ? title : title}</Modal.Title>
            {onClose && <Modal.CloseButton style={{ alignSelf: 'baseline' }} onClick={onClose} />}
          </Modal.Header>
        )}
        <Modal.Body
          style={{
            padding: !title ? '0' : '',
          }}
        >
          <>{children}</>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  )
}

export default ModalSkeleton

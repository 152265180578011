import { Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useBalancesContext } from '@/contexts/BalancesContext'
import { useModalContext } from '@/contexts/ModalContext'
import { ProcessedBalance } from '@/utils/utils'

import RootButton from '../Buttons/RootButton'
import ErrorMessage from '../ErrorMessage'
import Loading from '../Loading'
import PageSelector from '../PageSelector'
import TokenDisplay from '../Tokens/TokensDisplay'
import classes from './BalancesDisplay.module.css'

interface NetworkBalancesProps {
  network: string
  nativeBalance: ProcessedBalance
  erc20Balance?: ProcessedBalance
  rl1Balance?: ProcessedBalance
  ll1Balance?: ProcessedBalance
  stakeBalance?: ProcessedBalance
}

const NetworkBalances: React.FC<NetworkBalancesProps> = ({
  network,
  nativeBalance,
  erc20Balance,
  rl1Balance,
  ll1Balance,
  stakeBalance,
}) => (
  <Stack style={{ gap: '8px', minHeight: '180px' }}>
    <Stack className={classes.stackNetworkBalance}>
      <TokenDisplay token="L1" />
      <Text className={classes.balanceAmount}>{`${
        network === 'Lamina1 Network' ? nativeBalance.display : erc20Balance?.display
      } `}</Text>
    </Stack>
    {erc20Balance && (
      <Stack className={classes.stackNetworkBalance}>
        <TokenDisplay token="AVAX" />
        <Text className={classes.balanceAmount}>{`${
          network === 'Avalanche Network' ? nativeBalance.display : erc20Balance.display
        } `}</Text>
      </Stack>
    )}
    {ll1Balance && (
      <Stack className={classes.stackNetworkBalance}>
        <TokenDisplay token="LL1" />
        <Text className={classes.balanceAmount}>{`${ll1Balance.display}`}</Text>
      </Stack>
    )}
    {rl1Balance && (
      <Stack className={classes.stackNetworkBalance}>
        <TokenDisplay token="RL1" />
        <Text className={classes.balanceAmount}>{`${rl1Balance.display}`}</Text>
      </Stack>
    )}
    {stakeBalance && (
      <Stack className={classes.stackNetworkBalance}>
        <TokenDisplay token="STAKE" />
        <Text className={classes.balanceAmount}>{`${stakeBalance.display}`}</Text>
      </Stack>
    )}
  </Stack>
)

interface BalancesDisplayProps {
  closePopup?: () => void
}

const BalancesDisplay: React.FC<BalancesDisplayProps> = ({ closePopup }) => {
  const { t } = useTranslation()
  const {
    l1NetworkL1Balance,
    avaxNetworkAvaxBalance,
    bridge: { hasBridge, l1NetworkAvaxBalance, avaxNetworkL1Balance },
    ll1: { hasLL1, balance: totalLl1 },
    rl1: { hasGovernance, balance: totalRl1 },
    stake,
    isLoading,
    error,
  } = useBalancesContext()
  const { openModal } = useModalContext()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`)

  const [active, setActive] = useState<'l1' | 'avax'>('l1')
  // Logged In Info
  const isMetamask = localStorage.getItem('Web3Auth-cachedAdapter') === 'metamask'

  const openTokenWatchModal = useCallback(() => {
    openModal('watchTokens')
  }, [])

  return (
    <Stack style={{ gap: '30px' }}>
      <Stack style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Title order={isMobile ? 3 : 2}>{t('components.wallet.tokens', 'Tokens')}</Title>
        {isMetamask && (hasLL1 || hasGovernance || hasBridge) && (
          <RootButton
            secondary
            style1={false}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation()
              if (closePopup) closePopup()
              openTokenWatchModal()
            }}
          >
            Add To Metamask
          </RootButton>
        )}
      </Stack>
      {isLoading && <Loading />}
      {error.isError && (
        <ErrorMessage
          message={t('components.wallet.errorLoadingBalance', 'RPC Error loading {{ call }}', {
            call: error.call,
          })}
          details={error.msg}
        />
      )}
      {!isLoading && !error.isError && (
        <Stack style={{ gap: '30px' }}>
          <Stack style={{ flexDirection: 'row', gap: 30 }}>
            <PageSelector
              text={t('components.wallet.selector.l1', 'On LAMINA1')}
              active={active === 'l1'}
              onClick={() => setActive('l1')}
            />
            {hasBridge && (
              <PageSelector
                text={t('components.wallet.selector.avax', 'On Avalanche')}
                active={active === 'avax'}
                onClick={() => setActive('avax')}
              />
            )}
          </Stack>

          {active === 'l1' ? (
            <NetworkBalances
              network={t('components.wallet.networkL1', 'Lamina1 Network')}
              nativeBalance={l1NetworkL1Balance || '0'}
              erc20Balance={hasBridge ? l1NetworkAvaxBalance : undefined}
              ll1Balance={hasLL1 ? totalLl1 : undefined}
              rl1Balance={hasGovernance ? totalRl1 : undefined}
              stakeBalance={hasGovernance ? stake : undefined}
            />
          ) : (
            <NetworkBalances
              network={t('components.wallet.networkAVAX', 'Avalanche Network')}
              nativeBalance={avaxNetworkAvaxBalance || '0'}
              erc20Balance={avaxNetworkL1Balance}
            />
          )}
        </Stack>
      )}
    </Stack>
  )
}
export default BalancesDisplay

import { useCallback, useState } from 'react'

/**
 * A custom hook for handling errors in asynchronous operations within React functional components.
 * `useThrowAsync` allows components to programmatically throw errors during the state update phase,
 * which can then be caught by an external error boundary. It is designed to handle any type of input
 * and throws it as an error. This provides flexibility in handling various error scenarios,
 * especially in asynchronous contexts.
 *
 * Note:
 * - This hook is designed to be used in conjunction with error boundaries, as the errors thrown
 *   by the `throwAsync` callback will need to be caught and handled by an external boundary.
 * - The value passed to `throwAsync` can be of any value. If the error value is:
 *   - an Error -> thrown as is;
 *   - a Promise -> ignored (because React Suspense uses the convention of throwing Promises);
 *   - any other value -> throws an Error with the message "`${err}`".
 *
 * @returns {Function} A callback function named `throwAsync` that can be used to throw an error.
 *                     The error will be thrown during the state update phase, allowing it to be caught
 *                     by an error boundary. The callback accepts any input, throwing it as an error
 *                     unless it's a Promise.
 *
 * @example
 * const MyComponent = () => {
 *   const throwAsync = useThrowAsync();
 *
 *   useEffect(() => {
 *     asyncFunction().catch(throwAsync);
 *   }, []);
 *
 *   // Component logic...
 * };
 *
 * // Wrap the component with an error boundary to catch the thrown errors
 * <ErrorBoundary>
 *   <MyComponent />
 * </ErrorBoundary>
 */
const useThrowAsync = () => {
  const [, throwAsync] = useState()
  return useCallback((e: any) => {
    // Don't throw promises, as that's a React Suspense mechanism
    if (e && !(e instanceof Promise)) {
      throwAsync(() => {
        throw e instanceof Error ? e : new Error(`${e}`)
      })
    }
  }, [])
}

export default useThrowAsync

import { ActionIcon, Stack, Tooltip, useMantineTheme } from '@mantine/core'
import { IconEye, IconEyeOff } from '@tabler/icons-react'
import React, { useMemo } from 'react'

import useL1nsName from '@/hooks/useL1nsName'
import useProfilePath from '@/hooks/useProfilePath'

import CopyButton from '../components/Buttons/CopyButton'
import { NATIVE_CHAIN_EXPLORER_URL } from '../constants/blockchain'
import { StyledLink } from '../styled/components'
import classes from './table.module.css'
import { shortenHash, truncateIdentity } from './utils'

export const baseTxUrl = `${NATIVE_CHAIN_EXPLORER_URL}/tx/`
export const baseAddressUrl = `${NATIVE_CHAIN_EXPLORER_URL}/address/`
const MAX_CHARS = 20

export const SimpleAddressDisplay: React.FC<{
  address: string
}> = ({ address }) => {
  const theme = useMantineTheme()
  const {
    l1nsName: { data },
  } = useL1nsName(address)
  const [overwriteShowIdentity, setOverwriteShowIdentity] = React.useState(true)
  const profilePath = useProfilePath(address as `0x${string}`)

  const identity = useMemo(() => {
    setOverwriteShowIdentity(!!data)
    return data || 'no identity'
  }, [data])

  return (
    <Tooltip
      withArrow
      position="bottom"
      label={identity}
      style={{ display: identity.length > 13 ? 'inline' : 'none' }}
    >
      <StyledLink
        to={profilePath}
        style={{
          color: theme.colors.l1Primary[0],
          display: 'flex',
          alignItems: 'center',
          fontFamily: overwriteShowIdentity ? 'monument-grotesk-bold' : 'monospace',
          fontSize: '14px',
          fontWeight: 700,
          width: 'auto',
          whiteSpace: 'nowrap',
        }}
      >
        {overwriteShowIdentity ? `@${truncateIdentity(identity)}` : shortenHash(address)}
      </StyledLink>
    </Tooltip>
  )
}

interface TransactionAddressLinkProps {
  address: string
  noIcons?: boolean
}

export const TransactionAddressLink: React.FC<TransactionAddressLinkProps> = ({
  address,
  noIcons = false,
  ...props
}) => {
  const {
    l1nsName: { data },
  } = useL1nsName(address)
  const [overwriteShowIdentity, setOverwriteShowIdentity] = React.useState(true)
  const profilePath = useProfilePath(address as `0x${string}`)

  const identity = useMemo(() => {
    setOverwriteShowIdentity(!!data)
    return data || 'no identity'
  }, [data])

  return (
    <Stack style={{ flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
      <Tooltip
        withArrow
        position="bottom"
        label={identity}
        style={{ display: identity.length > MAX_CHARS ? 'inline' : 'none' }}
      >
        <div {...props}>
          <StyledLink
            to={profilePath}
            style={{
              fontFamily: overwriteShowIdentity ? 'monument-grotesk-bold' : 'monospace',
            }}
            className={classes.address}
          >
            {overwriteShowIdentity
              ? `@${truncateIdentity(identity, MAX_CHARS)}`
              : shortenHash(address)}
          </StyledLink>
        </div>
      </Tooltip>
      {!noIcons && (
        <Stack style={{ flexDirection: 'row', marginLeft: '8px', gap: '2px' }} {...props}>
          <CopyButton value={overwriteShowIdentity ? identity : address} transparent />
          <ActionIcon onClick={() => setOverwriteShowIdentity((previous: boolean) => !previous)}>
            {overwriteShowIdentity ? <IconEye size={16} /> : <IconEyeOff size={16} />}
          </ActionIcon>
        </Stack>
      )}
    </Stack>
  )
}

export const transactionHashLink = (hash: string) => (
  <StyledLink to={`${baseTxUrl}${hash}`} target="_blank" style={{ fontFamily: 'monospace' }}>
    {shortenHash(hash)}
  </StyledLink>
)

export const transactionAddressLink = (address: string) => (
  <Stack style={{ flexDirection: 'row', gap: '4px' }}>
    <StyledLink
      to={`${baseAddressUrl}${address}`}
      target="_blank"
      style={{ fontFamily: 'monospace', display: 'flex', alignItems: 'center' }}
    >
      {shortenHash(address)}
    </StyledLink>
    <CopyButton value={address} />
  </Stack>
)

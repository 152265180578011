/**
 * Creates a tuple from the given arguments.
 *
 * Example:
 * ```ts
 * const t1 = tuple('foo', 1, true); // [string, number, boolean]
 * ```
 *
 * To create a writeable tuple with narrowed types and no runtime overhead,
 * do this instead:
 * ```ts
 * const _t2 = ['foo', 1, true] as const; // readonly tuple literal: `const _bar: readonly ["foo", 1, true]`
 * const t2 = _bar as DeepMutable<typeof _bar>;  // writeable tuple: `const bar: ["foo", 1, true]`
 * ```
 *
 * However cumbersome, this is the only way to create a writeable tuple
 * with narrowed types and no runtime overhead in the current version of
 * TypeScript (5.1.6).
 */
export function tuple<T extends any[]>(...args: T): T {
  return args
}

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

export function lazySingleton<T>(singletonBuilder: () => T): () => T {
  let valueBox: { value: T }
  return () => {
    if (!valueBox) {
      valueBox = {
        value: singletonBuilder(),
      }
    }
    return valueBox.value
  }
}

export function isPrimitive(
  value: any
): value is string | number | boolean | null | undefined | symbol | bigint | Function {
  return (
    value === null ||
    ['string', 'number', 'boolean', 'undefined', 'symbol', 'bigint', 'function'].includes(
      typeof value
    )
  )
}

function deepFreezeRec<T>(obj: T, refs?: Set<any>): T {
  if (isPrimitive(obj) || refs?.has(obj)) return obj
  refs = refs ?? new Set()
  refs.add(obj)
  return deepFreezeRec(Object.freeze(obj), refs)
}

export function deepFreeze<T>(obj: T) {
  return deepFreezeRec(obj, new Set()) as DeepReadonly<T>
}

export function noop(): void {}

const $timedOutSymbol = Symbol('timedOut')
function isTimedOut(err: unknown): err is Error {
  return !!err && typeof err === 'object' && (err as any)[$timedOutSymbol] === true
}

interface WithTimeout {
  <P extends Promise<any>>(p: P, timeout: number): P
  isTimedOut: typeof isTimedOut
}
const withTimeout: WithTimeout = (<P extends Promise<any>>(p: P, timeout: number): P => {
  let timeoutId: ReturnType<typeof setTimeout>
  const timeoutPromise = new Promise<never>((_, reject) => {
    timeoutId = setTimeout(() => {
      const err = new Error('Promise timeout') as any
      Object.defineProperty(err, $timedOutSymbol, {
        value: true,
        configurable: false,
        enumerable: false,
        writable: false,
      })
      reject(err)
    }, timeout)
  })

  return Promise.race([p, timeoutPromise]).finally(() => {
    clearTimeout(timeoutId)
    timeoutId = undefined as any
  }) as P
}) as WithTimeout & Function
withTimeout.isTimedOut = isTimedOut

export { withTimeout }
// const obj = { a: { b: { c: { d: '' } } } }
/**
 * For use with `JSON.stringify` as the `replacer` argument to limit the depth of the output.
 * @param maxDepth
 * @example
 * ```ts
 * const obj = { a: { b: { c: { d: '' } } } }
 * const str = JSON.stringify(obj, createDepthLimiterReplacer(2))
 * console.log(str) // {"a":{"b": {} }}
 * ```
 */
export function createDepthLimiterReplacer(maxDepth: number) {
  return (() => {
    const weakMap = new WeakMap<any, any>()

    return function depthLimiterReplacer(this: any, _: keyof any, value: any): any {
      if (typeof value === 'object' && value !== null) {
        if (weakMap.has(value)) {
          // Reference to a circular or repeated object, return undefined to avoid errors
          return undefined
        }
        weakMap.set(value, true)

        let depth = 0
        let temp = this
        while (temp !== undefined) {
          temp = weakMap.get(temp)
          if (temp === true) {
            depth++
          }
          if (depth > maxDepth) {
            return undefined // Omitting the value for depths greater than maxDepth
          }
        }
      }
      return value
    }
  })()
}

export type CustomError = {
  call: string | undefined
  msg: string | undefined
  isError: boolean
}

export function parseError(errors: CustomError[]) {
  const error = errors.find(e => e.isError)
  return error || { call: '', msg: undefined, isError: false }
}

import { useMemo } from 'react'
import { zeroAddress } from 'viem'
import { namehash, normalize } from 'viem/ens'
import { useReadContract } from 'wagmi'

import { BLANK_ADDRESS, CONTRACT_RESOLVER_ADDRESS } from '@/constants/blockchain'
import L1Resolver from '@/contracts/PublicResolver'
import { l1IdSubnetWagmiConfig } from '@/plugins/auth/config'

const useL1nsName = (account: string = zeroAddress) => {
  // Stateful function
  const node = useMemo(() => {
    const name = normalize(
      `${account.startsWith('0x') ? account.substring(2) : account}.addr.reverse`
    )
    return namehash(name)
  }, [account])

  const { data, isError, isLoading, isSuccess } = useReadContract({
    chainId: l1IdSubnetWagmiConfig.id,
    address: CONTRACT_RESOLVER_ADDRESS as `0x${string}`,
    abi: L1Resolver,
    functionName: 'name',
    args: [node],
  })

  return {
    l1nsName: {
      data:
        isSuccess && !!data && data !== BLANK_ADDRESS ? (data as string).split('.')[0] : undefined,
      isError,
      isLoading,
      isSuccess,
    },
  }
}

export default useL1nsName

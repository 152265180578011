import type { TFunction } from 'i18next'

import { itemNameWithId } from '@/utils/utils'

const getSocialMessages = (
  t: TFunction,
  shareableLink: string,
  username?: string,
  nftMetadata?: any,
  nativeId?: string
) => {
  // Items Page
  const twitterMessage = `${t(
    'game.spaceLasers.nftUnlocked',
    'Check this out on LAMINA1: {{nftName}} by {{username}}.',
    {
      nftName: itemNameWithId(nftMetadata?.name, nativeId),
      username,
    }
  )}%0D%0A%0D%0A`
  const discordMessage = `${t(
    'game.spaceLasers.nftUnlocked',
    'Check this out on LAMINA1: {{nftName}} by {{username}}.',
    {
      nftName: itemNameWithId(nftMetadata?.name, nativeId),
      username,
    }
  )}

  ${shareableLink}
  `

  // Profile Page
  const twitterProfileMessage = `${t(
    'components.profile.twitterMessage',
    'Check out this Profile on LAMINA1: {{username}}',
    { username: username?.replace('@', '') }
  )}%0D%0A%0D%0A`
  const discordProfileMessage = `${t(
    'components.profile.discordMessage',
    'Check out this Profile on LAMINA1: {{username}}',
    { username: username?.replace('@', '') }
  )}
  ${shareableLink}
  `

  // General sharing message
  const twitterAccountTag = 'Lamina1official'

  return {
    // Escape # for Twitter
    twitterMessage: twitterMessage.replace('#', '%23'),
    discordMessage,
    twitterProfileMessage,
    discordProfileMessage,
    twitterAccountTag,
  }
}

export default getSocialMessages

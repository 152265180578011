import { ActionIcon, Stack, Title, Tooltip } from '@mantine/core'
import { IconHistory, IconSignature } from '@tabler/icons-react'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useModalContext } from '@/contexts/ModalContext'
import { useChainValues } from '@/hooks/useChain'
import { useAuth } from '@/plugins/auth'
import { l1NativeConfig } from '@/plugins/auth/config'
import { saveLocation } from '@/plugins/auth/RouteRedirectors/utils'

import RootButton from '../Buttons/RootButton'
import DisplayUsername from '../DisplayUsername'
import TokenDisplay from '../Tokens/TokensDisplay'
import classes from './WalletControl.module.css'

interface WalletControlProps {
  closePopup?: () => void
}

const WalletControl: React.FC<WalletControlProps> = ({ closePopup }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { address } = useAuth()
  const { balance } = useChainValues(l1NativeConfig.chainId)

  // use modals provider to get send and receive modals
  const { openModal } = useModalContext()

  const openSendModal = useCallback(() => {
    openModal('send')
  }, [openModal])

  const openReceiveModal = useCallback(() => {
    openModal('receive')
  }, [openModal])

  const openSignatureModal = useCallback(() => {
    openModal('signature')
  }, [openModal])

  return (
    <Stack style={{ gap: '30px' }}>
      <Stack style={{ gap: '10px' }}>
        <Stack style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
            <TokenDisplay token="L1" onlyIcon size={34} />
            <Title order={2}>{balance.display}</Title>
          </Stack>
          <Stack style={{ flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
            {closePopup && (
              <Tooltip label={t('components.wallet.history', 'Transaction History')}>
                <ActionIcon
                  className={classes.actionIcon}
                  onClick={() => {
                    saveLocation('/activity')
                    navigate('/activity')
                    closePopup()
                  }}
                >
                  <IconHistory />
                </ActionIcon>
              </Tooltip>
            )}
            {closePopup && (
              <Tooltip label={t('components.wallet.sign', 'Sign Message')}>
                <ActionIcon
                  className={classes.actionIcon}
                  onClick={() => {
                    if (closePopup) closePopup()
                    openSignatureModal()
                  }}
                >
                  <IconSignature />
                </ActionIcon>
              </Tooltip>
            )}
          </Stack>
        </Stack>
        {address && <DisplayUsername address={address} colorless />}
      </Stack>
      <Stack style={{ flexDirection: 'row', width: 320 }}>
        <RootButton
          secondary
          expand
          onClick={() => {
            if (closePopup) closePopup()
            openReceiveModal()
          }}
        >
          {t('buttons.receive', 'Receive')}
        </RootButton>
        <RootButton
          secondary
          expand
          onClick={() => {
            if (closePopup) closePopup()
            openSendModal()
          }}
        >
          {t('buttons.send', 'Send')}
        </RootButton>
      </Stack>
    </Stack>
  )
}

export default WalletControl

import styled from '@emotion/styled'
import {
  Button,
  ButtonProps,
  createPolymorphicComponent,
  Stack,
  StackProps,
  Text,
  TextInput,
  TextInputProps,
  TextProps,
} from '@mantine/core'
import { Link, LinkProps } from 'react-router-dom'

import { darkTheme, lightTheme } from '@/components/Theme/themes'

const styledStack = styled(Stack)`
  border: 0px inset white;
  border-radius: 0px;
`
export const StyledStack = createPolymorphicComponent<'div', StackProps>(styledStack)

const bodyStack = styled(Stack)`
  margin: 0 calc((100vw - 1340px) / 2);
  margin-top: 100px;
  padding: 0 25px;
  min-height: 100vh;
  @media (max-width: 1340px) {
    margin: 100px 25px;
    padding: 0;
  }
`
export const BodyStack = createPolymorphicComponent<'div', StackProps>(bodyStack)

const styledLink = styled(Link)`
  text-decoration: none;
`
export const StyledLink = createPolymorphicComponent<'a', LinkProps>(styledLink)

const styledLinkAsButton = styled(Link)`
  border: 'none';
  background: 'none';
  cursor: 'pointer';
`
export const StyledLinkAsButton = createPolymorphicComponent<'a', TextProps>(styledLinkAsButton)

const styledRoundedButton = styled(Button)`
  border-radius: 50% !important;
  border: 2px solid #888 !important;
  width: 5em !important;
  height: 5em !important;
  align-items: center !important;
`
export const StyledRoundedButton = createPolymorphicComponent<'button', ButtonProps>(
  styledRoundedButton
)

const styledTextInput = styled(TextInput)`
  border-radius: 12px;
  border: 2px solid #888;
`
export const StyledTextInput = createPolymorphicComponent<'text', TextInputProps>(styledTextInput)

// Function to get theme from local storage
const getTheme = () => {
  const themeMode = localStorage.getItem('theme') || 'dark'
  return themeMode === 'dark' ? darkTheme : lightTheme
}

// New styled component for an Action Text element (like inserting a button on documentation)
const styledActionText = styled(Text)`
  margin: 2px;
  align-content: flex-start;
  display: inline-block;
  color: ${getTheme().title};
  background: ${getTheme().l1PrimaryOpacity};
  padding: 4px 6px 2px;
  border-radius: 6px;
  text-transform: uppercase;
  font-size: 12px;
  font-family: monument-grotesk-bold;
  white-space: pre-wrap;
`
export const StyledActionText = createPolymorphicComponent<'span', TextProps>(styledActionText)

// New styled component for an inline code element
const styledInlineCode = styled.code`
  margin: 2px;
  align-content: flex-start;
  display: inline-block;
  color: ${getTheme().title};
  background: ${getTheme().l1PrimaryOpacity};
  padding: 4px 6px 2px;
  border-radius: 6px;
  font-size: 10px;
  font-family: monospace;
  white-space: pre-wrap;
`

export const StyledInlineCode = createPolymorphicComponent<'code', TextProps>(styledInlineCode)

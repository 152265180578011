import { IconCaretDownFilled } from '@tabler/icons-react'
import React from 'react'

import classes from './OpenMenuArrow.module.css'

interface OpenMenuArrowProps {
  menuOpened: boolean
}

const OpenMenuArrow: React.FC<OpenMenuArrowProps> = ({ menuOpened }) => (
  <IconCaretDownFilled
    size={14}
    stroke={5}
    className={menuOpened ? classes.arrowRotated : classes.arrowNotRotated}
  />
)

export default OpenMenuArrow

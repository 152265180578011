import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { zeroAddress } from 'viem'

import { useErrorMessages } from '@/constants/content'
import { avalancheWagmiConfig, l1NativeWagmiConfig } from '@/plugins/auth/config'
import { ErrorMessageDisplay, NetworkOptions, networkOptions } from '@/utils/utils'

import { ContractParams, useChainFunctions, useChainValues } from './useChain'

const useEstimatedFee = (
  networkOrigin?: NetworkOptions,
  contractCallConfig?: ContractParams,
  crossChain: boolean = false
) => {
  const { t } = useTranslation()
  const errorMessages = useErrorMessages()
  const { gasPrice: gasPriceL1 } = useChainValues(l1NativeWagmiConfig.id)
  const { estimateGas, estimateContractGas } = useChainFunctions(l1NativeWagmiConfig)
  const { gasPrice: gasPriceAvax } = useChainValues(avalancheWagmiConfig.id)
  const { estimateGas: estimateGasAvax, estimateContractGas: estimateContractGasAvax } =
    useChainFunctions(avalancheWagmiConfig)

  const [estimatedFee, setEstimatedFee] = useState<number>(0)
  const [gasPrice, setGasPrice] = useState<number>(0)
  const [gas, setGas] = useState<string>('0')
  const [loadingFees, setLoadingFees] = useState<boolean>(false)
  const [errorFees, setErrorFees] = useState<ErrorMessageDisplay>()
  const web3authGasEstimate = useCallback(async () => {
    try {
      const getEstimatedGas = async () => {
        setLoadingFees(true)
        if (networkOrigin !== networkOptions.avax) {
          setGasPrice(gasPriceL1)
          if (contractCallConfig) {
            const gasAmount = await estimateContractGas(contractCallConfig)
            if (gasAmount && gasPriceL1) {
              setEstimatedFee(gasAmount * (gasPriceL1 * 1e-18 * 1.1) || 0.0005775)
              setGas((gasAmount * 1.1).toFixed(0))
            }
          } else {
            const gasAmount = await estimateGas(zeroAddress, '1')
            if (gasAmount && gasPriceL1) {
              setEstimatedFee(gasAmount * (gasPriceL1 * 1e-18 * 1.1) || 0.0005775)
              setGas((gasAmount * 1.1).toFixed(0))
            }
          }
        } else {
          setGasPrice(gasPriceAvax)
          if (contractCallConfig) {
            const gasAmount = await estimateContractGasAvax(contractCallConfig)
            if (gasAmount && gasPriceAvax) {
              setEstimatedFee(gasAmount * (gasPriceAvax * 1e-18 * 1.1) || 0.0005775)
              setGas((gasAmount * 1.1).toFixed(0))
            }
          } else {
            const gasAmount = await estimateGas(zeroAddress, '1')
            if (gasAmount && gasPriceAvax) {
              setEstimatedFee(gasAmount * (gasPriceAvax * 1e-18 * 1.1) || 0.0005775)
              setGas((gasAmount * 1.1).toFixed(0))
            }
          }
        }
      }
      await getEstimatedGas()
      setLoadingFees(false)
    } catch (err: any) {
      // Set default fee if error occurs to avoid lopping
      setEstimatedFee(0.0005775)
      // Set default gas limit if error occurs
      setGas('250000')
      setLoadingFees(false)

      if (err.message.includes('Failed to fetch')) {
        setErrorFees({ message: errorMessages.rpcConnection, details: err.message })
      } else if (err.message.includes('exceeds the balance of the account')) {
        // noop
      } else {
        setErrorFees({
          message: t('components.send.errorFees', 'Error getting fees'),
          details: err.message,
        })
      }
    }
  }, [
    crossChain,
    networkOrigin,
    contractCallConfig,
    estimateGas,
    estimateContractGas,
    gasPriceL1,
    estimateGasAvax,
    estimateContractGasAvax,
    gasPriceAvax,
  ])

  // Get gas estimate on load or when contract call args change
  useEffect(() => {
    web3authGasEstimate()
  }, [web3authGasEstimate, contractCallConfig])

  const bridgeFees = useMemo(() => (crossChain ? 0.001 : 0), [])

  return { estimatedFee, loadingFees, errorFees, bridgeFees, gas, gasPrice }
}
export default useEstimatedFee

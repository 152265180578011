import { type Dispatch, useMemo } from 'react'

import _useConnectDisconnect from '@/plugins/auth/AuthProvider/effects/_useConnectDisconnect'
import _useOnModalClose from '@/plugins/auth/AuthProvider/effects/_useOnModalClose'
import _useStateTransitions from '@/plugins/auth/AuthProvider/effects/_useStateTransitions'
import _useSyncWithWagmi from '@/plugins/auth/AuthProvider/effects/_useSyncWithWagmi'
import type { AuthAction } from '@/plugins/auth/AuthProvider/types/AuthActions'
import type { InternalAuthState } from '@/plugins/auth/AuthProvider/types/AuthInternalState'
import { ConnectorLoadingInfo } from '@/plugins/auth/AuthProvider/types/context'

type UseAuthStateTransitionsEffect = (
  internalState: InternalAuthState,
  dispatch: Dispatch<AuthAction>
) => {
  connect: () => void
  disconnect: () => void
  connectorType?: ConnectorLoadingInfo['connectorType']
}

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
const _useInternalAuth: UseAuthStateTransitionsEffect = (internalState, dispatch) => {
  _useStateTransitions(internalState, dispatch)

  const connectorType = _useSyncWithWagmi(internalState, dispatch)

  _useOnModalClose(dispatch)

  const { connect, disconnect } = _useConnectDisconnect(internalState, dispatch)

  return useMemo(
    () => ({ connect, disconnect, connectorType }),
    [connect, disconnect, connectorType]
  )
}

export default _useInternalAuth

import { Grid, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { useTranslation } from 'react-i18next'

import { useBalancesContext } from '@/contexts/BalancesContext'

import RootButton from '../Buttons/RootButton'
import ModalSkeleton from '../Modals/ModalSkeleton'
import UnwrapModal from '../Modals/Wallet/UnwrapModal'
import TooltipTitle from '../TooltipTitle'
import classes from './RewardsDisplay.module.css'

const RewardsDisplay: React.FC = () => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`)

  const [openedR1, { open: openR1, close: closeR1 }] = useDisclosure(false)
  const [openedL1, { open: openL1, close: closeL1 }] = useDisclosure(false)

  const {
    ll1: { withdrawable: ll1Withdrawable, locked: ll1Locked },
    rl1: { hasGovernance, withdrawable: rl1Withdrawable, locked: rl1Locked },
  } = useBalancesContext()

  return (
    <Stack style={{ gap: '30px' }}>
      <Title order={isMobile ? 3 : 2}>{t('components.wallet.rewards', 'Rewards')}</Title>
      <Stack style={{ gap: '60px', flexDirection: isMobile ? 'column' : 'row' }}>
        {hasGovernance && (
          <Stack
            style={{
              gap: '20px',
              width: '330px',
            }}
          >
            <Stack style={{ gap: '5px' }}>
              <Grid justify="flex-start" style={{ gap: '0 10px', justifyContent: 'space-between' }}>
                <Grid.Col span="content">
                  <TooltipTitle
                    title={t('components.wallet.unlockReward', 'Available Rewards L1')}
                    tooltip={t(
                      'components.wallet.unlockRewardTooltip',
                      `The number of Rewards L1 (RL1) tokens you have earned by staking, validating, or supporting the network, are currently unlocked, and can be unwrapped into usable L1 tokens on the LAMINA1 Network.

                Note: The current unlock schedule for RL1 tokens is TBD. Check back here regularly to unwrap more RL1 over time.`
                    )}
                  />
                </Grid.Col>
                <Grid.Col span="auto">
                  <Stack style={{ gap: '0px', alignItems: 'end' }}>
                    <Text className={classes.rewardsAmount}>{rl1Withdrawable?.display}</Text>
                  </Stack>
                </Grid.Col>
              </Grid>
              <Text className={classes.rewardsDiscription}>{rl1Locked.display} RL1 are locked</Text>
            </Stack>
            <RootButton
              secondary
              disabled={!rl1Withdrawable?.value}
              onClick={openR1}
              style={{
                alignSelf: 'flex-end',
              }}
            >
              {t('buttons.unwrap', 'Unwrap')}
            </RootButton>
          </Stack>
        )}
        <Stack style={{ width: '330px', gap: '20px' }}>
          <Stack style={{ gap: '5px' }}>
            <Grid justify="flex-start" style={{ gap: '0 10px', justifyContent: 'space-between' }}>
              <Grid.Col span="content">
                <TooltipTitle
                  title={t('components.wallet.unlockLaunch', 'Available Launch L1')}
                  tooltip={t(
                    'components.wallet.unlockLaunchTooltip',
                    ` The number of Launch L1 (LL1) tokens you have earned by participating in early projects, quests, or partnerships, are currently unlocked, and can be unwrapped into usable L1 tokens on the LAMINA1 network.

                Note: The current unlock schedule for LL1 tokens is TBD. Check back here regularly to unwrap more LL1 over time.`
                  )}
                />
              </Grid.Col>
              <Grid.Col span="auto">
                <Stack style={{ gap: '0px', alignItems: 'end' }}>
                  <Text className={classes.rewardsAmount}>{ll1Withdrawable?.display}</Text>
                </Stack>
              </Grid.Col>
            </Grid>
            <Text className={classes.rewardsDiscription}>{ll1Locked.display} LL1 are locked</Text>
          </Stack>
          <RootButton
            secondary
            disabled={!ll1Withdrawable?.value}
            onClick={openL1}
            style={{
              alignSelf: 'flex-end',
            }}
          >
            {t('buttons.unwrap', 'Unwrap')}
          </RootButton>
        </Stack>
      </Stack>

      {hasGovernance && (
        <ModalSkeleton
          opened={openedR1}
          onClose={closeR1}
          title={t('components.wallet.unwrapRl1', 'Unwrap Rewards L1')}
        >
          <UnwrapModal unwrap="RL1" amount={rl1Withdrawable} close={closeR1} />
        </ModalSkeleton>
      )}
      <ModalSkeleton
        opened={openedL1}
        onClose={closeL1}
        title={t('components.wallet.unwrapLl1', 'Unwrap Launch L1')}
      >
        <UnwrapModal unwrap="LL1" amount={ll1Withdrawable} close={closeL1} />
      </ModalSkeleton>
    </Stack>
  )
}
export default RewardsDisplay

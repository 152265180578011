import { Divider, Stack, Text, Title, Tooltip, useMantineTheme } from '@mantine/core'
import { IconHelpCircleFilled } from '@tabler/icons-react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AVAX_CHAIN_TICKER, NATIVE_CHAIN_TICKER } from '@/constants/blockchain'
import { ContractParams } from '@/hooks/useChain'
import useEstimatedFee from '@/hooks/useEstimatedFee'
import { NetworkOptions, networkOptions } from '@/utils/utils'

import ErrorMessage from '../ErrorMessage'
import Loading from '../Loading'

export interface CrossChainTransaction {
  token: string
  networkOrigin: NetworkOptions
  networkDestination: NetworkOptions
}

const FeesTooltip: React.FC<{
  isMetamask: boolean
  loadingFees: boolean
  estimatedFee: number
  networkOriginTicker: string
  isTotal?: boolean
}> = ({ isMetamask, loadingFees, estimatedFee, networkOriginTicker, isTotal = false }) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  return (
    <Stack style={{ flexDirection: 'row', gap: '5px' }}>
      {isMetamask ? (
        <Tooltip
          withArrow
          position="left"
          label={t(
            'components.identity.confirmModal.feeDisclaimer',
            'Fees are estimated by Metamask and may vary.'
          )}
        >
          <Text>
            <IconHelpCircleFilled size="0.9rem" />
          </Text>
        </Tooltip>
      ) : loadingFees ? (
        <Loading size="20px" />
      ) : (
        <></>
      )}
      <Text
        size="sm"
        style={{
          fontFamily: isTotal ? 'monument-grotesk-heavy, sans-serif' : '',
          color: isTotal ? theme.colors.title[0] : '',
        }}
      >
        {estimatedFee.toFixed(5)} {networkOriginTicker}
      </Text>
    </Stack>
  )
}

interface Props {
  amount?: string
  crossChain?: CrossChainTransaction
  callConfig?: ContractParams
  isOrder?: boolean
  isStaking?: boolean
}

const TransactionSummary: React.FC<Props> = ({
  amount,
  crossChain = {
    token: 'L1',
    networkOrigin: networkOptions.l1,
    networkDestination: networkOptions.l1,
  },
  callConfig,
  isOrder = false,
  isStaking = false,
}) => {
  const { t } = useTranslation()
  const { estimatedFee, loadingFees, errorFees, bridgeFees } = useEstimatedFee(
    crossChain.networkOrigin,
    callConfig,
    crossChain.networkOrigin !== crossChain.networkDestination
  )

  // Transaction Summary Variables
  const networkOriginTicker = useMemo(
    () =>
      crossChain.networkOrigin === networkOptions.l1 ? NATIVE_CHAIN_TICKER : AVAX_CHAIN_TICKER,
    [crossChain]
  )
  const networkDestinationTicker = useMemo(
    () =>
      crossChain.networkDestination === networkOptions.l1 ? NATIVE_CHAIN_TICKER : AVAX_CHAIN_TICKER,
    [crossChain]
  )

  // Logged In Info
  const isMetamask = localStorage.getItem('Web3Auth-cachedAdapter') === 'metamask'

  /* -------------------------------------------------------------------------- */
  /*                             Transaction Summary                            */
  /* -------------------------------------------------------------------------- */
  // Total Fees
  const totalFees = useMemo(() => {
    if (!crossChain) return []
    // token = network origin = network destination
    // Example: L1 (L1) -> L1 (L1)
    if (
      networkOriginTicker === crossChain.token &&
      crossChain.networkOrigin === crossChain.networkDestination
    ) {
      return [
        {
          token: crossChain.token,
          amount: parseFloat(amount || '0') + estimatedFee,
        },
      ]
    }

    // token = network origin != network destination
    // Example: L1 (L1) -> AVAX (L1)
    if (networkOriginTicker === crossChain.token) {
      return [
        {
          token: crossChain.token,
          amount: parseFloat(amount || '0') + estimatedFee,
        },
        {
          token: networkDestinationTicker,
          amount: bridgeFees,
        },
      ]
    }

    // token != network origin = network destination
    // Example: L1 (AVAX) -> L1 (AVAX)
    if (crossChain.networkOrigin === crossChain.networkDestination) {
      if (networkOriginTicker === 'L1T') {
        return [
          {
            token: networkOriginTicker,
            amount: parseFloat(amount || '0') + estimatedFee + bridgeFees,
          },
        ]
      }
      return [
        {
          token: crossChain.token,
          amount: parseFloat(amount || '0'),
        },
        {
          token: networkOriginTicker,
          amount: bridgeFees + estimatedFee,
        },
      ]
    }

    // token != network origin != network destination
    // Example: L1 (AVAX) -> AVAX (AVAX)
    return [
      {
        token: crossChain.token,
        amount: parseFloat(amount || '0') + estimatedFee + bridgeFees,
      },
      {
        token: networkOriginTicker,
        amount:
          crossChain.networkOrigin !== crossChain.networkDestination
            ? estimatedFee + bridgeFees
            : bridgeFees,
      },
    ]
  }, [crossChain, networkOriginTicker, networkDestinationTicker, amount, estimatedFee, bridgeFees])

  return (
    <Stack style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
      <Title order={4}>
        {t('components.send.transactionSummary', '{{summaryType}} Summary', {
          summaryType: isOrder ? 'Order' : 'Transaction',
        })}
      </Title>
      {errorFees && <ErrorMessage message={errorFees.message} details={errorFees.details} />}
      {!errorFees && (
        <Stack style={{ flexDirection: 'column', gap: '0' }}>
          {amount !== undefined && (
            <Stack style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Text size="sm">
                {
                  t('components.send.txSummary.sendAmount', '{{typeAmount}}', {
                    typeAmount: isOrder ? 'Subtotal' : isStaking ? 'Stake Amount' : 'Send Amount',
                  }) as string
                }
              </Text>
              <Text size="sm">
                {amount || '0'} {crossChain.token}
              </Text>
            </Stack>
          )}
          <Stack style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text size="sm">
              {
                t('components.send.txSummary.originNetworkFees', '{{origin}} Network Fees', {
                  origin:
                    crossChain.networkOrigin !== crossChain.networkDestination ? 'Origin' : '',
                }) as string
              }
            </Text>
            <FeesTooltip
              isMetamask={isMetamask}
              loadingFees={loadingFees}
              estimatedFee={estimatedFee}
              networkOriginTicker={networkOriginTicker}
            />
          </Stack>
          {crossChain.networkOrigin !== crossChain.networkDestination && (
            <Stack style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Text size="sm">
                {t('components.send.txSummary.destinationNetworkFees', 'Destination Network Fees')}
              </Text>
              <FeesTooltip
                isMetamask={isMetamask}
                loadingFees={loadingFees}
                estimatedFee={bridgeFees}
                networkOriginTicker={networkDestinationTicker}
              />
            </Stack>
          )}

          <Divider style={{ margin: '6px 0' }} />
          {/* // TODO: fuji prints L1 and L1T (remove L1 Total) */}
          {totalFees.map(elem => (
            <Stack
              key={`${elem.amount}-${elem.token}`}
              style={{ flexDirection: 'row', justifyContent: 'space-between', gap: '0' }}
            >
              <Title style={{ fontSize: '14px' }}>
                {t('components.send.txSummary.total', '{{ticker}} Total', {
                  ticker: elem.token,
                })}
              </Title>
              <FeesTooltip
                isMetamask={isMetamask}
                loadingFees={loadingFees}
                estimatedFee={elem.amount}
                networkOriginTicker={elem.token}
                isTotal
              />
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  )
}
export default TransactionSummary

import React from 'react'
import { useTranslation } from 'react-i18next'

import Receive from '@/components/Transfer/Receive'

import ModalSkeleton from '../ModalSkeleton'

interface ReceiveModalProps {
  close: () => void
}

const ReceiveModal: React.FC<ReceiveModalProps> = ({ close }) => {
  const { t } = useTranslation()

  return (
    <ModalSkeleton opened onClose={close} title={t('components.wallet.receive', 'Receive')}>
      <Receive close={close} />
    </ModalSkeleton>
  )
}

export default ReceiveModal

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createContext, FC, memo, ReactNode, useContext, useMemo, useReducer } from 'react'
import { useAccount, WagmiProvider } from 'wagmi'

// Balances (LL1, RL1, L1/AVAX native + ERC20, using feature detection)
import { BalancesProvider } from '@/contexts/BalancesContext'
// Chain Features (L1 subnet)
import { ChainFeaturesProvider } from '@/contexts/ChainFeaturesContext'
// Providers
// All Chains native balance, gasPrice + generic read / write
import _useInternalAuth from '@/plugins/auth/AuthProvider/effects'
import reducer from '@/plugins/auth/AuthProvider/reducer'
import {
  AuthSteps,
  initState,
  InternalAuthStateConnectingProvider,
  isConnectingStep,
} from '@/plugins/auth/AuthProvider/types/AuthInternalState'
import { AuthContext, ConnectorLoadingInfo } from '@/plugins/auth/AuthProvider/types/context'
import { getWagmiConfig } from '@/plugins/auth/config'

const authContext = createContext<AuthContext>(null as any)

export function useAuth() {
  const ctx = useContext<AuthContext>(authContext)
  if (!ctx) throw new Error('useAuth must be used within an AuthProvider')
  return ctx
}

const InnerAuth: FC<{ children: ReactNode }> = ({ children }) => {
  const wagmiAccount = useAccount()
  // console.log('ACCOUNT:', JSON.parse(JSON.stringify(wagmiAccount, createDepthLimiterReplacer(2))))

  // This dispatch function should never be called anywhere other than inside the reducer and in the
  // useEffect that handles the queued action.
  const [internalState, dispatch] = useReducer(reducer, initState)

  const { connect, connectorType, disconnect } = _useInternalAuth(internalState, dispatch)

  const ctxValue = useMemo<AuthContext>(() => {
    if (internalState.step === AuthSteps.Connected) {
      return {
        status: 'connected',
        address: internalState.address,
        userInfo: internalState.userInfo,
        userIdentity: internalState.userIdentity,
        provider: internalState.provider,
        disconnect,
        ...({ connectorType, connector: wagmiAccount.connector } as ConnectorLoadingInfo & {
          connectorType: string
        }),
      } satisfies AuthContext & { status: 'connected' }
    }

    if (internalState.step === AuthSteps.Init || isConnectingStep(internalState.step)) {
      return {
        status: 'connecting',
        address: (internalState as InternalAuthStateConnectingProvider).address,
        disconnect,
        userInfo: (internalState as InternalAuthStateConnectingProvider).userInfo,
        userIdentity: (internalState as InternalAuthStateConnectingProvider).userIdentity,
        ...({ connectorType, connector: wagmiAccount.connector } as ConnectorLoadingInfo),
      } satisfies AuthContext & { status: 'connecting' }
    }

    return {
      status: 'disconnected',
      connect,
    } satisfies AuthContext & { status: 'disconnected' }
  }, [connect, connectorType, disconnect, internalState, wagmiAccount.connector])

  return <authContext.Provider value={ctxValue}>{children}</authContext.Provider>
}

const queryClient = new QueryClient()

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => (
  <WagmiProvider config={getWagmiConfig()}>
    <QueryClientProvider client={queryClient}>
      <ChainFeaturesProvider>
        <InnerAuth>
          <BalancesProvider>{children}</BalancesProvider>
        </InnerAuth>
      </ChainFeaturesProvider>
    </QueryClientProvider>
  </WagmiProvider>
)

const MemoizedAuthProvider = memo(AuthProvider)
MemoizedAuthProvider.displayName = 'MemoizedAuthProvider'

export default MemoizedAuthProvider

import { Menu, Stack, Text, useMantineTheme } from '@mantine/core'
import React, { useCallback, useState } from 'react'

import OpenMenuArrow from '../Animations/OpenMenuArrow/OpenMenuArrow'
import classes from './DropdownButton.module.css'

interface DropdownButtonProps {
  selectedOption: string
  setSelectedOption: ((option: string) => void) | null
  options: string[]
  optionIcons?: JSX.Element[]
  backgroundColor?: string
  width?: string
  noBorder?: boolean
  tip?: string
  disabled?: boolean
}

const DropdownButton: React.FC<DropdownButtonProps> = ({
  selectedOption,
  setSelectedOption,
  options,
  optionIcons = [],
  backgroundColor = '',
  width = '300px',
  noBorder = false,
  tip = '',
  disabled = false,
}) => {
  const theme = useMantineTheme()
  const [menuOpened, setMenuOpened] = useState<boolean>(false)

  const handleOptionChange = useCallback(
    (option: string) => {
      if (setSelectedOption === null) return

      setSelectedOption(option)
      setMenuOpened(false)
    },
    [setSelectedOption, setMenuOpened]
  )

  return (
    <Stack>
      <Menu
        trigger="hover"
        openDelay={500}
        closeDelay={2000}
        opened={menuOpened}
        onClose={() => setMenuOpened(false)}
        closeOnClickOutside
        closeOnEscape
        position="bottom-start"
        disabled={disabled}
        offset={0}
        transitionProps={{ transition: 'scale-y', duration: 200 }}
      >
        <Menu.Target>
          <Stack
            style={{
              border: noBorder ? 'none' : `2px solid ${theme.colors.textAndIcon[0]}`,
              color: disabled ? theme.colors.textAndIcon[0] : theme.colors.paragraph[0],
              backgroundColor: backgroundColor || theme.colors.background[0],
              width,
              cursor: !disabled
                ? options.length > 1
                  ? 'pointer'
                  : 'default'
                : options.length > 1
                  ? 'not-allowed'
                  : 'default',
              paddingRight: '5px',
              borderRadius: menuOpened ? '8px 8px 0px 0px' : '8px',
            }}
            className={classes.button}
            onClick={() => (!disabled ? setMenuOpened(!menuOpened) : {})}
          >
            <Stack style={{ flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
              {!!optionIcons && optionIcons[options.indexOf(selectedOption)]}
              {selectedOption}
            </Stack>
            {!disabled && (
              <Stack style={{ position: 'absolute', right: '5px' }}>
                <OpenMenuArrow menuOpened={menuOpened} />
              </Stack>
            )}
          </Stack>
        </Menu.Target>
        <Menu.Dropdown
          style={{
            border: noBorder ? 'none' : `2px solid ${theme.colors.textAndIcon[0]}`,
            backgroundColor: backgroundColor || theme.colors.background[0],
            width,
            borderRadius: menuOpened ? '0px 0px 8px 8px' : '8px',
          }}
          className={classes.selectionBox}
        >
          {options.map(option => (
            <Stack
              key={option}
              onClick={() => handleOptionChange(option)}
              style={{ backgroundColor: backgroundColor || theme.colors.background[0] }}
              className={classes.selectionButton}
            >
              <Stack className={classes.selectionButtonContent}>
                {!!optionIcons && optionIcons[options.indexOf(option)]}
                {option}
              </Stack>
            </Stack>
          ))}
        </Menu.Dropdown>
      </Menu>
      {tip && (
        <Text size="sm" style={{ color: theme.colors.paragraph[0], textAlign: 'left' }}>
          {tip}
        </Text>
      )}
    </Stack>
  )
}

export default DropdownButton

import 'cropperjs/dist/cropper.css'

import { Box, Slider, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconCloudUpload, IconZoomInFilled, IconZoomOutFilled } from '@tabler/icons-react'
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'
import Cropper, { ReactCropperElement } from 'react-cropper'
import { ErrorCode, useDropzone } from 'react-dropzone'
import { Trans, useTranslation } from 'react-i18next'

import { STUDIO_BOT } from '@/constants/blockchain'
import { useErrorMessages } from '@/constants/content'
import { convertDictToText, convertImageToBytes, convertUnitToBytes } from '@/utils/utils'

import RootButton from './Buttons/RootButton'
import ErrorMessage from './ErrorMessage'
import Loading from './Loading'
import classes from './UploadImage.module.css'

const MAX_FILE_SIZE = 10
const MAX_FILE_UNIT = 'MB'
const AvatarErrorMessage = `Error. Could not upload image to IPFS. Please try again.`
const JPG = 'image/jpeg'
const PNG = 'image/png'
const WEBP = 'image/webp'
const GIF = 'image/gif'

const ACCEPTED_FILE_TYPES = {
  [JPG]: [],
  [PNG]: [],
  [WEBP]: [],
  [GIF]: [],
}

interface UploadImageProps {
  uploadClose: () => void
  title: string
  subtitle: string
  width?: number
  height?: number
  isProfileImage?: boolean
  setImage: (image: string) => void
}

const UploadImage: React.FC<UploadImageProps> = ({
  uploadClose,
  title,
  subtitle,
  width = 350,
  height = 350,
  isProfileImage = true,
  setImage,
}) => {
  const theme = useMantineTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const [exceedsImageSize, setExceedsImageSize] = useState<boolean>(false)
  const [invalidFileType, setInvalidFileType] = useState<boolean>(false)
  const [uploadError, setUploadError] = useState<boolean>(false)

  const errorMessages = useErrorMessages()

  // Loading and Error Handling
  const [loading, setLoading] = useState<boolean>(false)
  const [errorImageUpload, setErrorImageUpload] = useState<string | JSX.Element>('')
  const [errorMessage, setErrorMessage] = useState<string>('')

  // State Variables
  const [infoMessage, setInfoMessage] = useState<string>('')
  const [previewImage, setPreviewImage] = useState<any>(undefined)

  // Crop Mode
  const [cropMode, setCropMode] = useState<boolean>(false)
  const cropperRef = useRef<ReactCropperElement>()

  // Ref Variables
  const isLimitUploadSizeReached = useRef<boolean>(false)

  // Reset Crop Mode
  useEffect(() => {
    setCropMode(false)
  }, [isProfileImage])

  // Dropzone
  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: any) => {
    // reset limit upload size
    isLimitUploadSizeReached.current = false

    // reset messages
    setInfoMessage('')
    setErrorMessage('')

    // Only accepts one file to be uploaded
    const file: File = acceptedFiles[0]

    if (rejectedFiles.length > 0) {
      if (rejectedFiles[0].errors[0].code === ErrorCode.FileTooLarge) {
        setExceedsImageSize(true)
      }
      if (rejectedFiles[0].errors[0].code === ErrorCode.FileInvalidType) {
        setInvalidFileType(true)
      }
      return
    }
    setExceedsImageSize(false)
    setInvalidFileType(false)
    // Check file dimensions
    const image = new Image()
    image.src = URL.createObjectURL(file)
    image.onload = () => {
      // if image type is gif set info message
      if (file.type === GIF) {
        setInfoMessage(
          t(
            'components.settings.identity.uploadAvatarGifNotSupported',
            'GIF format is not supported for cropping'
          )
        )
      }
    }

    const reader = new FileReader()
    reader.onload = () => {
      setPreviewImage(reader.result as string)
    }
    // file exceeds max size
    if (rejectedFiles.length > 0) {
      setExceedsImageSize(true)
      return
    }

    setExceedsImageSize(false)

    reader.readAsDataURL(file)
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    // accept only image files with the following extensions: JPG, PNG, WEBP and GIF.
    // SVG is not supported
    accept: ACCEPTED_FILE_TYPES,
    maxSize: convertUnitToBytes(MAX_FILE_SIZE, MAX_FILE_UNIT),
    maxFiles: 1,
    onError: () => {
      setUploadError(true)
    },
  })

  // Crop Mode
  const previewToCropImage = () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      const imageType = cropperRef.current.src.split(';')[0].split(':')[1]
      const croppedImg = cropperRef.current?.cropper.getCroppedCanvas().toDataURL(imageType)
      setPreviewImage(croppedImg)
      setCropMode(false)
    }
  }

  const sendImgMetadata = useCallback(
    async (bytes: Uint8Array, setError: (value: string | JSX.Element) => void) => {
      let retval: { cid: string; error: string | JSX.Element } = {
        cid: '',
        error: '',
      }
      try {
        setLoading(true)
        const response = await fetch(`${STUDIO_BOT}/image`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/octet-stream',
            'Access-Control-Request-Method': 'POST',
          },
          body: bytes,
        })
        // HTTP Error
        if (!response.ok) {
          setLoading(false)
          setError(response.statusText)
          retval = {
            cid: '',
            error: response.statusText,
          }
          return retval
        }

        const serverRes = await response.json()
        // Server Error
        if (!serverRes.valid) {
          setLoading(false)
          setError(serverRes.error)
          retval = {
            cid: '',
            error: serverRes.error,
          }
          return retval
        }
        // Success
        retval = {
          cid: serverRes.cid,
          error: '',
        }
      } catch (err: any) {
        setLoading(false)
        setError(err.message)
        retval = {
          cid: '',
          error: err.message,
        }
      }
      setLoading(false)
      return retval
    },
    [previewImage]
  )

  const handleImageUpload = useCallback(async () => {
    if (!previewImage) return
    // Convert image string to bytes
    const imgBytes = convertImageToBytes(previewImage)
    // Upload Image to IPFS
    const { cid: cidImage, error: errorImg } = await sendImgMetadata(imgBytes, setErrorImageUpload)
    if (!errorImg) {
      setImage(`ipfs://${cidImage}`)
      uploadClose()
      return
    }
    setErrorMessage(AvatarErrorMessage)
  }, [previewImage, setImage, uploadClose])

  const handleZoomLabel = useCallback((value: number) => `${(value * 100).toFixed(0)}%`, [])

  const handleZoom = useCallback(
    (value: number) => {
      if (typeof cropperRef.current?.cropper !== 'undefined') {
        cropperRef.current.cropper.zoomTo(value)
      }
    },
    [cropperRef]
  )

  return (
    <>
      {!loading && !errorImageUpload && (
        <Stack>
          <Title order={4}>{title}</Title>
          <Text>{subtitle}</Text>
          <Text style={{ fontSize: isMobile ? '14px' : '16px' }}>
            {cropMode
              ? t(
                  'components.settings.identity.cropAvatarBody',
                  'Zoom In or Zoom out and crop your Avatar'
                )
              : (t(
                  'components.uploadImage.uploadAvatarBody',
                  'Files should be JPG, PNG, WEBP or GIF format. Recommended aspect ratio: {{ratio}}:1',
                  {
                    ratio: Number.isInteger(width / height)
                      ? width / height
                      : Number(width / height).toFixed(2),
                  }
                ) as string)}
          </Text>
          {cropMode ? (
            <>
              <Cropper
                style={{
                  width,
                  height,
                  margin: '16px auto auto auto',
                }}
                src={previewImage}
                ref={cropperRef as MutableRefObject<ReactCropperElement>}
                aspectRatio={width / height}
                viewMode={1}
                guides
                background={false}
                responsive
                checkOrientation={false}
              />
            </>
          ) : (
            <>
              {infoMessage && (
                <Text style={{ fontSize: isMobile ? '14px' : '16px' }}>{infoMessage}</Text>
              )}
              {errorMessage && (
                <Text
                  style={{
                    fontSize: isMobile ? '14px' : '16px',
                    color: theme.colors.notification[0],
                  }}
                >
                  {errorMessage}
                </Text>
              )}
              {invalidFileType ? (
                <Text style={{ textAlign: 'left', color: theme.colors.notification[0] }}>
                  <Trans i18nKey="components.uploadImage.invalidType">
                    File type not accepted. Please upload a file with the following extensions:{' '}
                  </Trans>
                  {convertDictToText(ACCEPTED_FILE_TYPES)}
                </Text>
              ) : (
                exceedsImageSize && (
                  <Text
                    style={{
                      fontSize: isMobile ? '14px' : '16px',
                      color: theme.colors.notification[0],
                    }}
                  >
                    {
                      t(
                        'components.settings.identity.uploadAvatarFileSizeExceeded',
                        'Error: Upload an image with maximum size of {{size}} MB',
                        {
                          size: MAX_FILE_SIZE,
                        }
                      ) as string
                    }
                  </Text>
                )
              )}
              <Box {...getRootProps()} style={{ height, width }} className={classes.uploadBox}>
                <input {...getInputProps()} type="file" style={{ display: 'none' }} />
                {previewImage ? (
                  <img
                    src={previewImage}
                    alt="Dropped"
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '6px',
                      objectFit: isProfileImage ? 'inherit' : 'cover',
                    }}
                  />
                ) : (
                  <>
                    <Stack
                      style={{ marginTop: height >= 350 ? '100px' : !isMobile ? '10px' : '0px' }}
                      className={classes.boxContent}
                    >
                      {height >= 350 && <IconCloudUpload className={classes.uploadIcon} />}
                      {uploadError ? (
                        <Trans i18nKey="components.uploadImage.failupload">
                          <Text
                            className={classes.boxText}
                            style={{ color: theme.colors.notification[0] }}
                          >
                            Failed to
                            <br />
                            Upload
                          </Text>
                        </Trans>
                      ) : (
                        <Trans i18nKey="components.settings.identity.uploadAvatarInstructions">
                          <Text className={classes.boxText}>
                            <a>Click to upload</a>
                            <br />
                            or drag and drop
                          </Text>
                        </Trans>
                      )}
                    </Stack>
                    <Text
                      style={{
                        color: exceedsImageSize
                          ? theme.colors.notification[0]
                          : theme.colors.paragraph[0],
                        textAlign: 'center',
                      }}
                    >
                      {
                        t(
                          'components.settings.identity.maximumAvatarFileSizeMessage',
                          'Maximum file size {{size}}MB',
                          {
                            size: MAX_FILE_SIZE,
                          }
                        ) as string
                      }
                    </Text>
                    {height >= 350 && (
                      <Text
                        style={{
                          color: invalidFileType
                            ? theme.colors.notification[0]
                            : theme.colors.paragraph[0],
                          textAlign: 'center',
                        }}
                      >
                        {convertDictToText(ACCEPTED_FILE_TYPES)}
                      </Text>
                    )}
                  </>
                )}
              </Box>
            </>
          )}
          {cropMode && (
            <Stack style={{ flexDirection: 'row', alignItems: 'center', gap: 16, marginTop: 20 }}>
              <IconZoomOutFilled size={24} style={{ color: theme.colors.textAndIcon[0] }} />
              <Slider
                defaultValue={0.1}
                step={0.01}
                min={0}
                label={handleZoomLabel}
                max={1}
                style={{ width: '100%' }}
                onChange={handleZoom}
              />
              <IconZoomInFilled size={24} style={{ color: theme.colors.textAndIcon[0] }} />
            </Stack>
          )}

          <Stack style={{ flexDirection: 'row', marginTop: '20px' }}>
            {!previewImage ? (
              <RootButton onClick={uploadClose} expand style1={false} secondary>
                {t('buttons.cancel', 'Cancel')}
              </RootButton>
            ) : !cropMode ? (
              <RootButton
                onClick={() => setCropMode(true)}
                expand
                style1={false}
                disabled={!!infoMessage}
                secondary
              >
                {t('buttons.cropMode', 'Crop Mode')}
              </RootButton>
            ) : (
              <RootButton onClick={() => setCropMode(false)} expand style1={false} secondary>
                {t('buttons.cancel', 'Cancel')}
              </RootButton>
            )}

            {cropMode ? (
              <RootButton onClick={previewToCropImage} expand style1={false}>
                {t('buttons.crop', 'Crop')}
              </RootButton>
            ) : (
              <RootButton
                disabled={!previewImage || isLimitUploadSizeReached.current}
                onClick={handleImageUpload}
                expand
                style1={false}
              >
                {t('buttons.confirm', 'Confirm')}
              </RootButton>
            )}
          </Stack>
        </Stack>
      )}
      {loading && !errorImageUpload && <Loading />}
      {errorImageUpload && (
        <ErrorMessage message={errorMessages.studioBotConnection} details={errorImageUpload} />
      )}
    </>
  )
}

export default UploadImage

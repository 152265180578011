import { ActionIcon, Container, Menu, Stack, Title, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useCallback, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import l1TokenBlack from '@/assets/tokens/l1TokenBlack.svg'
import l1TokenWhite from '@/assets/tokens/l1TokenWhite.svg'
import { useChainValues } from '@/hooks/useChain'
import { l1NativeWagmiConfig } from '@/plugins/auth/config'
import { saveLocation } from '@/plugins/auth/RouteRedirectors/utils'

import OpenMenuArrow from '../Animations/OpenMenuArrow/OpenMenuArrow'
import DisplayUsername from '../DisplayUsername'
import IconWrapper from '../MediaWrappers/IconWrapper'
import WalletManagement from '../Wallet/WalletManagement'
import classes from './WalletButton.module.css'

const BalanceDisplay: React.FC = () => {
  const { balance } = useChainValues(l1NativeWagmiConfig.id)
  const { pathname } = useLocation()
  const theme = useMantineTheme()
  return (
    <Stack style={{ gap: '8px', flexDirection: 'row', alignItems: 'center' }}>
      <IconWrapper classes={classes.tokenLogo} iconDark={l1TokenWhite} iconLight={l1TokenBlack} />
      <Title
        order={4}
        style={{
          color: pathname === '/wallet' ? theme.colors.l1Primary[0] : theme.colors.title[0],
        }}
      >
        {Number.isNaN(Number(balance.fullValue)) ? '-' : balance.display}
      </Title>
    </Stack>
  )
}

interface WalletButtonProps {
  address: string
  username?: string
}

const WalletButton: React.FC<WalletButtonProps> = ({ address, username }) => {
  const [menuOpened, setMenuOpened] = useState<boolean>(false)
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`)
  const navigate = useNavigate()

  const closeTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const openWallet = useCallback(
    (e: any) => {
      e.stopPropagation()
      if (!isMobile) {
        if (closeTimeoutRef.current) {
          clearTimeout(closeTimeoutRef.current)
        }
        setMenuOpened(true)
      }
    },
    [isMobile]
  )

  const closeMenu = useCallback(() => {
    if (!isMobile) {
      closeTimeoutRef.current = setTimeout(() => {
        setMenuOpened(false)
      }, 300)
    }
  }, [isMobile])

  const handleOnClick = useCallback((e: any) => {
    e.stopPropagation()
    navigate('/wallet')
    saveLocation('/wallet')
  }, [])

  const handleToggle = useCallback(
    (e: any) => {
      e.stopPropagation()
      e.preventDefault()
      setMenuOpened(prev => !prev)
    },
    [openWallet]
  )

  return (
    address && (
      <Menu opened={menuOpened} closeOnClickOutside closeOnEscape position="bottom-end">
        <Menu.Target>
          <Container
            className={classes.walletButtonContainer}
            style={{
              border: menuOpened
                ? `1px solid ${theme.colors.splitter[0]}`
                : '1px solid transparent',
              backgroundColor: menuOpened ? theme.colors.background2[0] : 'transparent',
              cursor: 'pointer',
            }}
            onMouseEnter={openWallet}
            onMouseLeave={closeMenu}
            onClick={handleOnClick}
          >
            <Stack style={{ flexDirection: 'row', justifyContent: 'space-between', gap: '2px' }}>
              <Stack gap="0px" style={{ marginTop: '4px' }}>
                <BalanceDisplay />
                <DisplayUsername address={address} username={username} colorless noButton />
              </Stack>
              <ActionIcon onClick={handleToggle}>
                <OpenMenuArrow menuOpened={menuOpened} />
              </ActionIcon>
            </Stack>
          </Container>
        </Menu.Target>
        <Menu.Dropdown className={classes.dropdown}>
          <Container onMouseEnter={openWallet} onMouseLeave={closeMenu}>
            <WalletManagement closePopup={closeMenu} />
          </Container>
        </Menu.Dropdown>
      </Menu>
    )
  )
}

export default WalletButton

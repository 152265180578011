import { Center } from '@mantine/core'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Loading from '@/components/Loading'
import { useAuth } from '@/plugins/auth'
import { getUnauthedRedirectPath } from '@/plugins/auth/RouteRedirectors/utils'

const RootRedirector: React.FC = () => {
  const navigate = useNavigate()
  const { status } = useAuth()

  useEffect(() => {
    if (status === 'connected') {
      navigate('/home')
    } else if (status === 'disconnected') {
      navigate(getUnauthedRedirectPath(), { replace: true })
    }
  }, [status, navigate])

  return (
    <Center style={{ height: '100vh', width: '100vw' }}>
      <Loading />
    </Center>
  )
}

export default RootRedirector

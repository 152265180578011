import { Group, Radio, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import React from 'react'

import useNetworkType, { NetworkType } from '@/hooks/useNetworkType'

interface SimulateNetworkToolProps {}

const SimulateNetworkTool: React.FC<SimulateNetworkToolProps> = () => {
  const theme = useMantineTheme()
  const { simulatedNetworkType, isTestnet, simulateNetwork } = useNetworkType()

  const onRadioChange = (value: string) => {
    simulateNetwork(value as NetworkType)
  }

  return (
    isTestnet && (
      <Stack
        style={{
          border: `1px solid ${theme.colors.l1Primary[0]}`,
          padding: '10px',
          borderRadius: '12px',
        }}
      >
        <Title order={5} style={{ color: theme.colors.l1Primary[0] }}>
          TOOL: Simulate Network Type (Betanet/Mainnet)
        </Title>
        <Radio.Group value={simulatedNetworkType || 'mainnet'} onChange={onRadioChange}>
          <Group mt="xs">
            <Radio value="betanet" label="betanet" />
            <Radio value="mainnet" label="mainnet" />
          </Group>
        </Radio.Group>
        <Text style={{ fontSize: 14 }}>Note: Only Displayed on Testnet</Text>
      </Stack>
    )
  )
}

export default SimulateNetworkTool

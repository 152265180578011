import { Center } from '@mantine/core'
import type { LazyRouteFunction } from '@remix-run/router'
import { forwardRef, memo, ReactNode, useEffect } from 'react'
import type { NonIndexRouteObject } from 'react-router/dist/lib/context'
import { useNavigate } from 'react-router-dom'

import Loading from '@/components/Loading'
import { useAuth } from '@/plugins/auth'
import { getUnauthedRedirectPath } from '@/plugins/auth/RouteRedirectors/utils'

interface AuthenticatedProps {
  children: ReactNode
  whileConnecting?: 'loader' | 'nothing' | 'child'
}

const Authenticated = memo(({ children, whileConnecting = 'loader' }: AuthenticatedProps) => {
  const { status } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (status === 'disconnected') {
      const to = getUnauthedRedirectPath()
      navigate(to, { replace: true })
    }
  }, [status, navigate])

  if (!children) {
    return null
  }

  if (status === 'disconnected') {
    // return nothing, as the effect will redirect
    return null
  }

  if (status === 'connected') {
    // if connected, just render the children
    return children
  }

  // if connecting, render a loading screen, the children or nothing
  return whileConnecting === 'loader' ? (
    <Center style={{ height: '100vh' }}>
      <Loading />
    </Center>
  ) : whileConnecting === 'child' ? (
    children
  ) : null
})
Authenticated.displayName = 'Authenticated'

type RouteDynamicImport = ReturnType<LazyRouteFunction<NonIndexRouteObject>>
export function authenticated<T extends ReactNode>(
  child: T,
  whileConnecting?: AuthenticatedProps['whileConnecting']
): ReactNode {
  return <Authenticated whileConnecting={whileConnecting}>{child}</Authenticated>
}

const authedDynImportWeakMap = new WeakMap<
  Awaited<RouteDynamicImport>,
  Awaited<RouteDynamicImport>
>()
export async function authenticatedDynImport(
  importPromise: RouteDynamicImport,
  whileConnecting?: AuthenticatedProps['whileConnecting']
): RouteDynamicImport {
  const awaitedImport = await importPromise
  const preloadedAuthedDynImport = authedDynImportWeakMap.get(awaitedImport)
  if (preloadedAuthedDynImport) {
    return preloadedAuthedDynImport
  }

  const im = { ...awaitedImport }
  if (im.element) im.element = <Authenticated>{im.element}</Authenticated>
  const ImComponent = im.Component
  if (ImComponent) {
    const authedComponent = forwardRef((props, ref) => (
      <Authenticated whileConnecting={whileConnecting}>
        {/* @ts-expect-error */}
        <ImComponent {...props} ref={ref} />
      </Authenticated>
    ))
    authedComponent.displayName = `${ImComponent.displayName ?? ''}Authenticated`
    im.Component = authedComponent
  }
  authedDynImportWeakMap.set(awaitedImport, im)

  return im
}

import { type Dispatch, useCallback, useEffect, useMemo, useRef } from 'react'
import { useAccount } from 'wagmi'

import { AuthAction, AuthActionType } from '@/plugins/auth/AuthProvider/types/AuthActions'
import {
  AuthSteps,
  InternalAuthState,
  isConnectingStep,
  isDisconnectingStep,
} from '@/plugins/auth/AuthProvider/types/AuthInternalState'
import { avalancheConfig, l1IdSubnetConfig, l1NativeConfig } from '@/plugins/auth/config'

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
export default function _useSyncWithWagmi(
  internalState: InternalAuthState,
  dispatch: Dispatch<AuthAction>
) {
  const wagmiAccount = useAccount()

  const connectorType = useMemo(
    () =>
      wagmiAccount.connector === undefined
        ? undefined
        : wagmiAccount.connector.name === 'Web3Auth'
          ? 'Web3Auth'
          : wagmiAccount.connector.name === 'Injected'
            ? 'Injected'
            : 'Unknown',
    [wagmiAccount.connector]
  )

  const initDisconnect = useCallback(() => {
    dispatch({
      type: AuthActionType.DisconnectInit,
    })
  }, [dispatch])

  const initConnectWithAddress = useCallback(
    (address: `0x${string}`) => {
      dispatch({
        // The action after Wagmi initialization
        type: AuthActionType.ConnectUserInfo,
        address,
      })
    },
    [dispatch]
  )

  const prevWagmiAccountRef = useRef<ReturnType<typeof useAccount>>()
  useEffect(() => {
    const { current: prevWagmiAccount } = prevWagmiAccountRef
    prevWagmiAccountRef.current = wagmiAccount

    // On disconnect
    if (wagmiAccount.status === 'disconnected' && prevWagmiAccount?.status !== 'disconnected') {
      if (
        internalState.step === AuthSteps.Init ||
        (!isDisconnectingStep(internalState.step) && internalState.step !== AuthSteps.Disconnected)
      ) {
        initDisconnect()
      }
      return
    }

    // On connect
    if (wagmiAccount.status === 'connected' && prevWagmiAccount?.status !== 'connected') {
      if (
        internalState.step === AuthSteps.Init ||
        (!isConnectingStep(internalState.step) && internalState.step !== AuthSteps.Connected)
      ) {
        initConnectWithAddress(wagmiAccount.address)
      }
      // eslint-disable-next-line no-useless-return
      return
    }

    // If the Wagmi status is not final (connected or disconnected), do
    // nothing and wait for it to change or for the user to attempt an action.
  }, [dispatch, initConnectWithAddress, initDisconnect, internalState.step, wagmiAccount])

  // Update on Account change (Metamask) - when OpenLogin or JWT not in use
  useEffect(() => {
    if (wagmiAccount.status !== 'connected') return

    if (localStorage.getItem('Web3Auth-cachedAdapter') === 'metamask') {
      const { ethereum } = window as any
      if (!ethereum) return

      // TODO: add multi-account support
      const handleAccountsChanged = async (accounts: string[]) => {
        if (Array.isArray(accounts) && accounts.includes(wagmiAccount.address)) return

        initDisconnect()
      }

      const handleChainChanged = async (chainId: string) => {
        if (
          chainId === l1NativeConfig.chainIdHex ||
          chainId === l1IdSubnetConfig.chainIdHex ||
          chainId === avalancheConfig.chainIdHex
        )
          return

        initDisconnect()
      }

      ethereum.on('accountsChanged', handleAccountsChanged)
      ethereum.on('chainChanged', handleChainChanged)

      // eslint-disable-next-line consistent-return
      return () => {
        ethereum.removeListener('accountsChanged', handleAccountsChanged)
        ethereum.removeListener('chainChanged', handleChainChanged)
      }
    }
  }, [initDisconnect, wagmiAccount.address, wagmiAccount.status])

  return connectorType
}

import { Stack, Text, Tooltip, useMantineTheme } from '@mantine/core'
import { IconInfoCircleFilled } from '@tabler/icons-react'
import React from 'react'

interface TooltipTextProps {
  text: string | React.ReactNode
  tooltip?: string | React.ReactNode
  position?: 'top' | 'bottom' | 'left' | 'right'
  textStyle?: React.CSSProperties
}

const TooltipText: React.FC<TooltipTextProps> = ({
  text,
  tooltip,
  position = 'top',
  textStyle,
}) => {
  const theme = useMantineTheme()
  return (
    <Stack style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
      <Text style={textStyle}>{text}</Text>
      {tooltip && (
        <Tooltip
          multiline
          label={tooltip}
          withArrow
          position={position}
          offset={10}
          style={{
            // wrap
            whiteSpace: 'pre-wrap',
          }}
        >
          <IconInfoCircleFilled size={15} style={{ color: theme.colors.textAndIcon[0] }} />
        </Tooltip>
      )}
    </Stack>
  )
}

export default TooltipText

import { FC, useEffect } from 'react'
import ga4 from 'react-ga4'
import { useLocation, useOutlet } from 'react-router-dom'

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TOKEN || 'G-6L0T09XQF8'
const isProduction = process.env.NODE_ENV === 'production'

const init = () =>
  ga4.initialize(TRACKING_ID, {
    testMode: !isProduction,
  })

const sendPageview = (path: string) =>
  ga4.send({
    hitType: 'pageview',
    page: path,
  })

function useAnalytics() {
  const location = useLocation()

  useEffect(() => {
    if (TRACKING_ID) {
      init()
    }
  }, [])

  useEffect(() => {
    sendPageview(location.pathname + location.search)
  }, [location])
}

const Analytics: FC = () => {
  useAnalytics()

  return useOutlet()
}

export default Analytics

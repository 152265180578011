import { Stack, Text, TextInput, Title } from '@mantine/core'
import { signMessage } from '@wagmi/core'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import RootButton from '@/components/Buttons/RootButton'
import ErrorMessage from '@/components/ErrorMessage'
import Loading from '@/components/Loading'
import { getWagmiConfig } from '@/plugins/auth/config'

import CopyButton from '../../Buttons/CopyButton'
import ModalSkeleton from '../ModalSkeleton'

interface SignatureModalProps {
  close: () => void
}

const SignatureModal: React.FC<SignatureModalProps> = ({ close }) => {
  const { t } = useTranslation()
  const [message, setMessage] = useState('')
  const [signature, setSignature] = useState('')
  const [errorSigning, setErrorSigning] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleSign = useCallback(async () => {
    if (!message) return
    try {
      setIsLoading(true)
      const sig = await signMessage(getWagmiConfig(), { message })
      console.log('sig', sig)
      setSignature(sig)
    } catch (error: any) {
      setErrorSigning(error.message)
      setIsLoading(false)
    }
    setIsLoading(false)
  }, [message])

  const handleReset = useCallback(() => {
    setMessage('')
    setSignature('')
    setIsLoading(false)
    setErrorSigning('')
  }, [])

  const onChangeMessage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.currentTarget.value)
    setErrorSigning('')
  }, [])

  return (
    <ModalSkeleton opened onClose={close} title={t('components.wallet.sign', 'Sign Message')}>
      <Stack>
        {!isLoading && !errorSigning && signature ? (
          <>
            <Stack style={{ flexDirection: 'row' }} justify="space-between" align="baseline">
              <Title order={5}>{t('modals.signature.resultTitle', 'Signature')}</Title>
              <CopyButton value={signature} transparent />
            </Stack>
            <Text
              style={{
                wordWrap: 'break-word',
                width: '100%',
                maxWidth: '100%',
                overflow: 'hidden',
                whiteSpace: 'wrap',
                textAlign: 'left',
                fontSize: '16px',
                fontFamily: 'monospace',
              }}
            >
              {signature}
            </Text>
          </>
        ) : (
          <TextInput
            label={t('modals.signature.message', 'Message to sign')}
            value={message}
            onChange={onChangeMessage}
            placeholder={t('modals.signature.messagePlaceholder', 'Enter your message here')}
          />
        )}
        {isLoading && <Loading />}
        {errorSigning && (
          <ErrorMessage
            fullWidth
            message={t(
              'components.spaceLasers.requestSignature.errorMessage',
              'Error requesting signature'
            )}
            details={errorSigning}
          />
        )}
        <Stack style={{ flexDirection: 'row' }}>
          <RootButton style1={false} secondary expand onClick={close} variant="outline">
            {t('buttons.close', 'Close')}
          </RootButton>
          {signature ? (
            <RootButton style1={false} expand onClick={handleReset}>
              {t('buttons.reset', 'Reset')}
            </RootButton>
          ) : (
            <RootButton style1={false} expand onClick={handleSign} disabled={isLoading && !message}>
              {t('buttons.sign', 'Sign')}
            </RootButton>
          )}
        </Stack>
      </Stack>
    </ModalSkeleton>
  )
}

export default SignatureModal

import type { Config, ResolvedRegister } from '@wagmi/core'
import { ReadContractData } from '@wagmi/core/query'
import { useEffect, useState } from 'react'
import { Abi, ContractFunctionArgs, ContractFunctionName } from 'viem'
import { useReadContract, UseReadContractParameters, UseReadContractReturnType } from 'wagmi'

import { emptyBalance, processBalance, ProcessedBalance } from '@/utils/utils'

export const useCustomContractReadBalance = <
  const TAbi extends Abi | readonly unknown[],
  TFunctionName extends ContractFunctionName<TAbi, 'pure' | 'view'>,
  TArgs extends ContractFunctionArgs<TAbi, 'pure' | 'view', TFunctionName>,
  TConfig extends Config = ResolvedRegister['config'],
  TSelectData = ReadContractData<TAbi, TFunctionName, TArgs>,
>(
  parameters: UseReadContractParameters<TAbi, TFunctionName, TArgs, TConfig, TSelectData>,
  blockNumber: number,
  enabled: boolean = true
) => {
  const { chainId, address, abi, functionName } = parameters
  const args = parameters.args as readonly unknown[]
  const {
    data,
    isLoading,
    isError,
    error,
    refetch,
  }: UseReadContractReturnType<TAbi, TFunctionName, TArgs, TSelectData> = useReadContract({
    chainId,
    address,
    abi,
    functionName,
    args,
    query: {
      enabled,
    },
  })

  // Set value
  const [value, setValue] = useState<ProcessedBalance>(emptyBalance)
  useEffect(() => {
    if (!isLoading && !isError && data !== undefined) {
      setValue(processBalance(data as bigint))
    }
  }, [data, isLoading, isError])

  // Refetch
  // NOTE: need 2 separate useEffects because data changes after refetch is called
  // and we only want to call refetch when enabled flag changes or on new blocks
  useEffect(() => {
    if (enabled) {
      refetch()
    }
  }, [enabled, blockNumber])

  return {
    value,
    isLoading,
    error: { call: functionName, msg: error?.message, isError: enabled ? isError : false },
  }
}

export const useCustomContractRead = <
  const TAbi extends Abi | readonly unknown[],
  TFunctionName extends ContractFunctionName<TAbi, 'pure' | 'view'>,
  TArgs extends ContractFunctionArgs<TAbi, 'pure' | 'view', TFunctionName>,
  TConfig extends Config = ResolvedRegister['config'],
  TSelectData = ReadContractData<TAbi, TFunctionName, TArgs>,
>(
  parameters: UseReadContractParameters<TAbi, TFunctionName, TArgs, TConfig, TSelectData>,
  blockNumber: number,
  enabled: boolean = true
) => {
  const { chainId, address, abi, functionName } = parameters
  const args = parameters.args as readonly unknown[]
  const {
    data,
    isLoading,
    isError,
    error,
    refetch,
  }: UseReadContractReturnType<TAbi, TFunctionName, TArgs, TSelectData> = useReadContract({
    chainId,
    address,
    abi,
    functionName,
    args,
    query: {
      enabled,
    },
  })

  // Set value
  const [value, setValue] = useState<TSelectData>()
  useEffect(() => {
    if (!isLoading && !isError && data !== undefined) {
      setValue(data)
    }
  }, [data, isLoading, isError])

  // Refetch
  // NOTE: need 2 separate useEffects because data changes after refetch is called
  // and we only want to call refetch when enabled flag changes or on new blocks
  useEffect(() => {
    if (enabled) {
      refetch()
    }
  }, [enabled, blockNumber])

  return {
    value,
    isLoading,
    error: { call: functionName, msg: error?.message, isError: enabled ? isError : false },
  }
}

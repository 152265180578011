import type { TFunction } from 'i18next'
import { isAddress } from 'viem'
import { normalize } from 'viem/ens'

import { BLANK_ADDRESS } from '@/constants/blockchain'

// Handle
export const MAX_HANDLE_LENGTH = 63
export const MIN_HANDLE_LENGTH = 1

// Nickname
export const MAX_NICKNAME_LENGTH = 32
export const MIN_NICKNAME_LENGTH = 3

enum NicknameError {
  None = 0,
  IsSameAsBefore,
  IsEmpty,
  InvalidLength,
  InvalidChars,
  IsEthAddr,
}

export function getNicknameErrorMessage(t: TFunction, error: NicknameError): string {
  switch (error) {
    case NicknameError.None:
      return ''
    case NicknameError.IsSameAsBefore:
      return t('components.identity.invalidName.same', 'Select a different nickname')
    case NicknameError.IsEmpty:
      return t('components.identity.invalidName.empty', 'Display Name cannot be empty')
    case NicknameError.InvalidLength:
      return t(
        'components.identity.invalidName.length',
        'Display Name must be between 3 and 32 characters long'
      )
    case NicknameError.InvalidChars:
      return t(
        'components.identity.invalidName.invalidChars',
        'Display Name can only contain letters, numbers, dashes, underscores and spaces'
      )
    case NicknameError.IsEthAddr:
      return t(
        'components.identity.invalidName.ethAddr',
        'Display Name cannot be an Ethereum address'
      )
    default:
      // This should never happen, so there's no need to translate it
      return 'Invalid Display Name'
  }
}

// export function validateNickname()

export const validateNickname = (
  t: TFunction,
  currentNickname: string,
  nickname: string
): string => {
  // Checking the length of the nickname (assuming a minimum length of 3 and a maximum length of 32)
  if (nickname.length < MIN_NICKNAME_LENGTH || nickname.length > MAX_NICKNAME_LENGTH) {
    return t(
      'components.identity.invalidName.length',
      'Display Name must be between 3 and 32 characters long'
    )
  }

  // Checking for special characters (allowing only letters, numbers, dashes, underscores and spaces)
  if (!/^[a-zA-Z0-9-_ ]+$/.test(nickname)) {
    return t(
      'components.identity.invalidName.invalidChars',
      'Display Name can only contain letters, numbers, dashes, underscores and spaces'
    )
  }

  // Checking if the nickname is a eth address
  if (isAddress(nickname)) {
    return t(
      'components.identity.invalidName.ethAddr',
      'Display Name cannot be an Ethereum address'
    )
  }

  // If all checks pass, return an empty string
  return ''
}

export const checkInputHandleAddress = (t: TFunction, value: string, isHandle: boolean): string => {
  if (isHandle) {
    if (value.trim().length === 0)
      return t(
        'components.identity.userValidation.empty',
        'Destination address or Username is required'
      )
    if (value.length > MAX_HANDLE_LENGTH)
      return t(
        'components.identity.userValidation.short',
        `Username must be less than {{count}} characters long`,
        { count: MAX_HANDLE_LENGTH }
      )
    if (value.length < MIN_HANDLE_LENGTH)
      return t(
        'components.identity.userValidation.long',
        `Username must be more than {{count}} characters long`,
        { count: MIN_HANDLE_LENGTH }
      )
    try {
      normalize(value)
    } catch (error) {
      return t('components.identity.userValidation.invalid', 'Username is invalid')
    }
    // if (!/^[a-zA-Z0-9_]+$/.test(value)) return 'Handle must be alphanumeric'
  } else {
    if (value.length === 0)
      return t('components.identity.userValidation.emptyAddress', 'Destination address is required')
    if (!isAddress(value) || value === BLANK_ADDRESS)
      return t('components.identity.userValidation.invalidAddress', 'Invalid address')
  }
  return ''
}

export const checkInputValue = (
  t: TFunction,
  value: string,
  balance: string,
  estimatedFee: number
): string => {
  value = value.trim()
  if (value.length === 0)
    return t('components.identity.balanceValidation.empty', 'Value is required')
  if (!/^\d+(?:\.\d+)?$/.test(value))
    return t('components.identity.balanceValidation.invalid', 'Value must be a number')
  const n = parseFloat(value)
  if (n <= 0)
    return t('components.identity.balanceValidation.positive', 'Value must be greater than 0')
  if (!balance || n + estimatedFee > parseFloat(balance))
    return t('components.identity.balanceValidation.youArePoor', 'Insufficient balance')
  return ''
}

import type { Reducer } from 'react'

import {
  AuthSteps,
  type InternalAuthState,
  type InternalAuthStateConnected,
  isConnectingStep,
  isDisconnectingStep,
  throwIfInvalidStateTransition,
} from '@/plugins/auth/AuthProvider/types/AuthInternalState'

import { type AuthAction, AuthActionType } from './types/AuthActions'

export type InternalAuthStateReducer = Reducer<InternalAuthState, AuthAction>

const reducer: InternalAuthStateReducer = (state, action) => {
  try {
    switch (action.type) {
      // DisconnectInit actions
      case AuthActionType.DisconnectInit:
        return throwIfInvalidStateTransition(state, {
          step: AuthSteps.DisconnectingInit,
        })
      case AuthActionType.DisconnectWagmi:
        return throwIfInvalidStateTransition(state, {
          step: AuthSteps.DisconnectingWagmi,
        })
      case AuthActionType.DisconnectStartWeb3AuthLogout:
        return throwIfInvalidStateTransition(state, {
          step: AuthSteps.DisconnectingLogoutWeb3Auth,
        })
      case AuthActionType.Disconnected:
        return throwIfInvalidStateTransition(state, {
          step: AuthSteps.Disconnected,
        })

      // Connect actions
      case AuthActionType.ConnectInit:
        return throwIfInvalidStateTransition(state, {
          step: AuthSteps.ConnectingInit,
        })
      case AuthActionType.ConnectWagmi:
        return throwIfInvalidStateTransition(state, {
          step: AuthSteps.ConnectingWagmi,
        })
      case AuthActionType.ConnectCancel:
        if (state.step !== AuthSteps.ConnectingWagmi) return state
        return throwIfInvalidStateTransition(state, {
          step: AuthSteps.Disconnected,
        })
      case AuthActionType.ConnectUserInfo:
        return throwIfInvalidStateTransition(state, {
          step: AuthSteps.ConnectingUserInfo,
          address: action.address,
        })
      case AuthActionType.ConnectUserIdentity:
        if (state.step !== AuthSteps.ConnectingUserInfo)
          throw new Error(`Invalid state for ConnectUserIdentity action. State: ${state.step}`)
        return throwIfInvalidStateTransition(state, {
          step: AuthSteps.ConnectingUserIdentity,
          address: state.address,
          userInfo: action.userInfo,
        })
      case AuthActionType.ConnectProvider:
        if (state.step !== AuthSteps.ConnectingUserIdentity)
          throw new Error(`Invalid state for ConnectProvider action. State: ${state.step}`)
        return throwIfInvalidStateTransition(state, {
          step: AuthSteps.ConnectingProvider,
          address: state.address,
          userInfo: state.userInfo,
          userIdentity: action.userIdentity,
        })
      case AuthActionType.Connected:
        if (state.step !== AuthSteps.ConnectingProvider)
          throw new Error(`Invalid state for Connected action. State: ${state.step}`)
        return throwIfInvalidStateTransition(state, {
          step: AuthSteps.Connected,
          address: state.address,
          userInfo: state.userInfo,
          userIdentity: state.userIdentity,
          provider: action.provider,
        })
      case AuthActionType.ConnectError:
        if (!isConnectingStep(state.step)) {
          // Warns instead of throwing an error because this is already an error state
          console.warn(`Invalid state for ConnectError action. State: ${state.step}`)
        }
        console.log(action.error)
        return throwIfInvalidStateTransition(state, {
          step: AuthSteps.ConnectError,

          error: action.error instanceof Error ? action.error : new Error(action.error),
          address: (state as InternalAuthStateConnected).address,
          userInfo: (state as InternalAuthStateConnected).userInfo,
          userIdentity: (state as InternalAuthStateConnected).userIdentity,
          provider: (state as InternalAuthStateConnected).provider,
        })
      default:
        return state
    }
  } catch (e) {
    // If there's an invalid transition
    console.error(e)
    // If the valid transition was from a disconnecting state, move to disconnected
    if (isDisconnectingStep(state.step)) {
      return {
        step: AuthSteps.Disconnected,
      }
    }
    // If the valid transition was from a connecting state, start the disconnection flow
    if (isConnectingStep(state.step)) {
      return {
        step: AuthSteps.DisconnectingInit,
      }
    }
    return state
  }
}

export default reducer

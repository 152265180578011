const abi = [
  {
    inputs: [
      {
        internalType: 'contract ENS',
        name: '_ens',
        type: 'address',
      },
      {
        internalType: 'contract IBaseRegistrar',
        name: '_registrar',
        type: 'address',
      },
      {
        internalType: 'contract IReverseRegistrar',
        name: '_reverseRegistrar',
        type: 'address',
      },
      {
        internalType: 'bytes32',
        name: '_difficulty',
        type: 'bytes32',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'handle',
        type: 'string',
      },
    ],
    name: 'FreeHandleInvalidSuffix',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'handle',
        type: 'string',
      },
    ],
    name: 'FreeHandleTooShort',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'handle',
        type: 'string',
      },
    ],
    name: 'HandleAvailable',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'handle',
        type: 'string',
      },
    ],
    name: 'HandleNotAvailable',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'handle',
        type: 'string',
      },
    ],
    name: 'HandleNotExpired',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'handle',
        type: 'string',
      },
    ],
    name: 'HandleTooLong',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'handle',
        type: 'string',
      },
    ],
    name: 'HandleTooShort',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'bytes4',
        name: 'nonce',
        type: 'bytes4',
      },
    ],
    name: 'InvalidNonce',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'difficulty',
        type: 'bytes32',
      },
    ],
    name: 'InvalidPoWDifficulty',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        internalType: 'string',
        name: 'handle',
        type: 'string',
      },
    ],
    name: 'OnlyOwnerCanRenew',
    type: 'error',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'string',
        name: 'name',
        type: 'string',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
    ],
    name: 'HandleRegistered',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'string',
        name: 'name',
        type: 'string',
      },
    ],
    name: 'HandleRenewed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    inputs: [],
    name: 'DURATION',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'FREE_SUFFIX_SIZE',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'MAX_LENGTH',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'MIN_LENGTH',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'controller',
        type: 'address',
      },
    ],
    name: 'addController',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'handle',
        type: 'string',
      },
    ],
    name: 'available',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'controllers',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'difficulty',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'ens',
    outputs: [
      {
        internalType: 'contract ENS',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'bytes',
        name: '',
        type: 'bytes',
      },
    ],
    name: 'onERC721Received',
    outputs: [
      {
        internalType: 'bytes4',
        name: '',
        type: 'bytes4',
      },
    ],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'handle',
        type: 'string',
      },
      {
        internalType: 'bytes4',
        name: 'nonce',
        type: 'bytes4',
      },
    ],
    name: 'registerFree',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'handle',
        type: 'string',
      },
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
    ],
    name: 'registerPaid',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'registrar',
    outputs: [
      {
        internalType: 'contract IBaseRegistrar',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'controller',
        type: 'address',
      },
    ],
    name: 'removeController',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'handle',
        type: 'string',
      },
    ],
    name: 'renew',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
    ],
    name: 'renounce',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'resolver',
    outputs: [
      {
        internalType: 'contract Resolver',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'reverseRegistrar',
    outputs: [
      {
        internalType: 'contract IReverseRegistrar',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: '_difficulty',
        type: 'bytes32',
      },
    ],
    name: 'setDifficulty',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract Resolver',
        name: '_resolver',
        type: 'address',
      },
    ],
    name: 'setResolver',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'handle',
        type: 'string',
      },
      {
        internalType: 'bool',
        name: 'free',
        type: 'bool',
      },
    ],
    name: 'validateHandle',
    outputs: [],
    stateMutability: 'pure',
    type: 'function',
  },
] as const

export default abi

const abi = [
  {
    type: 'function',
    name: 'receiveTeleporterMessage',
    inputs: [
      { name: 'sourceBlockchainID', type: 'bytes32', internalType: 'bytes32' },
      { name: 'originSenderAddress', type: 'address', internalType: 'address' },
      { name: 'message', type: 'bytes', internalType: 'bytes' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'send',
    inputs: [
      {
        name: 'input',
        type: 'tuple',
        internalType: 'struct SendTokensInput',
        components: [
          { name: 'destinationBlockchainID', type: 'bytes32', internalType: 'bytes32' },
          { name: 'destinationTokenTransferrerAddress', type: 'address', internalType: 'address' },
          { name: 'recipient', type: 'address', internalType: 'address' },
          { name: 'primaryFeeTokenAddress', type: 'address', internalType: 'address' },
          { name: 'primaryFee', type: 'uint256', internalType: 'uint256' },
          { name: 'secondaryFee', type: 'uint256', internalType: 'uint256' },
          { name: 'requiredGasLimit', type: 'uint256', internalType: 'uint256' },
          { name: 'multiHopFallback', type: 'address', internalType: 'address' },
        ],
      },
      { name: 'amount', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'sendAndCall',
    inputs: [
      {
        name: 'input',
        type: 'tuple',
        internalType: 'struct SendAndCallInput',
        components: [
          { name: 'destinationBlockchainID', type: 'bytes32', internalType: 'bytes32' },
          { name: 'destinationTokenTransferrerAddress', type: 'address', internalType: 'address' },
          { name: 'recipientContract', type: 'address', internalType: 'address' },
          { name: 'recipientPayload', type: 'bytes', internalType: 'bytes' },
          { name: 'requiredGasLimit', type: 'uint256', internalType: 'uint256' },
          { name: 'recipientGasLimit', type: 'uint256', internalType: 'uint256' },
          { name: 'multiHopFallback', type: 'address', internalType: 'address' },
          { name: 'fallbackRecipient', type: 'address', internalType: 'address' },
          { name: 'primaryFeeTokenAddress', type: 'address', internalType: 'address' },
          { name: 'primaryFee', type: 'uint256', internalType: 'uint256' },
          { name: 'secondaryFee', type: 'uint256', internalType: 'uint256' },
        ],
      },
      { name: 'amount', type: 'uint256', internalType: 'uint256' },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'event',
    name: 'CallFailed',
    inputs: [
      { name: 'recipientContract', type: 'address', indexed: true, internalType: 'address' },
      { name: 'amount', type: 'uint256', indexed: false, internalType: 'uint256' },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'CallSucceeded',
    inputs: [
      { name: 'recipientContract', type: 'address', indexed: true, internalType: 'address' },
      { name: 'amount', type: 'uint256', indexed: false, internalType: 'uint256' },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'TokensAndCallSent',
    inputs: [
      { name: 'teleporterMessageID', type: 'bytes32', indexed: true, internalType: 'bytes32' },
      { name: 'sender', type: 'address', indexed: true, internalType: 'address' },
      {
        name: 'input',
        type: 'tuple',
        indexed: false,
        internalType: 'struct SendAndCallInput',
        components: [
          { name: 'destinationBlockchainID', type: 'bytes32', internalType: 'bytes32' },
          { name: 'destinationTokenTransferrerAddress', type: 'address', internalType: 'address' },
          { name: 'recipientContract', type: 'address', internalType: 'address' },
          { name: 'recipientPayload', type: 'bytes', internalType: 'bytes' },
          { name: 'requiredGasLimit', type: 'uint256', internalType: 'uint256' },
          { name: 'recipientGasLimit', type: 'uint256', internalType: 'uint256' },
          { name: 'multiHopFallback', type: 'address', internalType: 'address' },
          { name: 'fallbackRecipient', type: 'address', internalType: 'address' },
          { name: 'primaryFeeTokenAddress', type: 'address', internalType: 'address' },
          { name: 'primaryFee', type: 'uint256', internalType: 'uint256' },
          { name: 'secondaryFee', type: 'uint256', internalType: 'uint256' },
        ],
      },
      { name: 'amount', type: 'uint256', indexed: false, internalType: 'uint256' },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'TokensSent',
    inputs: [
      { name: 'teleporterMessageID', type: 'bytes32', indexed: true, internalType: 'bytes32' },
      { name: 'sender', type: 'address', indexed: true, internalType: 'address' },
      {
        name: 'input',
        type: 'tuple',
        indexed: false,
        internalType: 'struct SendTokensInput',
        components: [
          { name: 'destinationBlockchainID', type: 'bytes32', internalType: 'bytes32' },
          { name: 'destinationTokenTransferrerAddress', type: 'address', internalType: 'address' },
          { name: 'recipient', type: 'address', internalType: 'address' },
          { name: 'primaryFeeTokenAddress', type: 'address', internalType: 'address' },
          { name: 'primaryFee', type: 'uint256', internalType: 'uint256' },
          { name: 'secondaryFee', type: 'uint256', internalType: 'uint256' },
          { name: 'requiredGasLimit', type: 'uint256', internalType: 'uint256' },
          { name: 'multiHopFallback', type: 'address', internalType: 'address' },
        ],
      },
      { name: 'amount', type: 'uint256', indexed: false, internalType: 'uint256' },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'TokensWithdrawn',
    inputs: [
      { name: 'recipient', type: 'address', indexed: true, internalType: 'address' },
      { name: 'amount', type: 'uint256', indexed: false, internalType: 'uint256' },
    ],
    anonymous: false,
  },
] as const

export default abi

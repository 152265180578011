import { createContext, ReactNode, useContext } from 'react'

import { BalancesHook, EmptyBalances, useBalances } from '@/hooks/useBalances'

type WithChildren = {
  children?: ReactNode
}

const BalancesContext = createContext<BalancesHook>(EmptyBalances)

export const BalancesProvider: React.FC<WithChildren> = ({ children }) => {
  const value = useBalances()
  return <BalancesContext.Provider value={value}>{children}</BalancesContext.Provider>
}

export const useBalancesContext = () => useContext(BalancesContext)

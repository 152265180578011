import { Grid, Stack } from '@mantine/core'
import React from 'react'

import { useChainFeaturesContext } from '@/contexts/ChainFeaturesContext'

import BalancesDisplay from './BalancesDisplay'
import RewardsDisplay from './RewardsDisplay'
import WalletControl from './WalletControl'

interface WalletManagementProps {
  closePopup?: () => void
}

const WalletManagement: React.FC<WalletManagementProps> = ({ closePopup = undefined }) => {
  const { hasFeature } = useChainFeaturesContext()
  const hasBridge = hasFeature('bridge')
  const hasGovernance = hasFeature('governance')
  const hasLL1 = hasFeature('ll1')
  return (
    <Stack style={{ gap: '60px', minHeight: '500px' }}>
      <WalletControl closePopup={closePopup} />
      {(hasLL1 || hasGovernance || hasBridge) && (
        <Grid gutter="60px">
          <Grid.Col span="content">
            <BalancesDisplay closePopup={closePopup} />
          </Grid.Col>
          {!closePopup && (hasLL1 || hasGovernance) && (
            <Grid.Col span="content">
              <RewardsDisplay />
            </Grid.Col>
          )}
        </Grid>
      )}
    </Stack>
  )
}

export default WalletManagement

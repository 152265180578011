import { MantineThemeOverride } from '@mantine/core'
import { useMemo } from 'react'

import { Theme } from '../../utils/utils'
import actionIconClasses from './ActionIcon.module.css'
import autocompleteClasses from './Autocomplete.module.css'
import carouselClasses from './Carousel.module.css'
import checkboxClasses from './Checkbox.module.css'
import drawerClasses from './Drawer.module.css'
import modalClasses from './Modal.module.css'
import paginationClasses from './Pagination.module.css'
import radioClasses from './Radio.module.css'
import selectClasses from './Select.module.css'
import sliderClasses from './Sliders.module.css'
import switchClasses from './Switch.module.css'

// Define the dark theme colors
export const darkTheme: Theme = {
  background: '#000000',
  background2: '#101213',
  background3: '#202324',
  background4: '#34393A',

  title: '#ffffff',
  textAndIcon: '#888888',
  paragraph: '#B1B1B1',

  l1Primary: '#01FE57',
  l1Secondary: '#0000FF',
  l1PrimaryHover: '#6AFF9D',
  l1PrimaryOpacity: 'rgba(1, 254, 87, 0.33)',
  l1SecondaryOpacity: 'rgba(0, 0, 255, 0.5)',

  notification: '#FD406E',

  splitter: '#272727',

  profileOutline: '#2E2E2E',
  popUpOutline: '#1A1919',

  barChartPurple: '#BA61FF',
  barChartBlue: '#5CD8FF',
  barChartGreen: '#00FF57',
  barChartYellow: '#FFD700',
}

// Define the light theme colors
export const lightTheme: Theme = {
  background: '#ffffff',
  background2: '#F9F9F9',
  background3: '#E9ECED',
  background4: '#D9D9D9',

  title: '#000000',
  textAndIcon: '#C2C1C1',
  paragraph: '#777777',

  l1Primary: '#0000FF',
  l1Secondary: '#01FE57',
  l1PrimaryHover: '#6A82FF',
  l1PrimaryOpacity: 'rgba(0, 0, 255, 0.2)',
  l1SecondaryOpacity: 'rgba(1, 254, 87, 0.33)',

  notification: '#FF3541',

  splitter: '#EEEEEE',

  profileOutline: '#EEEEEE',
  popUpOutline: '#F0F0F0',

  barChartPurple: '#BA61FF',
  barChartBlue: '#5CD8FF',
  barChartGreen: '#00FF57',
  barChartYellow: '#FFD700',
}

function mapThemeToMantineTheme(customTheme: Theme): MantineThemeOverride {
  return {
    colors: {
      background: [customTheme.background, '', '', '', '', '', '', '', '', '', '', '', ''],
      background2: [customTheme.background2, '', '', '', '', '', '', '', '', '', '', '', ''],
      background3: [customTheme.background3, '', '', '', '', '', '', '', '', '', '', '', ''],
      background4: [customTheme.background4, '', '', '', '', '', '', '', '', '', '', '', ''],
      title: [customTheme.title, '', '', '', '', '', '', '', '', '', '', '', ''],
      textAndIcon: [customTheme.textAndIcon, '', '', '', '', '', '', '', '', '', '', '', ''],
      paragraph: [customTheme.paragraph, '', '', '', '', '', '', '', '', '', '', '', ''],
      l1Primary: [customTheme.l1Primary, '', '', '', '', '', '', '', '', '', '', '', ''],
      l1Secondary: [customTheme.l1Secondary, '', '', '', '', '', '', '', '', '', '', '', ''],
      l1PrimaryOpacity: [
        customTheme.l1PrimaryOpacity,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ],
      l1SecondaryOpacity: [
        customTheme.l1SecondaryOpacity,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ],
      l1PrimaryHover: [customTheme.l1PrimaryHover, '', '', '', '', '', '', '', '', '', '', '', ''],
      notification: [customTheme.notification, '', '', '', '', '', '', '', '', '', '', '', ''],
      splitter: [customTheme.splitter, '', '', '', '', '', '', '', '', '', '', '', ''],
      profileOutline: [customTheme.profileOutline, '', '', '', '', '', '', '', '', '', '', '', ''],
      popUpOutline: [customTheme.popUpOutline, '', '', '', '', '', '', '', '', '', '', '', ''],
      barChartPurple: [customTheme.barChartPurple, '', '', '', '', '', '', '', '', '', '', '', ''],
      barChartBlue: [customTheme.barChartBlue, '', '', '', '', '', '', '', '', '', '', '', ''],
      barChartGreen: [customTheme.barChartGreen, '', '', '', '', '', '', '', '', '', '', '', ''],
      barChartYellow: [customTheme.barChartYellow, '', '', '', '', '', '', '', '', '', '', '', ''],
    },
    fontFamily: 'monument-grotesk-regular, sans-serif',
    fontFamilyMonospace: 'Courier New, monospace',
    headings: {
      fontFamily: 'monument-grotesk-heavy, sans-serif',
      sizes: {
        h1: { fontSize: '52px', lineHeight: '53px' },
        h2: { fontSize: '34px', lineHeight: '34px' },
        h3: { fontSize: '22px', lineHeight: '26px' },
        h4: { fontSize: '19px', lineHeight: '22px' },
        h5: { fontSize: '14px', lineHeight: '14px' },
      },
    },
    breakpoints: {
      xs: '450px',
      sm: '576px',
      md: '768px',
      lg: '1300px',
      xl: '1500px',
    },
    fontSizes: {
      xs: '0.6rem',
      sm: '0.75rem',
      md: '0.9rem',
      lg: '1rem',
      xl: '1.2rem',
    },
    components: {
      Button: {
        defaultProps: {
          radius: '12px',
        },
        styles: () => ({
          root: {
            fontFamily: 'monument-grotesk-bold, sans-serif',
            backgroundColor: customTheme.background,
            color: customTheme.title,
          },
          icon: {
            width: 18,
            height: 18,
          },
        }),
      },
      Container: {
        defaultProps: {
          sizes: {
            xs: 540,
            sm: 720,
            md: 960,
            lg: 1140,
            xl: 1320,
          },
        },
      },
      Title: {
        defaultProps: {
          order: 3,
        },
        styles: () => ({
          root: {
            textAlign: 'left',
            fontFamily: 'monument-grotesk-heavy, sans-serif',
            fontWeight: 700,
            color: customTheme.title,
          },
        }),
      },
      Text: {
        defaultProps: {
          color: customTheme.paragraph,
        },
        styles: () => ({
          root: {
            textAlign: 'left',
          },
        }),
      },
      List: {
        styles: () => ({
          root: {
            textAlign: 'left',
            color: customTheme.paragraph,
            marginLeft: '20px',
          },
        }),
      },
      Modal: {
        defaultProps: {
          radius: '12px',
        },
        classNames: modalClasses,
      },
      ActionIcon: {
        classNames: actionIconClasses,
        styles: () => ({}),
      },
      Slider: {
        defaultProps: {
          radius: '12px',
          size: 2,
        },
        classNames: sliderClasses,
        styles: () => ({
          mark: {
            display: 'none',
          },
          markFilled: {
            display: 'none',
          },
        }),
      },
      RangeSlider: {
        defaultProps: {
          radius: '12px',
          size: 2,
        },
        classNames: sliderClasses,
      },
      Stepper: {
        styles: () => ({
          root: {
            width: '100%',
          },
          stepLabel: {
            fontFamily: 'monument-grotesk-heavy, sans-serif',
            color: customTheme.title,
          },
          stepBody: {
            marginLeft: '5px',
          },

          stepCompletedIcon: {
            backgroundColor: customTheme.l1Primary,
            borderRadius: '50%',
            color: customTheme.background,
          },
          stepIcon: {
            border: `2px solid ${customTheme.title}`,
            backgroundColor: customTheme.background,
            color: customTheme.background,
          },
          separator: {
            marginLeft: '5px',
            marginRight: '5px',
          },
        }),
      },
      Table: {
        defaultProps: {
          withRowBorders: false,
        },
      },
      Badge: {
        styles: () => ({
          root: {
            borderRadius: '4px',
            height: '20px',
            padding: '2px 10px',
            alignItems: 'center',
            fontSize: 14,
            fontFamily: 'monument-grotesk-heavy, sans-serif',
          },
          label: {
            color: 'black',
          },
        }),
      },
      TextInput: {
        defaultProps: {
          radius: '12px',
        },
        styles: () => ({
          input: {
            height: '48px',
            backgroundColor: 'transparent',
            color: customTheme.title,
            border: '2px solid',
            borderColor: customTheme.textAndIcon,
            fontSize: 16,
            fontFamily: 'monument-grotesk-regular, sans-serif',
          },
        }),
      },
      Autocomplete: {
        defaultProps: {
          radius: '12px',
        },
        classNames: autocompleteClasses,
      },
      NumberInput: {
        defaultProps: {
          radius: '12px',
          thousandSeparator: ',',
          decimalSeparator: '.',
          allowNegative: false,
          hideControls: true,
        },
        styles: () => ({
          input: {
            height: '48px',
            backgroundColor: 'transparent',
            color: customTheme.title,
            border: '2px solid',
            borderColor: customTheme.textAndIcon,
            fontSize: 16,
            fontFamily: 'monument-grotesk-regular, sans-serif',
          },
        }),
      },
      Textarea: {
        defaultProps: {
          radius: '12px',
        },
        styles: () => ({
          input: {
            backgroundColor: 'transparent',
            color: customTheme.title,
            fontSize: 16,
            fontFamily: 'monument-grotesk-regular, sans-serif',
            border: '2px solid',
            borderColor: customTheme.textAndIcon,
          },
        }),
      },
      Pagination: {
        defaultProps: {
          radius: '12px',
          color: customTheme.l1Primary,
        },
        classNames: paginationClasses,
      },
      Carousel: {
        defaultProps: {
          draggable: false,
          slideGap: '30px',
          dragFree: true,
          initialSlide: 0,
          withControls: true,
          controlsOffset: 0,
          align: 'start',
          slidesToScroll: 1,
        },
        classNames: carouselClasses,
      },
      SimpleGrid: {
        defaultProps: {
          verticalSpacing: '30px',
          spacing: '30px',
          cols: { base: 2, xs: 2, md: 4, lg: 4 },
        },
        styles: () => ({
          root: {
            paddingTop: '10px',
          },
        }),
      },
      Select: {
        classNames: selectClasses,
      },
      Radio: {
        classNames: radioClasses,
      },
      RadioGroup: {
        styles: () => ({
          label: {
            color: customTheme.title,
            fontFamily: 'monument-grotesk-heavy, sans-serif',
            fontSize: '18px',
          },
        }),
      },
      Divider: {
        defaultProps: {
          color: customTheme.splitter,
          height: 1,
        },
      },
      Drawer: {
        classNames: drawerClasses,
      },
      Tooltip: {
        defaultProps: {
          events: {
            touch: true,
            focus: true,
            hover: true,
          },
          withArrow: true,
          multiline: true,
          position: 'top',
          offset: 10,
          style: {
            maxWidth: 260,
          },
        },
      },
      Menu: {
        styles: () => ({
          arrow: {
            borderTop: `2px solid ${customTheme.profileOutline}`,
            borderLeft: `2px solid ${customTheme.profileOutline}`,
            borderBottom: `2px solid ${customTheme.profileOutline}`,
            borderRight: `2px solid ${customTheme.profileOutline}`,
            borderTopColor: customTheme.profileOutline,
            borderLeftColor: customTheme.profileOutline,
            borderBottomColor: customTheme.profileOutline,
            borderRightColor: customTheme.profileOutline,
          },
        }),
      },
      Switch: {
        classNames: switchClasses,
      },
      Checkbox: {
        defaultProps: {
          iconColor: customTheme.background,
        },
        classNames: checkboxClasses,
      },
    },
  }
}

export function useCustomTheme(light: boolean): {
  activeTheme: Theme
  mantineTheme: MantineThemeOverride
} {
  const activeTheme = light ? lightTheme : darkTheme
  return useMemo(
    () => ({
      activeTheme,
      mantineTheme: mapThemeToMantineTheme(activeTheme),
    }),
    [activeTheme]
  )
}

import { Box, Menu, Stack, Text, useMantineTheme } from '@mantine/core'
import React, { useCallback, useState } from 'react'

import { NetworkOptions } from '@/utils/utils'

import OpenMenuArrow from '../Animations/OpenMenuArrow/OpenMenuArrow'
import classes from './SendDropdownButton.module.css'

interface SendDropdownButtonProps {
  selectedOption: NetworkOptions
  setSelectedOption: (option: NetworkOptions) => void
  options: NetworkOptions[]
  optionIcons?: JSX.Element[]
  label?: string
  disabled?: boolean
  width?: string
}

const SendDropdownButton: React.FC<SendDropdownButtonProps> = ({
  selectedOption,
  setSelectedOption,
  options,
  optionIcons = [],
  label = 'Origin',
  disabled = false,
  width = '100px',
}) => {
  const theme = useMantineTheme()
  const [menuOpened, setMenuOpened] = useState<boolean>(false)

  const handleOptionChange = useCallback(
    (option: NetworkOptions) => {
      setSelectedOption(option)
      setMenuOpened(false)
    },
    [setSelectedOption, setMenuOpened]
  )

  return (
    <Stack style={{ flexDirection: 'row', gap: '0' }}>
      <Box className={classes.label}>
        <Text style={{ textAlign: 'center' }}>{label}</Text>
      </Box>
      <Menu
        trigger="hover"
        openDelay={500}
        closeDelay={2000}
        opened={menuOpened}
        onClose={() => setMenuOpened(false)}
        closeOnClickOutside
        closeOnEscape
        position="bottom"
        offset={0}
        disabled={disabled}
      >
        <Menu.Target>
          <Stack
            style={{
              color: disabled ? theme.colors.textAndIcon[0] : theme.colors.paragraph[0],
              cursor: disabled ? 'not-allowed' : 'pointer',
              borderRadius: menuOpened ? '0px 8px 0px 0px' : '0px 8px 8px 0px',
              width,
              position: 'relative',
            }}
            className={classes.button}
            onClick={() => (!disabled ? setMenuOpened(!menuOpened) : {})}
          >
            <Stack style={{ flexDirection: 'row', alignItems: 'center', gap: 5 }}>
              {!!optionIcons && optionIcons[options.indexOf(selectedOption)]}
              {selectedOption}
            </Stack>
            {!disabled && (
              <Stack style={{ position: 'absolute', right: '5px' }}>
                <OpenMenuArrow menuOpened={menuOpened} />
              </Stack>
            )}
          </Stack>
        </Menu.Target>
        <Menu.Dropdown
          className={classes.selectionBox}
          style={{ borderRadius: menuOpened ? '0px 0px 8px 8px' : '8px', width }}
        >
          {options.map(option => (
            <Stack
              key={option}
              onClick={() => handleOptionChange(option)}
              className={classes.selectionButton}
            >
              <Stack className={classes.selectionButtonContent}>
                {!!optionIcons && optionIcons[options.indexOf(option)]}
                {option}
              </Stack>
            </Stack>
          ))}
        </Menu.Dropdown>
      </Menu>
    </Stack>
  )
}

export default SendDropdownButton

import { useDisclosure } from '@mantine/hooks'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Loading from '@/components/Loading'
import Send from '@/components/Transfer/Send'
import { l1NativeConfig } from '@/plugins/auth/config'
import { switchChain } from '@/plugins/auth/utils'

import ModalSkeleton from '../ModalSkeleton'

interface SendModalProps {
  close: () => void
}

const SendModal: React.FC<SendModalProps> = ({ close }) => {
  const { t } = useTranslation()

  // modal on cancel / close make sure to be in Lamina1 network
  const [openedSwitchNetwork, { open: openSwitchNetwork, close: closeSwitchNetwork }] =
    useDisclosure(false)

  const tryChainSwitch = useCallback(async () => {
    openSwitchNetwork()
    try {
      await switchChain(false, false)
      closeSwitchNetwork()
    } catch (err: any) {
      console.error(err)
      if (err.message.includes('User rejected')) {
        setTimeout(async () => {
          await tryChainSwitch()
        }, 1000)
      }
    }
  }, [closeSwitchNetwork])

  const handleCancel = useCallback(async () => {
    if (window.ethereum) {
      const currentChainId = await window.ethereum.request({ method: 'eth_chainId' })
      if (currentChainId !== l1NativeConfig.chainIdHex) {
        await tryChainSwitch()
      } else {
        close()
      }
    } else {
      // if no ethereum provider, assume we are using Web3Auth and close the modal
      close()
    }
  }, [close, closeSwitchNetwork, l1NativeConfig.chainIdHex, openSwitchNetwork, tryChainSwitch])

  return (
    <ModalSkeleton
      opened
      onClose={handleCancel}
      title={t('components.wallet.send', 'Send')}
      allowModalCloseOutside={false}
    >
      {openedSwitchNetwork ? (
        <Loading
          title={t(
            'components.wallet.switchNetwork.modalTitle',
            'Switching Networks... To proceed you need to connect to Lamina1 Network'
          )}
        />
      ) : (
        <Send close={handleCancel} />
      )}
    </ModalSkeleton>
  )
}

export default SendModal

import { Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { IconAlertTriangle, IconCheck } from '@tabler/icons-react'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import RootButton from '@/components/Buttons/RootButton'
import TokenDisplay from '@/components/Tokens/TokensDisplay'
import {
  BRIDGE_AVAX_ERC20_REMOTE_ADDRESS,
  LL1_ADDRESS,
  RL1_ADDRESS,
  STAKE_ADDRESS,
} from '@/constants/blockchain'
import { useBalancesContext } from '@/contexts/BalancesContext'
import { addTokenToMetamask } from '@/plugins/auth/utils'

import ModalSkeleton from '../ModalSkeleton'

interface TokenToAdd {
  RL1: boolean
  LL1: boolean
  STAKE: boolean
  AVAX: boolean
  all: boolean
}

interface ErrorAddingToken {
  RL1: string
  LL1: string
  STAKE: string
  AVAX: string
  all: string
}

interface AddTokenProps {
  addCall: () => void
  token: 'RL1' | 'LL1' | 'STAKE' | 'AVAX' | 'all'
  added: boolean
  isLoadingAction: boolean
  isError: string
  disabled: boolean
}

const AddToken: React.FC<AddTokenProps> = ({
  addCall,
  token,
  added,
  isLoadingAction,
  isError,
  disabled,
}) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  return (
    <Stack
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '50%',
      }}
    >
      {token === 'all' ? (
        <Title order={4}>{t('components.wallet.addTokenToMetamask.subheader', 'Tokens')}</Title>
      ) : (
        <TokenDisplay token={token} />
      )}
      <RootButton
        secondary
        style1={false}
        onClick={addCall}
        loading={isLoadingAction}
        disabled={added || disabled}
      >
        {added && <IconCheck color={theme.colors.l1Primary[0]} style={{ margin: '0 4px' }} />}
        {!added && token === 'all' && t('buttons.addAll', 'Add All')}
        {!added && !isError && token !== 'all' && t('buttons.add', 'Add')}
        {isError && (
          <IconAlertTriangle color={theme.colors.notification[0]} style={{ margin: '0 4px' }} />
        )}
      </RootButton>
    </Stack>
  )
}

interface AddToMetamaskModalProps {
  close: () => void
}

const AddToMetamaskModal: React.FC<AddToMetamaskModalProps> = ({ close }) => {
  const theme = useMantineTheme()
  const { t } = useTranslation()
  const {
    bridge: { hasBridge },
    ll1: { hasLL1 },
    rl1: { hasGovernance },
  } = useBalancesContext()

  const [added, setAdded] = useState<TokenToAdd>({
    RL1: false,
    LL1: false,
    STAKE: false,
    AVAX: false,
    all: false,
  })
  const [isLoadingAction, setIsLoadingAction] = useState<TokenToAdd>({
    RL1: false,
    LL1: false,
    STAKE: false,
    AVAX: false,
    all: false,
  })
  const [isError, setIsError] = useState<ErrorAddingToken>({
    RL1: '',
    LL1: '',
    STAKE: '',
    AVAX: '',
    all: '',
  })

  const ll1andRl1Icon =
    'https://images.ctfassets.net/jeccmtvjaeon/4jQlQnzCwbbUWcLI9SVDiE/8432f68f44d7721399021092d0d36dd4/r_ll1TokenBlack.svg'
  const avaxIcon =
    'https://cdnwp-s3.benzinga.com/wp-content/uploads/2021/02/25122900/avalanche-avax-logo.png'

  const addLL1ToMetamask = useCallback(async () => {
    setIsLoadingAction({ ...isLoadingAction, LL1: true })
    try {
      await addTokenToMetamask(LL1_ADDRESS, 'LL1', ll1andRl1Icon)

      setIsLoadingAction({ ...isLoadingAction, LL1: false })
      setAdded(prev => ({
        ...prev,
        LL1: true,
      }))
    } catch (err: any) {
      setIsLoadingAction({ ...isLoadingAction, LL1: false })
      setIsError({ ...isError, LL1: err.message })
    }
  }, [])

  const addRL1ToMetamask = useCallback(async () => {
    setIsLoadingAction({ ...isLoadingAction, RL1: true })
    try {
      await addTokenToMetamask(RL1_ADDRESS, 'RL1', ll1andRl1Icon)
      setIsLoadingAction({ ...isLoadingAction, RL1: false })
      setAdded(prev => ({
        ...prev,
        RL1: true,
      }))
    } catch (err: any) {
      setIsLoadingAction({ ...isLoadingAction, RL1: false })
      setIsError({ ...isError, RL1: err.message })
    }
  }, [])

  const addStakeToMetamask = useCallback(async () => {
    setIsLoadingAction({ ...isLoadingAction, STAKE: true })
    try {
      await addTokenToMetamask(STAKE_ADDRESS, 'STKP', ll1andRl1Icon)
      setIsLoadingAction({ ...isLoadingAction, STAKE: false })
      setAdded(prev => ({
        ...prev,
        STAKE: true,
      }))
    } catch (err: any) {
      setIsLoadingAction({ ...isLoadingAction, STAKE: false })
      setIsError({ ...isError, STAKE: err.message })
    }
  }, [])

  const addAvaxToMetamask = useCallback(async () => {
    setIsLoadingAction({ ...isLoadingAction, AVAX: true })
    try {
      await addTokenToMetamask(BRIDGE_AVAX_ERC20_REMOTE_ADDRESS, 'AVAX', avaxIcon)
      setIsLoadingAction({ ...isLoadingAction, AVAX: false })
      setAdded(prev => ({
        ...prev,
        AVAX: true,
      }))
    } catch (err: any) {
      setIsLoadingAction({ ...isLoadingAction, AVAX: false })
      setIsError({ ...isError, AVAX: err.message })
    }
  }, [])

  const addAllToMetamask = useCallback(async () => {
    setIsLoadingAction({ ...isLoadingAction, all: true })
    try {
      if (hasLL1) {
        await addTokenToMetamask(LL1_ADDRESS, 'LL1', ll1andRl1Icon)
      }
      if (hasGovernance) {
        await addTokenToMetamask(RL1_ADDRESS, 'RL1', ll1andRl1Icon)
        await addTokenToMetamask(STAKE_ADDRESS, 'STAKE', ll1andRl1Icon)
      }
      if (hasBridge) {
        await addTokenToMetamask(BRIDGE_AVAX_ERC20_REMOTE_ADDRESS, 'AVAX', avaxIcon)
      }
      setIsLoadingAction({ ...isLoadingAction, all: false })
      setAdded({ RL1: true, LL1: true, STAKE: true, AVAX: true, all: true })
    } catch (err: any) {
      setIsLoadingAction({ ...isLoadingAction, all: false })
      setIsError({ ...isError, all: err.message })
    }
  }, [hasLL1, hasGovernance, hasBridge])

  const disableButtons = useCallback(
    () =>
      isLoadingAction.RL1 ||
      isLoadingAction.LL1 ||
      isLoadingAction.STAKE ||
      isLoadingAction.AVAX ||
      isLoadingAction.all,
    [isLoadingAction]
  )

  const errorMessageDisplayed = useMemo(
    () => isError.all || isError.RL1 || isError.LL1 || isError.STAKE || isError.AVAX,
    [isError]
  )

  return (
    <ModalSkeleton
      opened
      onClose={close}
      title={t('components.wallet.addTokenToMetamask.title', 'Add Tokens to MetaMask')}
    >
      <Stack style={{ gap: '15px', paddingBottom: '30px' }}>
        <Text>
          {
            t(
              'components.wallet.addTokenToMetamask.description',
              'Easily add supported ERC-20 tokens to your MetaMask wallet to always see your LAMINA1 balances. '
            ) as string
          }
        </Text>
        <AddToken
          addCall={addAllToMetamask}
          token="all"
          added={added.all}
          isLoadingAction={isLoadingAction.all}
          isError={isError.all}
          disabled={disableButtons()}
        />
        {hasLL1 && (
          <AddToken
            addCall={addLL1ToMetamask}
            token="LL1"
            added={added.LL1}
            isLoadingAction={isLoadingAction.LL1}
            isError={isError.LL1}
            disabled={disableButtons()}
          />
        )}
        {hasGovernance && (
          <AddToken
            addCall={addRL1ToMetamask}
            token="RL1"
            added={added.RL1}
            isLoadingAction={isLoadingAction.RL1}
            isError={isError.RL1}
            disabled={disableButtons()}
          />
        )}
        {hasGovernance && (
          <AddToken
            addCall={addStakeToMetamask}
            token="STAKE"
            added={added.STAKE}
            isLoadingAction={isLoadingAction.STAKE}
            isError={isError.STAKE}
            disabled={disableButtons()}
          />
        )}
        {hasBridge && (
          <AddToken
            addCall={addAvaxToMetamask}
            token="AVAX"
            added={added.AVAX}
            isLoadingAction={isLoadingAction.AVAX}
            isError={isError.AVAX}
            disabled={disableButtons()}
          />
        )}
        {errorMessageDisplayed && (
          <Text
            style={{
              color: theme.colors.notification[0],
            }}
          >
            {errorMessageDisplayed}
          </Text>
        )}
      </Stack>
    </ModalSkeleton>
  )
}

export default AddToMetamaskModal

import { Stack, useMantineTheme } from '@mantine/core'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AVAX_CHAIN_TICKER, NATIVE_CHAIN_TICKER } from '@/constants/blockchain'
import { useBalancesContext } from '@/contexts/BalancesContext'
import { formatNumberWithCommas, NetworkOptions, networkOptions } from '@/utils/utils'

import DropdownButton from './Buttons/DropdownButton'
import ErrorMessage from './ErrorMessage'
import { checkInputValue } from './Identity/rules'
import Loading from './Loading'
import { SpecialInputText } from './SpecialInputText'
import TokenDisplay from './Tokens/TokensDisplay'

interface Amount {
  value: string
  touched: boolean
  error: string
}

interface CurrencyInputProps {
  amount: Amount
  setAmount: (amount: Amount) => void
  estimatedFee: number
  setToken?: (token: string) => void
  token?: string
  networkOrigin?: NetworkOptions
  disabledDropdown?: boolean
  disabledInput?: boolean
  allowMax?: boolean
}

const MAX_AMOUNT = 1000000000000

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  amount,
  setAmount,
  estimatedFee,
  disabledDropdown,
  disabledInput,
  allowMax = true,
  token = NATIVE_CHAIN_TICKER,
  // default to not have dropdown menu to select token
  setToken = null,
  networkOrigin = networkOptions.l1,
}) => {
  const theme = useMantineTheme()
  const { t } = useTranslation()
  const {
    l1NetworkL1Balance,
    avaxNetworkAvaxBalance,
    bridge: { hasBridge, l1NetworkAvaxBalance, avaxNetworkL1Balance },
    isLoading: balanceLoading,
    error: balanceError,
  } = useBalancesContext()

  const menuWidth = setToken === null ? 70 : 110

  const balance = useMemo(() => {
    if (!l1NetworkL1Balance || !networkOrigin || !token) {
      return undefined
    }

    if (networkOrigin === networkOptions.l1) {
      if (token === NATIVE_CHAIN_TICKER) {
        return l1NetworkL1Balance
      }
      return l1NetworkAvaxBalance
    }
    if (token === NATIVE_CHAIN_TICKER) {
      return avaxNetworkL1Balance
    }
    return avaxNetworkAvaxBalance
  }, [
    avaxNetworkAvaxBalance,
    avaxNetworkL1Balance,
    l1NetworkAvaxBalance,
    l1NetworkL1Balance,
    networkOrigin,
    token,
  ])

  const setMaxAmount = useCallback(() => {
    if (balance) {
      if (parseFloat(balance.fullValue) < estimatedFee) {
        setAmount({
          value: '0',
          touched: true,
          error: 'Insuficient balance to cover fees',
        })
      } else
        setAmount({
          value: (parseFloat(balance.fullValue) - estimatedFee).toString(),
          touched: true,
          error: '',
        })
    }
  }, [balance, estimatedFee])

  const handleAmountChange = useCallback(
    (value: number | string) => {
      if (!balance) return
      if (typeof value === 'number') {
        value = value.toString()
      }
      if (Number(value) > MAX_AMOUNT) {
        setAmount({
          value: MAX_AMOUNT.toString(),
          touched: true,
          error: t('components.send.maxAmount', 'Max amount is {{maxAmount}}', {
            maxAmount: formatNumberWithCommas(MAX_AMOUNT),
          }),
        })
      } else {
        // if input value starts with a dot add a zero before it
        const processedValue = value.startsWith('.') ? `0${value}` : value
        setAmount({
          value: processedValue,
          touched: true,
          error: checkInputValue(t, processedValue, balance.fullValue, estimatedFee),
        })
      }
    },
    [balance, estimatedFee, t, MAX_AMOUNT]
  )

  // Token options for dropdown
  const tokenOptions = {
    [NATIVE_CHAIN_TICKER]: (
      <TokenDisplay key={NATIVE_CHAIN_TICKER} token={NATIVE_CHAIN_TICKER} onlyIcon />
    ),
    [AVAX_CHAIN_TICKER]: (
      <TokenDisplay key={AVAX_CHAIN_TICKER} token={AVAX_CHAIN_TICKER} onlyIcon />
    ),
    // Add more tokens here as needed
  }

  const options = setToken ? Object.keys(tokenOptions) : [NATIVE_CHAIN_TICKER]
  const optionIcons = options.map(icon => tokenOptions[icon])

  return (
    <>
      {!balanceError.isError && !balanceLoading && balance && (
        <SpecialInputText
          placeholder="0.00"
          value={amount.value}
          leftSectionWidth={`${menuWidth + 10}px`}
          leftSectionProps={{ style: { justifyContent: 'left', marginLeft: '2px' } }}
          iconLeft={
            <DropdownButton
              width={`${menuWidth}px`}
              options={options}
              optionIcons={optionIcons}
              selectedOption={token}
              setSelectedOption={setToken}
              backgroundColor={theme.colors.background3[0]}
              noBorder
              disabled={setToken === null ? true : !hasBridge || disabledDropdown}
            />
          }
          iconRight={
            allowMax ? (
              <a
                style={{
                  cursor: 'pointer',
                  color: theme.colors.textAndIcon[0],
                }}
                onClick={setMaxAmount}
              >
                Max
              </a>
            ) : null
          }
          error={amount.touched ? amount.error : null}
          borderStatus={amount.touched ? (amount.error ? 'error' : 'success') : 'default'}
          onChange={handleAmountChange}
          isAmount
          tipLeft={
            hasBridge && setToken !== null
              ? t(
                  'components.send.amountTip',
                  'Select the token and enter the amount you’d like to send'
                )
              : ''
          }
          tipRight={t('components.send.available', 'Available: {{balance}} {{token}}', {
            balance: parseFloat(balance.fullValue).toFixed(4),
            token,
          })}
          disabled={disabledInput}
        />
      )}
      {balanceLoading && !balanceError.isError && (
        <Stack>
          <Loading title={t('components.send.loadingBalance', 'Loading Balance')} size="40px" />
        </Stack>
      )}
      {balanceError.isError && (
        <ErrorMessage
          message={t('components.send.errorLoadingBalance', 'RPC Error loading {{ call }}', {
            call: balanceError.call,
          })}
          details={balanceError.msg}
        />
      )}
    </>
  )
}

export default CurrencyInput

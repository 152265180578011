import './plugins/sentry'
import './App.css'
import '@mantine/carousel/styles.css'
import '@mantine/core/styles.css'
import '@mantine/charts/styles.css'

import { ApolloProvider } from '@apollo/client'
import { MantineProvider } from '@mantine/core'
import * as Sentry from '@sentry/react'
import { IconAlertCircle } from '@tabler/icons-react'
import { enqueueSnackbar } from 'notistack'
import React, { Suspense, useEffect } from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import { HelmetProvider } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import ErrorBoundaryFallback from '@/components/ErrorBoundaryFallback'
import { AuthProvider } from '@/plugins/auth'

import AppFrame from './components/AppFrame'
import ModalWrapper from './components/Modals/ModalWrapper'
import Seo from './components/Seo'
import Snackbar from './components/Snackbar'
import { useCustomTheme } from './components/Theme/themes'
import { ModalProvider } from './contexts/ModalContext'
import { ChainWorker, useChainWorker } from './hooks/useChain'
import useLocalStorage from './hooks/useLocalStorage'
import client from './plugins/apollo'
import { getWagmiConfig } from './plugins/auth/config'
import { DBProvider } from './plugins/db/Db'
import { I18NextProvider } from './plugins/i18n'
import SnackbarProvider from './plugins/SnackbarProvider'
import { StoredTheme } from './utils/utils'

// Chains Worker
const ChainsWorker: React.FC = () => {
  const { t } = useTranslation()

  // Load wagmi
  const config = getWagmiConfig()

  // Load chains
  // TODO: remove eslint disable and refactor
  const chains: Record<number, ChainWorker> = {}
  config.chains.forEach(chain => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    chains[chain.id] = useChainWorker(chain.id)
  })

  // Detect errors
  useEffect(() => {
    config.chains.forEach(({ id }) => {
      if (!chains[id].isLoading && chains[id].error.isError) {
        enqueueSnackbar(
          <Snackbar
            value={t(
              'errors.snackbar.fetchBalance.message',
              `Error fetching balance on chain ID {{ chainId }}.`,
              {
                chainId: id,
              }
            )}
            title={t('errors.snackbar.fetchBalance.title', 'RPC Connection Error')}
            icon={<IconAlertCircle size="14px" />}
          />,
          { preventDuplicate: true, autoHideDuration: 10000 }
        )
      }
    })
  }, [chains, config.chains])

  return null
}

function App() {
  const [userTheme] = useLocalStorage<StoredTheme>(`userTheme`, {
    isLightMode: window?.matchMedia?.('(prefers-color-scheme: light)')?.matches ?? false,
  })

  const { mantineTheme } = useCustomTheme(userTheme.isLightMode)

  return (
    <React.StrictMode>
      <Suspense fallback={<div>Still loading...</div>}>
        <I18NextProvider>
          <ApolloProvider client={client}>
            <div className="App">
              <MantineProvider theme={mantineTheme}>
                <HelmetProvider>
                  <Seo
                    title="Lamina1"
                    description="LAMINA1 is a platform and protocol for the next era of digital content and co-creation. Come build with us across entertainment, gaming, music, film, fashion, DeFi, and beyond."
                    type="website"
                    creator="Lamina1"
                    url="https://lamina1.com"
                    image="https://azure-hilarious-mandrill-400.mypinata.cloud/ipfs/QmXZdRodUFQ48nFkEz3Docd7ryaEjMDUEi3Yh2D99U6f8A"
                  />
                  <SnackbarProvider>
                    <AuthProvider>
                      <ModalProvider>
                        <DBProvider>
                          <ChainsWorker />
                          <ModalWrapper />
                          <AppFrame />
                        </DBProvider>
                      </ModalProvider>
                    </AuthProvider>
                  </SnackbarProvider>
                </HelmetProvider>
              </MantineProvider>
            </div>
          </ApolloProvider>
        </I18NextProvider>
      </Suspense>
    </React.StrictMode>
  )
}

export default withErrorBoundary(Sentry.withProfiler(App), {
  FallbackComponent: ErrorBoundaryFallback,
})

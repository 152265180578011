import { Center, StackProps, Text, useMantineTheme } from '@mantine/core'
import React from 'react'

import error_dark from '@/assets/icons/error_dark.svg'
import error_light from '@/assets/icons/error_light.svg'
import success_blue from '@/assets/icons/success_blue.svg'
import success_green from '@/assets/icons/success_green.svg'
import loading_dark from '@/assets/loading/loading_dark.svg'
import loading_light from '@/assets/loading/loading_light.svg'

import IconWrapper from './MediaWrappers/IconWrapper'

interface LoadingProps extends StackProps {
  title?: string
  size?: string
  imageLoaderHeight?: string
  success?: boolean
  error?: boolean
  style?: React.CSSProperties
}

const Loading: React.FC<LoadingProps> = ({
  title = '',
  size = '',
  imageLoaderHeight = '100%',
  success = false,
  error = false,
  style = {},
}) => {
  const theme = useMantineTheme()
  return (
    <Center h={imageLoaderHeight} style={style}>
      {title && (
        <Text
          style={{
            marginBottom: '16px',
            fontSize: '10pt',
            color: theme.colors.textAndIcon[0],
            fontFamily: 'monument-grotesk-regularitalic',
          }}
        >
          {title}
        </Text>
      )}
      <IconWrapper
        style={{ height: size || '90px', padding: size ? '0px' : '' }}
        iconDark={success ? success_green : error ? error_dark : loading_dark}
        iconLight={success ? success_blue : error ? error_light : loading_light}
      />
    </Center>
  )
}

export default Loading

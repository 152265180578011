import { useCallback, useEffect, useMemo } from 'react'
import { Chain, EstimateContractGasParameters, parseEther, WriteContractParameters } from 'viem'
import {
  useAccount,
  useBalance,
  useGasPrice,
  usePublicClient,
  useWalletClient,
  useWatchBlockNumber,
} from 'wagmi'

import IERC165 from '@/contracts/IERC165'
import IERC5633 from '@/contracts/IERC5633'
import { CustomError } from '@/utils/helpers'
import { processBalance, ProcessedBalance } from '@/utils/utils'

import useSessionStorage from './useSessionStorage'

export type ContractParams = Omit<WriteContractParameters, 'chain' | 'account'>

export type ChainWorker = {
  isLoading: boolean
  error: CustomError
}

export type ChainValues = {
  balance: ProcessedBalance
  gasPrice: number
  blockNumber: number
}

export type ChainFunctions = {
  estimateGas: (destinationAddress: string, value: string) => Promise<number>
  estimateContractGas: (args: ContractParams) => Promise<number>
  hasCode: (address: string) => Promise<boolean>
  readContract: (config: ContractParams) => Promise<any>
  isSoulbound: (addr: string, id: string) => Promise<boolean>
  sendTransfer: (destination: string, value: string, wait?: boolean) => Promise<string>
  writeContract: (config: ContractParams, wait?: boolean) => Promise<string>
}

const balanceStorageKey = (id: number, address: string | undefined) => `balance-${id}-${address}`
const gasStorageKey = (id: number) => `gas-${id}`
const blockStorageKey = (id: number) => `block-${id}`

// Chain worker hook
// Updates balance, gas price and block number in
// session storage on new blocks
export const useChainWorker = (id: number): ChainWorker => {
  // User address
  const { address } = useAccount()

  /// //////////////////////
  // Session storage

  // Balance
  const [, setBalance] = useSessionStorage<string>(balanceStorageKey(id, address), '0')
  // Gas price
  const [, setGasPrice] = useSessionStorage<number>(gasStorageKey(id), 20)
  // Block number
  const [, setBlockNumber] = useSessionStorage<number>(blockStorageKey(id), 0)

  /// //////////////////////
  // Hooks
  const {
    data: dataBalance,
    isLoading: isLoadingBalance,
    isError: isErrorBalance,
    error: errorBalance,
    refetch: refetchBalance,
  } = useBalance({
    chainId: id,
    address,
    query: {
      enabled: !!address,
    },
  })

  const {
    data: dataGasPrice,
    isLoading: isLoadingGasPrice,
    isError: isErrorGasPrice,
    error: errorGasPrice,
    refetch: refetchGasPrice,
  } = useGasPrice({ chainId: id })

  // Watch block number to trigger refetch of data where needed
  useWatchBlockNumber({
    chainId: id,
    onBlockNumber: num => {
      // Update block number
      setBlockNumber(parseFloat(num.toString()))
      // Refetch data on new blocks
      if (address) {
        refetchBalance()
      }
      refetchGasPrice()
    },
  })

  /// //////////////////////
  // State updates

  // Set data
  useEffect(() => {
    if (!isLoadingBalance && !isErrorBalance && dataBalance) {
      setBalance(dataBalance.value.toString())
    }
    if (!isLoadingGasPrice && !isErrorGasPrice && dataGasPrice) {
      setGasPrice(Number(dataGasPrice))
    }
  }, [
    address,
    dataBalance,
    isLoadingBalance,
    isErrorBalance,
    dataGasPrice,
    isLoadingGasPrice,
    isErrorGasPrice,
  ])

  const error: CustomError = useMemo(() => {
    if (isErrorBalance && !!address) {
      return { call: 'nativeBalance', msg: errorBalance?.message, isError: true }
    }
    if (isErrorGasPrice) {
      return { call: 'gasPrice', msg: errorGasPrice?.message, isError: true }
    }
    return { call: '', msg: undefined, isError: false }
  }, [address, isErrorBalance, errorBalance, isErrorGasPrice, errorGasPrice])

  const isLoading = useMemo(
    () => isLoadingBalance || isLoadingGasPrice,
    [isLoadingBalance, isLoadingGasPrice]
  )

  return {
    isLoading,
    error,
  }
}

// Chain values hook
// Reads balance, gas price and block number from session storage
export const useChainValues = (id: number): ChainValues => {
  // User address
  const { address } = useAccount()

  /// //////////////////////
  // Read balance, gas and block from session storage
  const [balanceStr] = useSessionStorage<string>(balanceStorageKey(id, address), '0')
  const [gasPrice] = useSessionStorage<number>(gasStorageKey(id), 20)
  const [blockNumber] = useSessionStorage<number>(blockStorageKey(id), 0)

  // Process balance
  const balance = useMemo(() => processBalance(BigInt(balanceStr)), [balanceStr])

  return {
    balance,
    gasPrice,
    blockNumber,
  }
}

// Chain hook
// Provides read and write functions for a chain
// and reads balance, gas price and block number from session storage
export const useChainFunctions = (chainConfig: Chain): ChainFunctions => {
  // User address
  const { address } = useAccount()

  // When using Web3Auth, need to set gas price and limit
  const isNotMetamask = localStorage.getItem('Web3Auth-cachedAdapter') !== 'metamask'

  // Public client for read functions
  const publicClient = usePublicClient({ chainId: chainConfig.id })

  // Wallet client for write functions
  const { data: walletClient } = useWalletClient({ chainId: chainConfig.id })

  /// //////////////////////
  // Read Functions

  const estimateGas = useCallback(
    async (destinationAddress: string, value: string): Promise<number> => {
      try {
        const gas = await publicClient?.estimateGas({
          account: address as `0x${string}`,
          to: destinationAddress as `0x${string}`,
          value: parseEther(value),
        })
        // Convert BigInt to number
        return Number(gas)
      } catch (err: any) {
        console.error('Error', err)
        // Default to 21000 as a safe value for transfer
        return 21000
      }
    },
    [address, publicClient]
  )

  const estimateContractGas = useCallback(
    async (args: ContractParams): Promise<number> => {
      try {
        const withAccount = {
          ...args,
          account: address as `0x${string}`,
        }
        const gas = await publicClient?.estimateContractGas(
          withAccount as EstimateContractGasParameters
        )
        // Convert BigInt to number
        return Number(gas)
      } catch (err: any) {
        console.error('Error', err)
        // Use a high default of 2M gas which should be safe for any contract call
        return 2000000
      }
    },
    [address, publicClient]
  )

  const hasCode = useCallback(
    async (addr: string): Promise<boolean> => {
      try {
        const code = await publicClient?.getCode({
          address: addr as `0x${string}`,
        })
        return code !== undefined && code !== '0x'
      } catch (err: any) {
        console.error('Error', err)
        throw new Error(`Error getting code: ${err.message}`)
      }
    },
    [publicClient]
  )

  const readContract = useCallback(
    async (params: ContractParams): Promise<any> => {
      try {
        const value = await publicClient?.readContract(params)
        return value
      } catch (err: any) {
        console.error('Error', err)
        throw new Error(`Error reading contract: ${err.message}`)
      }
    },
    [publicClient]
  )

  const isSoulbound = useCallback(
    async (addr: string, id: string): Promise<boolean> => {
      try {
        // interface ID for ERC5633 Interface
        const interfaceID = '0x911ec470'
        const data = await publicClient?.readContract({
          address: addr as `0x${string}`,
          abi: IERC165,
          functionName: 'supportsInterface',
          args: [interfaceID as `0x${string}`],
        })
        if (data) {
          try {
            const soulbound = await publicClient?.readContract({
              address: addr as `0x${string}`,
              abi: IERC5633,
              functionName: 'isSoulbound',
              args: [BigInt(id)],
            })
            return soulbound as boolean
          } catch (err: any) {
            console.error(err)
            throw new Error(`Error checking if item is soulbound: ${err.message}`)
          }
        }
        return data as boolean
      } catch (err: any) {
        console.error(err)
        throw new Error(`Error checking if contract supports ERC5633 interface: ${err.message}`)
      }
    },
    [publicClient]
  )

  /// //////////////////////
  // Write Functions

  const sendTransaction = useCallback(
    async (
      destination: string,
      value: string,
      wait: boolean,
      params?: ContractParams
    ): Promise<string> => {
      if (!address || !walletClient || !publicClient) {
        throw new Error('Cannot send transaction without address or wallet client')
      }
      try {
        // Read gas price from session storage
        const gasPrice = sessionStorage.getItem(gasStorageKey(chainConfig.id)) || '25000000000'
        // Contract interaction
        let hash: `0x${string}` | undefined
        if (params) {
          let gas: bigint | undefined
          // If not metamask, estimate gas
          if (isNotMetamask) {
            const gasEstimate = await estimateContractGas(params as EstimateContractGasParameters)
            gas = BigInt((gasEstimate * 1.1).toFixed(0)) // 10% buffer for gas limit
          }
          hash = await walletClient.writeContract({
            ...params,
            chain: chainConfig,
            gasPrice: isNotMetamask ? BigInt(gasPrice) : undefined,
            gas,
          } as WriteContractParameters)
        } else {
          // If not metamask, estimate gas
          let gas: bigint | undefined
          if (isNotMetamask) {
            const gasEstimate = await estimateGas(destination, value)
            gas = BigInt((gasEstimate * 1.1).toFixed(0)) // 10% buffer for gas limit
          }
          hash = await walletClient.sendTransaction({
            account: address as `0x${string}`,
            to: destination as `0x${string}`,
            value: parseEther(value),
            gasPrice: isNotMetamask ? BigInt(gasPrice) : undefined,
            gas,
          })
        }
        // Wait for transaction receipt
        if (wait) {
          await publicClient.waitForTransactionReceipt({ hash })
        }
        return hash
      } catch (err: any) {
        console.error('Error:', err)
        throw new Error(`Failed to Send transaction: ${err.message}`)
      }
    },
    [address, chainConfig, publicClient, walletClient]
  )

  const sendTransfer = useCallback(
    async (destination: string, value: string, wait: boolean = true): Promise<string> =>
      sendTransaction(destination, value, wait),
    [sendTransaction]
  )

  const writeContract = useCallback(
    async (params: ContractParams, wait: boolean = true): Promise<string> =>
      sendTransaction('', '0', wait, params),
    [sendTransaction]
  )

  return {
    estimateGas,
    estimateContractGas,
    hasCode,
    readContract,
    isSoulbound,
    sendTransfer,
    writeContract,
  }
}

import { Dexie } from 'dexie'
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useAuth } from '../auth'

type WithChildren = {
  children?: ReactNode
}

type DBContextType = {
  db?: Dexie | undefined
  dbIpfs?: Dexie | undefined
  init: (dbName: string) => Promise<void> | undefined
  destroy: (dbName: string) => void
  exists: (dbName: string) => Promise<boolean>
}

export const IPFS_CACHE_DB_NAME = 'ipfs-cache'

export const getDb = async (dbName: string) => {
  const indexedDb = new Dexie(dbName)
  const openedDb = await indexedDb.open().catch(e => {
    console.error(`Error initializing db: ${e}`)
  })
  return openedDb
}

export const saveToDb = async (db: Dexie | void, table: string, data: any) => {
  if (db) {
    try {
      await db.table(table).put({ ...data })
    } catch (e) {
      console.error(`Error saving to db: ${e}`)
    }
  }
}

export const DBContext = createContext<DBContextType>({
  init: async () => {},
  destroy: () => {},
  exists: async () => false,
})

export const DBProvider: FC<WithChildren> = ({ children }) => {
  const [dbAddr, setDbAddr] = useState<Dexie>()
  const [dbIpfs, setDbIpfs] = useState<Dexie>()
  const { address } = useAuth()
  const init = useCallback(async (dbName: string) => {
    if (dbName !== '') {
      const indexedDb = new Dexie(dbName)
      // Version 5 - drafts working again
      indexedDb
        .version(5)
        .stores(
          dbName === IPFS_CACHE_DB_NAME
            ? {
                ipfs: 'cid, data, timestamp',
              }
            : {
                drafts: 'id, timestamp',
              }
        )
        .upgrade(async transaction => {
          console.log('Upgrading db:', dbName)
          if (dbName !== IPFS_CACHE_DB_NAME) {
            return
          }
          // Migrate existing data if needed
          const allItems = await transaction.table('ipfs').toArray()
          await Promise.all(
            allItems.map(item => {
              // If 'timestamp' is missing, set it to Date.now()
              if (!item.timestamp) {
                item.timestamp = Date.now()
              }
              console.log('Migrating item:', item)
              return transaction.table('ipfs').put(item)
            })
          )
        })

      const openedDb = await indexedDb
        .open()
        .then(dbName === IPFS_CACHE_DB_NAME ? setDbIpfs : setDbAddr)
        .catch(e =>
          console.error(
            ` ${dbName === IPFS_CACHE_DB_NAME ? '[IPFS]' : '[L1]'}: Error initializing db: ${e}`
          )
        )
      return openedDb
    }
    return undefined
  }, [])

  useEffect(() => {
    init(IPFS_CACHE_DB_NAME)
    if (address) {
      init(`L1-${address}`)
    }
  }, [address])

  // Delete ipfs table indexed db elements older than 15 days (check created date)
  useEffect(() => {
    const deleteOldIpfsElements = async () => {
      if (dbIpfs) {
        try {
          const numDeletedItems = await dbIpfs
            .table('ipfs')
            .where('timestamp')
            .below(Date.now() - 15 * 24 * 60 * 60 * 1000)
            .delete()
          console.log(`Deleted ${numDeletedItems} items from IPFS cache`)
        } catch (error) {
          console.error(`Error deleting items from IPFS cache: ${error}`)
        }
      }
    }

    deleteOldIpfsElements()
  }, [dbIpfs])

  const destroy = useCallback((dbName: string) => {
    const database = new Dexie(dbName)
    if (database) {
      database.delete().then(() => {
        if (dbName === IPFS_CACHE_DB_NAME) setDbIpfs(undefined)
        else setDbAddr(undefined)
      })
    }
  }, [])

  const exists = useCallback(
    async (dbName: string) => {
      try {
        const retval = await Dexie.exists(dbName)
        return retval
      } catch {
        return false
      }
    },
    [dbAddr, dbIpfs]
  )

  const contextValue = useMemo(
    () => ({ db: dbAddr, dbIpfs, init, destroy, exists }),
    [dbAddr, dbIpfs, init, destroy, exists]
  )

  return <DBContext.Provider value={contextValue}>{children}</DBContext.Provider>
}

export const useDb = () => useContext(DBContext)
